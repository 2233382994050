@charset "utf-8";

.whoweare {
  padding-top: 60px;

  @include mq(md) {
    padding-top: 40px;
  }

  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &Intro {
    @include fz_vw(24);
    line-height: 1.5;
    letter-spacing: 0.6px;
    margin-bottom: 60px;

    @include mq(md) {
      @include fz_vw(40);
      margin-bottom: 30px;
    }
  }

  &Logo {
    width: 42.26%;
    margin: 0 auto 80px;

    @include mq(md) {
      width: 60%;
      margin-bottom: 40px;
    }
  }

  &Tit {
    @include fz_vw(24);
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: left;
    color: #a81e2d;
    margin-bottom: 20px;

    @include mq(md) {
      @include fz_vw(48);
    }
  }

  &Txt {
    @include fz_vw(16);
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin-bottom: 30px;

    @include mq(md) {
      @include fz_vw(32);
      margin-bottom: 10px;
    }
  }

  &Btn {
    @include fz_vw(19);
    font-weight: bold;
    line-height: 1.55;
    letter-spacing: 1.2px;
    text-align: center;
    color: #fff;
    background-color: #b21d2e;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: 60px auto 0;
    cursor: pointer;

    @include mq(md) {
      @include fz_vw(38);
    }
  }
}

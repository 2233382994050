#books,
#synopsisSingle{
  .books{
    max-width: 880px;
    width: 100%;
    padding: 0 20px;
    margin: 60px auto 0;
    background-color: #fff;

    @include mq(tab) {
      overflow: hidden;//ajax用調整
    }
    @include mq(md) {
      position: relative;
      z-index: 2;
      padding-bottom: 30px;
      margin: 60px auto 0;
      overflow: hidden;//ajax用調整
    }

    .booksNote {
      font-size: 1.4rem;
      line-height: 1.75;
      letter-spacing: 0.4px;
      color: #333;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include mq(md) {
        font-size: 1.3rem;
        line-height: 1.43;
        letter-spacing: 0.7px;
        margin: 0 auto 30px;
      }
    }

    .booksList{
      max-width: 100%;
      width: 100%;
      margin: 0 auto 15px;

      @include mq(md) {
        margin: 0 auto 15px;
      }

      .flex{
        display: flex;
        align-items: center;

        @include mq(md) {
          display: block;
        }
      }

      .heade{
        background-color: #f4f5f5;

        .booksListHead{
          font-size: 1.4rem;
          font-weight: normal;
          line-height: 1.71;
          letter-spacing: 0.35px;
          color: #000;

          &.num{
            max-width: 52px;//7.143%
            width: 100%;
            padding: 14px 0 13px 20px;
          }
          &.title{
            max-width: 33.691%;
            width: 100%;
            padding: 14px 0 13px 20px;
          }
          &.author{
            max-width: 28.572%;
            width: 100%;
            padding: 14px 0 13px 40px;
          }
          &.publish{
            padding: 14px 10px 13px 40px;
          }
        }
      }

      .booksListBody{
        .booksBody{
          border-bottom: dashed 1px #c7c7c7;

          @include mq(md) {
            display: block;
            padding: 15px 0;

            &:first-of-type{
              border-top: dashed 1px #c7c7c7;
            }
          }
        }

        a{
          transition: opacity .3s;
          &:hover{
            opacity: .7;
          }
        }
        .booksListCon{
          margin: 0;
          padding: 0;

          &.isActive{
            a{
              opacity: .7;
            }
          }
          @include mq(md) {
            display: block;
          }
        }
        .num{
          //width: 48px;
          max-width: 52px;//7.143%
          width: 100%;
          font-size: 1.4rem;
          line-height: 1.43;
          letter-spacing: 0.35px;
          color: #000;
          padding: 13px 0 13px 20px;
          word-break: break-all;

          a{
            display: block;
            color: inherit;
          }

          @include mq(md) {
            max-width: 100%;
            width: 100%;
            letter-spacing: 0.35px;
            padding: 0;
            //float: left;

            a{
              padding: 0;

              &::before{
                //content: "No.";
                display: inline-block;
              }
            }
          }
        }
        .title{
          //width: 283px;
          max-width: 33.691%;
          width: 100%;
          padding: 13px 0 13px 20px;

          a{
            display: block;
            color: inherit;

            em{
              font-style: italic;
            }
          }

          @include mq(md) {
            max-width: 100%;
            width: auto;
            padding: 0;

            a{
              padding: 0;
            }
          }
        }
        .cat{
          font-size: 1.4rem;
          line-height: 1.43;
          letter-spacing: 0.35px;
          color: #b21d2e;
          margin-bottom: 8px;

          @include mq(md) {
            letter-spacing: .35px;
            padding: 0;
          }
        }
        .tit{
          display: block;
          font-size: 1.6rem;
          line-height: 1.25;
          letter-spacing: 0.32px;
          color: #000;

          em{
            font-style: italic;
          }

          @include mq(md) {
            font-size: 1.9rem;
            line-height: 1.26;
            letter-spacing: .38px;
            padding: 8px 0 0;
          }
        }
        .subTit{
          font-size: 1.4rem;
          line-height: 1.29;
          letter-spacing: 0.28px;
          color: #666;
          margin-top: 8px;

          @include mq(md) {
            line-height: 1.25;
            letter-spacing: .35px;
            padding: 0;
          }
        }
        .author{
          //width: 240px;
          max-width: 28.572%;
          width: 100%;
          font-size: 1.6rem;
          line-height: 1.25;
          letter-spacing: 0.4px;
          color: #000;
          padding: 13px 0 13px 40px;

          a{
            display: block;
            color: inherit;
          }

          @include mq(md) {
            max-width: 100%;
            width: auto;
            line-height: 1.09;
            letter-spacing: .24px;
            clear: both;
            padding: 8px 0 0;

            a{
              padding: 8px 0 0;
            }
          }
        }
        .publish{
          flex-grow: 1;
          font-size: 1.4rem;
          line-height: 1.29;
          letter-spacing: 0.35px;
          color: #000;
          padding: 13px 10px 13px 40px;

          a{
            display: block;
            color: inherit;
          }

          @include mq(md) {
            width: auto;
            line-height: 1.25;
            letter-spacing: .35px;
            color: #666;
            padding: 8px 0 0;

            a{
              padding: 8px 0 0;
            }
          }
        }
      }
    }

    .categoryColumn {
      max-width: 880px;
      margin: 0 auto 45px;
      @include mq(tab) {
        position: relative;
      }
      @include mq(md) {
        position: relative;
        margin-bottom: 50px;
      }
      &Current {
        font-size: 2rem;
        line-height: 1.7;
        letter-spacing: 0.5px;
        color: #a81e2d;
        display: block;
        background-color: #fff;
        padding: 2.2% 5.8%;
        border: solid 1px #a81e2d;
        position: relative;
        z-index: 2;
        &::after {
          content: "";
          display: inline-block;
          width: 20px;
          height: 10px;
          margin: 0 auto 0 0;
          background-image: url(/images/synopsis/icon_accordion.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          right: 6%;
          top: 42%;
          transition: .4s;
        }
        &.open {
          &::after {
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
          }
        }
        //      &:hover {
        //        @include mq(md) {
        //          background-color: #f4f5f5;
        //        }
        //      }
      }
      ul {
        display: flex;
        justify-content: space-between;
        z-index: 1;
        @include mq(tab) {
          display: block;
          width: 100%;
          position: absolute;
          margin-top: -1px;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transition: opacity 10s, visibility 10s, transform .5s;
          transform: translateY(-100%);
          border-top: solid 1px #c7c7c7;
          border-right: solid 1px #a81e2d;
          border-left: solid 1px #a81e2d;
          border-bottom: solid 1px #a81e2d;
        }
        @include mq(md) {
          display: block;
          width: 100%;
          position: absolute;
          margin-top: -1px;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transition: opacity 10s, visibility 10s, transform .5s;
          transform: translateY(-100%);
          border-top: solid 1px #c7c7c7;
          border-right: solid 1px #a81e2d;
          border-left: solid 1px #a81e2d;
          border-bottom: solid 1px #a81e2d;
        }
        &.open {
          visibility: visible;
          opacity: 1;
          transition: .4s;
          transform: translateY(0);
        }
        li {
          margin-right: 20px;
          @include mq(tab) {
            margin-right: 0;
            background-color: #fff;
          }
          @include mq(md) {
            margin-right: 0;
            background-color: #fff;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            font-size: 2rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: 0;
            text-align: center;
            color: #666666;
            @include mq(tab) {
              display: block;
              background-color: #fff;
              padding: 4.4% 5.8%;
              text-align: left;
            }
            @include mq(md) {
              display: block;
              background-color: #fff;
              padding: 4.4% 5.8%;
              text-align: left;
            }
            &:hover {
              color: #a81e2d;
              padding-bottom: 5px;
              border-bottom: solid 1px #a81e2d;
              @include mq(tab) {
                padding: 4.4% 5.8%;
                border-bottom: none;
                color: #000;
                opacity: 1;
                background-color: #f4f5f5;
              }
              @include mq(md) {
                padding: 4.4% 5.8%;
                border-bottom: none;
                color: #000;
                opacity: 1;
                background-color: #f4f5f5;
              }
            }
          }
          &.active {
            a {
              color: #a81e2d;
              padding-bottom: 5px;
              border-bottom: solid 1px #a81e2d;
            }
            @include mq(tab) {
              display: none;
            }
            @include mq(md) {
              display: none;
            }
          }
        }
      }
    }
    
    .postNum {
      display: none;
    }
    .synopsisColumnTitle {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.68px;
      text-align: left;
      color: #b21d2e;
      margin-bottom: 15px;
      @include mq(md) {
        font-size: 2.3rem;
        line-height: 1.07;
        margin-bottom: 4%;
      }
      visibility: hidden;
      position: absolute;
      &+.synopsisCardLayout {
        visibility: hidden;
        position: absolute;
      }
      &.active {
        visibility: visible;
        position: relative;
        &+.synopsisCardLayout {
          visibility: visible;
          position: relative;
        }
      }
    }
    .synopsisBtn {
      font-size: 1.9rem;
      font-weight: 700;
      line-height: 1.55;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
      background-color: #b21d2e;
      padding: 15px 0;
      display: block;
      width: 250px;
      margin: 50px auto 0;
      cursor: pointer;
      @include mq(md) {
        font-size: 2.2rem;
        width: 100%;
        margin: 0 auto;
        line-height: 1.68;
        letter-spacing: 1.14px;
        margin: 60px auto 20px;
        padding: 5px 25px;
      }
    }

    //記事ページのレイアウト
    .synopsisArticle {
      width: 100%;
      // display: -webkit-box;
      // display: -webkit-flex;
      // display: -ms-flexbox;
      // display: flex;
      // -webkit-flex-wrap: wrap;
      // -ms-flex-wrap: wrap;
      // flex-wrap: wrap;
      @include mq(md) {
        display: block;
      }
      .synopsisArticleRightColumn {
        //flex-basis: 33.57%;
        margin-right: 60px;
        @include mq(md) {
          width: 60%;
          margin: 0 auto;
        }
        .synopsisArticleVisual {
          width: 100%;
          height: auto;
          margin-bottom: 15px;
        }
        .txtBox{
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include mq(md) {
            margin-bottom: 20%;
          }
        }
      }
      .synopsisCategory {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.4px;
        text-align: left;
        color: #a81e2d;
        padding: 5px 12px;
        border: solid 1px #A81E2D;
        background-color: #ffffff;
        &::before {
          content: "";
          display: inline-block;
          background-image: url(/images/synopsis/icon_book.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 13px;
          height: 13px;
          margin-right: 6px;
          vertical-align: middle;
          @include mq(md) {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
        @include mq(md) {
          font-size: 1.6rem;
          line-height: 0.38;
          letter-spacing: 0.8px;
          padding: 3% 6% 2%;
        }
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
      .synopsisId{
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.4px;
        //text-align: right;
        opacity: 0;
        margin: 0 0 10px;
        &.show {
          opacity: 1;
        }
        @include mq(md) {
          font-size: 1.5rem;
          line-height: 0.38;
          letter-spacing: 0.4px;
          margin: 0 0 15px;
        }
      }
      .synopsisArticleLeftColumn {
        //flex-basis: 55.83%;
        @include mq(md) {
          width: 100%;
        }
        .synopsisArticleCategory {
          font-size: 2rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.7;
          letter-spacing: 0.5px;
          text-align: left;
          color: #a81e2d;
          margin-bottom: 20px;
          @include mq(md) {
            line-height: 1.42;
            letter-spacing: 0.95px;
          }
        }
        .synopsisArticleTitle {
          font-size: 3.2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.8px;
          text-align: left;
          color: #000000;
          margin-bottom: 10px;

          em{
            font-style: italic;
          }
          
          @include mq(md) {
            line-height: 1.29;
            letter-spacing: 1.05px;
          }
        }
        .synopsisArticlePublication {
          font-size: 1.6rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          text-align: left;
          color: #000000;
          margin-bottom: 32px;
          @include mq(md) {
            font-size: 1.4rem;
            line-height: 1.5;
            letter-spacing: 0.7px;
            margin-bottom: 10%;
          }
        }
        .synopsisArticleAuthor {
          font-size: 2.2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          text-align: left;
          color: #000000;
          @include mq(md) {
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: 0.7px;
          }
        }
        .synopsisArticleTxt {
          font-size: 1.6rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: 0.4px;
          text-align: left;
          color: #000000;
          margin-bottom: 20px;

          p{
            margin-bottom: 15px;

            &:last-of-type{
              margin-bottom: 0;
            }
          }

          em,
          i{
            font-style: italic;
          }
          b{
            font-weight: bold;
          }
          @include mq(md) {
            font-size: 1.8rem;
            line-height: 1.5;
            letter-spacing: 0.9px;
          }
        }
      }
    }
  }

  .errorLayout {
    display: block;
    .inner {
      max-width: 880px;
      padding: 60px 20px 0;
      margin: 0 auto;
      @include mq(md) {
        padding-bottom: 60px;
      }
      h2 {
        @include fz_vw(40);
        line-height: 1.23;
        letter-spacing: 1px;
        text-align: center;
        color: #a81e2d;
        padding-bottom: 40px;
        @include mq(md) {
          @include fz_vw(72);
          padding-bottom: 20px;
        }
      }
      p {
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.6px;
        text-align: center;
        color: #000000;
        @include mq(md) {
          @include fz_vw(36);
        }
      }
      .backToBtn {
        display: block;
        text-align: center;
        padding-top: 80px;
        @include mq(md){
          padding-top: 40px;
        }
        a {
          display: inline-block;
          background-color: #b21d2e;
          padding: 15px;
          width: 100%;
          max-width: 255px;
          @include fz_vw(20);
          line-height: 1.55;
          letter-spacing: 1.2px;
          text-align: center;
          color: #ffffff;
          @include mq(md){
            @include fz_vw(32);
            padding: 10px;
          }
        }
      }
    }
  }

  .newsPager{
    max-width: 750px;
    width: 100%;
    padding: 0 20px;
    margin: 60px auto 100px;
    @include mq(md) {
      position: relative;
      z-index: 1;
      max-width: 100%;    
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      padding: 0 75px 0 0;
      margin: 0 auto 80px;

      dl{
        max-width: 75px;
        width: 100%;

        .pageAccordionArea{
          position: relative;
        }

        #pageAccordionBox{
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
          width: 100%;
          background-color: #fff;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transition: opacity 10s, visibility 10s, transform .5s;
          transform: translateY(-100%);
          &.open {
            visibility: visible;
            opacity: 1;
            transition: .4s;
            transform: translateY(0);
          }
        }
      }
      .maxPage{
        font-size: 2.3rem;
        line-height: 1;
        letter-spacing: 0.46px;
        color: #c7c7c7;
        margin: 0 0 0 10px;
      }
    }
    ol{
      flex-wrap: wrap;
      justify-content: flex-start;

      &.center{
        justify-content: center;
      }
      @include mq(md) {
        position: static;
        display: block;
        width: 100%;
        max-height: 215px;
        overflow-y: scroll;
        border-right: solid 1px #a81e2d;
        border-left: solid 1px #a81e2d;
        border-bottom: solid 1px #a81e2d;
      }

      li{
        width: 35px;
        text-align: center;
        @include mq(md) {
          width: auto;
          text-align: left;
        }
        a{
          @include mq(md) {
            display: block;
            font-size: 2.3rem;
            line-height: 1;
            letter-spacing: .572px;
            padding: 10px 15px 10px;

            &:hover {
              color: #000;
              background-color: #f4f5f5;
            }
          }
        }
        &.current{
          a{
            color: #b21d2e;
          }
          @include mq(md) {
            display: none;
          }
        }
      }
    }
    .next{
      @include mq(md) {
        position: absolute;
        width: 7px;
        top: 15px;
        left: calc(100% - 27px);
        padding: 0;

        &::after{
          display: none;
        }

        a{
          display: block;
        }
      }
    }
    .prev{
      @include mq(md) {
        position: absolute;
        width: 7px;
        top: 15px;
        left: calc(100% - 57px);
        padding: 0;

        &::after{
          display: none;
        }

        a{
          display: block;
        }
      }
    }


    .currentPage {
      cursor: pointer;
      font-size: 2.3rem;
      line-height: 1;
      letter-spacing: .572px;
      color: #a81e2d;
      display: block;
      background-color: #fff;
      padding: 10px 15px 10px;
      border: solid 1px #a81e2d;
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 6px;
        margin: 0 auto 0 0;
        background-image: url(/images/synopsis/icon_accordion.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 14%;
        top: 44%;
        transition: .4s;
      }
      &.open {
        border-bottom: solid 1px #c7c7c7;
        &::after {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
      //      &:hover {
      //        @include mq(md) {
      //          background-color: #f4f5f5;
      //        }
      //      }
    }
  }
}
@charset "utf-8";
#privacy {
  .privacy{
    max-width: 880px;
    padding: 60px 20px 100px;
    margin: 0 auto;

    @include mq(md) {
      padding: 40px 20px 70px;
    }

    .privacyLead{
      @include fz_vw(16);
      line-height: 1.5;
      letter-spacing: 0.4px;
      color: #000;
      margin: 0 0 60px;

      @include mq(md) {
        @include fz_vw(32);
        letter-spacing: 0.2px;
        margin: 0 0 40px;
      }
    }

    .privacyList{
      .privacyListTit{
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #000;
        margin: 0 0 18px;

        &::before{
          content: "";
          display: block;
          width: 70px;
          // height: 1px;
          // background-color: #b21d2e;
          border-bottom: solid 1px #b21d2e;
          margin: 0 0 20px;
        }

        @include mq(md) {
          @include fz_vw(42);
          letter-spacing: 0.3px;
          margin: 0 0 15px;
  
          &::before{
            margin: 0 0 18px;
          }
        }
      }
      .privacyListCon{
        @include fz_vw(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: #000;
        margin: 0 0 60px;

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(md) {
          @include fz_vw(32);
          letter-spacing: 0.2px;
          margin: 0 0 55px;
        }

        p{
          margin: 0 0 14px;

          &:last-of-type{
            margin-bottom: 0;
          }

          @include mq(md) {
            margin: 0 0 10px;
          }
        }

        ul{
          margin: 0 0 10px;

          li{
            position: relative;
            display: flex;
            padding-left: 5px;
            margin: 0 0 10px;
  
            &:last-of-type{
              margin-bottom: 0;
            }

            @include mq(md) {
              padding-left: 0;
              margin: 0 0 10px;
            }

            &::before{
              content: "・";
              flex-shrink: 0;
              font-weight: bold;
              @include fz_vw(24);
              line-height: 1.1;
              height: 0;

              @include mq(fixed) {
                line-height: 1;
              }

              @include mq(md) {
                @include fz_vw(50);
                margin: 0 -2px;
              }
            }
          }
        }
      }
    }
  }
}
@charset "utf-8";
/* ================================================================================
トップページ
================================================================================ */
#indexTop {
  /*キービジュアルスライドショー*/
  .topSlideContents {
    display: block;
    .inner {
      width: 100%;
      .swiper-container {
        .swiper-slide {
          img {
            width: 100%;
          }
        }
        /*ページネーション*/
        .swiper-pagination {
          bottom: 20px;
          @include mq(md) {
            bottom: 10px;
          }
          .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: #fff;
            opacity: 1;
            @include mq(md) {
              width: 7px;
              height: 7px;
            }
          }
          .swiper-pagination-bullet-active {
            background: #b3b3b3;
          }
        }
      }
    }
  }
  /*MISSION*/
  .missonLayout {
    background-color: #f7f8f8;
    .inner {
      max-width: 830px + 40px;
      padding: 45px 20px 35px;
      margin: 0 auto;
      @include mq(md) {
        padding: 13.02083% 20px 6.5104%;
      }
      h2 {
        @include fz_vw(28);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.68px;
        text-align: center;
        color: #b21d2e;
        margin-bottom: 30px;
        @include mq(md) {
          @include fz_vw(46);
          margin-bottom: 20px;
        }
        span {
          border-bottom: 4px solid #B21D2E;
          @include mq(md) {
            border-bottom: 2px solid #B21D2E;
          }
        }
      }
      p {
        @include fz_vw(30);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.16;
        letter-spacing: 1.14px;
        text-align: center;
        color: #000000;
        @include mq(md) {
          @include fz_vw(40);
        }
      }
      .moreBtnCom {
        padding: 35px 0 0 0;
        @include mq(md) {
          padding: 20px 0 0 0;
        }
      }
    }
  }
  /*ニュース&TOPICS*/
  .newsAndTopocs {
    width: 100%;
    .inner {
      padding: 55px 20px 35px;
      margin: 0 auto;
      @include mq(md) {
        padding: 12.3697% 20px 13.0208%;
      }
      h2 {
        @include fz_vw(28);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.68px;
        text-align: center;
        color: #b21d2e;
        margin-bottom: 30px;
        @include mq(md) {
          @include fz_vw(46);
          margin-bottom: 20px;
        }
        span {
          border-bottom: 4px solid #B21D2E;
          @include mq(md) {
            border-bottom: 2px solid #B21D2E;
          }
        }
      }
      .wrap {
        width: 100%;
        position: relative;
        max-width: 1000px;
        padding: 0 20px;
        margin: 0 auto;
        @include mq(md) {
          padding: 0;
        }
      }
      #newsSwiper {
        width: 100%;
        max-width: 845px;
        margin: 0 auto;
        .swiper-wrapper {
          @include mq(md) {
            display: block;
          }
          .swiper-slide {
            &:nth-child(n + 4) {
              @include mq(md) {
                display: none;
              }
            }
            @include mq(md) {
              margin-bottom: 15px;
            }
            a {
              display: block;
              .postNews {
                display: block;
                .thumbnail {
                  height: 0;
                  padding-top: 75%;
                  position: relative;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
                p.txt {
                  @include fz_vw(14);
                  line-height: 1.07;
                  color: #000000;
                  padding: 5px 0 10px;
                  @include mq(md) {
                    @include fz_vw(32);
                  }
                }
                p.more {
                  @include fz_vw(14);
                  line-height: 1.07;
                  color: #b21d2e;
                  @include mq(md) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .newslNext, .newslPrev {
        top: 30%;
        width: 3.125%;
        @include mq(md) {
          display: none;
        }
      }
      .newslNext {
        background-image: url('/images/common/slide_arrow_right.svg');
        right: 0;
      }
      .newslPrev {
        background-image: url('/images/common/slide_arrow_left.svg');
        left: 0;
      }
      .moreBtnCom {
        padding: 35px 0 0 0;
        @include mq(md) {
          padding: 0;
        }
      }
    }
  }
  /*ニュース&TOPICS 2カラム*/
  .newsAndTopicsTwoColumn {
    width: 100%;
    .inner {
      max-width: 840px;
      padding: 60px 20px 35px;
      margin: 0 auto;
      @include mq(md) {
        padding: 12.3697% 20px 8.0208%;
      }
      h2 {
        @include fz_vw(28);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.68px;
        text-align: center;
        color: #b21d2e;
        margin-bottom: 30px;
        @include mq(md) {
          @include fz_vw(46);
          margin-bottom: 30px;
        }
        span {
          border-bottom: 4px solid #B21D2E;
          @include mq(md) {
            border-bottom: 2px solid #B21D2E;
          }
        }
      }
      .twoColumnLayout {
        display: flex;
        flex-wrap: wrap;
        @include mq(md) {
          display: block;
        }
      }
      .newsAndTopicsBox {
        max-width: 380px;
        overflow-wrap: break-word;
        word-break: keep-all;
        @include mq(md) {
          max-width: 100%;
        }
        &:not(:last-child) {
          margin-right: 40px;
          @include mq(md) {
            margin-right: 0;
            padding-bottom: 8.5104%;
          }
        }
        .newsAndTopicsImg {
          margin-bottom: 20px;
          @include mq(md) {
            margin-bottom: 4.5104%;
          }
          img {
            width: 100%;
          }
        }
        .newsAndTopicsTit {
          font-size: 2rem;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: 0.5px;
          margin-bottom: 20px;
          @include mq(md) {
            margin-bottom: 4.5104%;
          }
        }
        .newsAndTopicsTxt {
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: 0.4px;
          margin-bottom: 20px;
          @include mq(md) {
            margin-bottom: 4.5104%;
          }
        }
      }
    }
  }
  /*BOOK DONATION*/
  .bookLayout {
    .inner {
      display: flex;
      width: 100%;
      @include mq(md) {
        display: block;
      }
      .bgBack {
        width: 32.2115%;
        background-image: url('/images/index/book_photo.jpg');
        padding-top: 36.61923%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center right;
        @include mq(max) {
          padding-top: 380px;
          width: 42.2115%;
          background-image: url('/images/index/book_photo_long.jpg');
        }
        @include mq(fixed) {
          padding-top: 380px;
        }
        @include mq(md) {
          width: 100%;
          background-image: url('/images/index/book_photo_sp.jpg');
          padding-top: 58.59375%;
        }
      }
      .bookTxtLayout {
        background-color: #b21d2e;
        flex: 1;
        .ineerBook {
          max-width: 565px;
          width: 100%;
          padding: 40px 0 0 40px;
          @include mq(md) {
            max-width: none;
            padding: 6.510416% 20px 9.11458%;
          }
          h2 {
            @include fz_vw(28);
            line-height: 1;
            letter-spacing: 1.68px;
            color: #ffffff;
            margin-bottom: 30px;
            @include mq(md) {
              @include fz_vw(46);
              text-align: center;
              margin-bottom: 6.8681318%;
            }
            span {
              display: inline-block;
              border-bottom: 4px solid #fff;
              padding-bottom: 5px;
              @include mq(md) {
                border-bottom: 2px solid #fff;
              }
            }
          }
          p.txt {
            @include fz_vw(23);
            line-height: 1.3;
            letter-spacing: 0.58px;
            text-align: left;
            color: #ffffff;
            @include mq(md) {
              @include fz_vw(32);
            }
          }
          .moreBtnCom {
            padding-top: 30px;
            @include mq(md) {
              padding-top: 4.120879%;
            }
            a {
              background-color: #fff;
              color: #b21d2e;
              @include fz_vw(16);
              max-width: 220px;
              margin: 0;
              font-weight: bold;
              @include mq(md) {
                max-width: none;
                @include fz_vw(40);
              }
            }
          }
        }
      }
    }
  }
  /*SYNOPSIS*/
  .stnopisLayout {
    padding: 50px 0;
    @include mq(md) {
      padding: 13.0208% 20px;
    }
    .inner {
      max-width: 880px;
      margin: 0 auto;
      padding: 0 20px;
      @include mq(md) {
        padding: 0;
      }
      .txtBox {
        border-bottom: 1px solid #231815;
        h2 {
          @include fz_vw(28);
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 1.68px;
          text-align: center;
          color: #b21d2e;
          margin-bottom: 30px;
          @include mq(md) {
            @include fz_vw(46);
            margin-bottom: 20px;
          }
          span {
            display: inline-block;
            border-bottom: 4px solid #B21D2E;
            @include mq(md) {
              border-bottom: 2px solid #B21D2E;
            }
          }
        }
        p.txt {
          max-width: 710px;
          margin: 0 auto;
          @include fz_vw(20);
          line-height: 1.2;
          letter-spacing: 0.18px;
          text-align: center;
          color: #000000;
          padding-bottom: 15px;
          @include mq(md) {
            @include fz_vw(32);
            padding-bottom: 4.8076%;
            max-width: none;
            text-align: left;
          }
        }
        .moreBtnCom {
          padding: 0 0 32px 0;
          @include mq(md) {
            padding-bottom: 6.86813%;
          }
        }
      }
    }
  }
  .whoWeAreLayout {
    .inner {
      max-width: 840px;
      padding: 50px 20px 50px;
      margin: 0 auto;
      @include mq(md) {
        padding-bottom: 10.4166%;
      }
      h2 {
        @include fz_vw(28);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.68px;
        text-align: center;
        color: #b21d2e;
        margin-bottom: 80px;
        @include mq(md) {
          @include fz_vw(46);
          margin-bottom: 0;
        }
        span {
          border-bottom: 4px solid #B21D2E;
          @include mq(md) {
            border-bottom: 2px solid #B21D2E;
          }
        }
      }
      .logoBox {
        padding-bottom: 65px;
        @include mq(md) {
          padding: 17.1703% 0 13.7362% 0;
        }
        img {
          margin: 0 auto;
          max-width: 225px;
          @include mq(md) {
            width: 65.3846%;
          }
        }
      }
      p.txt {
        @include fz_vw(21);
        line-height: 1.3;
        font-weight: bold;
        color: #231815;
        text-align: center;
        @include mq(md) {
          @include fz_vw(32);
          text-align: left;
          line-height: 1.5;
        }
        span {
          color: #b21d2e;
          display: inline-block;
        }
      }
      .moreBtnCom {
        padding-top: 45px;
        a {
          max-width: 200px;
          @include mq(md) {
            max-width: none;
          }
        }
      }
    }
  }
}
@charset "utf-8";
.news {
  padding-top: 60px;
  margin-bottom: 30px;
  @include mq(md) {
    padding-top: 40px;
    margin-bottom: 20px;
  }
  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    @include fz_vw(28);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.68px;
    text-align: center;
    color: #b21d2e;
    text-transform: uppercase;
    margin-bottom: 60px;
    @include mq(md) {
      @include fz_vw(46);
      margin-bottom: 40px;
    }
    span {
      border-bottom: 4px solid #b21d2e;
      @include mq(md) {
        border-bottom: 2px solid #b21d2e;
      }
    }
  }
  &List {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include mq(md) {
      margin-bottom: 30px;
    }
    &::after {
      content: '';
      display: block;
      flex-basis: calc((100% - 48px)/3);
      @include mq(md) {
        flex-basis: 48%;
      }
    }
    &Item {
      flex-basis: calc((100% - 48px)/3);
      margin-bottom: 60px;
      @include mq(md) {
        flex-basis: 48%;
        margin-bottom: 40px;
      }
      &Link {
        display: block;
        cursor: pointer;
      }
      &Img {
        margin-bottom: 10px;
        height: 0;
        padding-top: 75%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &Date {
        font-family: $base-font;
        @include fz_vw(13);
        letter-spacing: 0.33px;
        color: #555;
        margin-bottom: 6px;
        position: relative;
        padding-left: 10px;
        @include mq(md) {
          @include fz_vw(26);
          letter-spacing: 0.65px;
          color: #666;
          padding-left: 18px;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 1px;
          background-color: #b21d2e;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          @include mq(md) {
            width: 10px;
          }
        }
      }
      &Txt {
        @include fz_vw(16);
        letter-spacing: 0.4px;
        color: #333;
        margin-bottom: 10px;
        padding-right: 10px;
        @include mq(md) {
          @include fz_vw(32);
          color: #000;
        }
      }
      &More {
        @include fz_vw(14);
        line-height: 1.07;
        color: #b21d2e;
        @include mq(md) {
          @include fz_vw(32);
        }
      }
    }
  }
  &Pager {
    max-width: 140px;
    margin: auto;
    ol {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      li {
        flex-basis: auto;
        @include fz_vw(24);
        @include mq(md) {
          @include fz_vw(52);
        }
        a {
          color: #c7c7c7;
        }
        &.current {
          text-decoration: underline;
          color: #a81e2d;
        }
        &.prev,
        &.next {
          position: absolute;
          top: 0;
          &::after {
            content: '';
            width: 10px;
            height: 10px;
            border: solid 1px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(45deg);
            cursor: pointer;
          }
          a {
            color: #fff;
          }
          &.hide {
            opacity: 0;
            visibility: hidden;
          }
        }
        &.prev {
          right: 100%;
          &::after {
            right: 10px;
            border-color: transparent transparent #a81e2d #a81e2d;
          }
        }
        &.next {
          left: 100%;
          &::after {
            left: 10px;
            border-color: #a81e2d #a81e2d transparent transparent;
          }
        }
      }
    }
  }
  &Single {
    margin-bottom: 70px;
    @include mq(md) {
      margin-bottom: 30px;
    }
    &Tit {
      @include fz_vw(24);
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: #333;
      margin-bottom: 10px;
      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.5;
        letter-spacing: 0.9px;
      }
    }
    &Date {
      font-family: $base-font;
      @include fz_vw(14);
      letter-spacing: 0.35px;
      color: #555;
      margin-bottom: 40px;
      padding-left: 10px;
      position: relative;
      @include mq(md) {
        @include fz_vw(28);
        letter-spacing: 0.7px;
        color: #666;
        margin-bottom: 30px;
        padding-left: 18px;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 1px;
        background-color: #b21d2e;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        @include mq(md) {
          width: 10px;
        }
      }
    }
    &Content {
      margin-bottom: 60px;
      @include mq(md) {
        margin-bottom: 50px;
      }
      img {
        width: 100%;
        max-width: 480px;
        margin: 0 auto 40px;
        @include mq(md) {
          margin-bottom: 25px;
        }
      }
      p {
        @include fz_vw(16);
        letter-spacing: 0.4px;
        color: #000;
        @include mq(md) {
          @include fz_vw(36);
          letter-spacing: 0.9px;
        }
      }
      a {
        color: #000;
        text-decoration: underline;
      }
      .wp-video{
        margin: 0 auto;
        max-width: 100%;
      }
      video{
        width: 100%;
        height: auto;
      }
      iframe{
        display: block;
        max-width: 640px;
        width: 100%;
        height: calc(640px * 0.5625);
        margin: 0 auto;

        @include mq(md) {
          max-width: 84vw;
          height: calc(84vw * 0.5625);
        }
      }
    }
    &Btn {
      font-size: 1.9rem;
      font-weight: 700;
      line-height: 1.55;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
      background-color: #b21d2e;
      padding: 15px 0;
      display: block;
      width: 250px;
      margin: 60px auto 0;
      cursor: pointer;
      @include mq(md) {
        font-size: 2.2rem;
        width: 100%;
        margin: 0 auto;
        line-height: 1.68;
        letter-spacing: 1.14px;
        margin: 60px auto 20px;
        padding: 5px 25px;
      }
    }
  }
}
@charset "utf-8";
#errorTop {
  .errorLayout {
    display: block;
    .inner {
      max-width: 880px;
      padding: 60px 20px 0;
      margin: 0 auto;
      @include mq(md) {
        padding-bottom: 60px;
      }
      h2 {
        @include fz_vw(40);
        line-height: 1.23;
        letter-spacing: 1px;
        text-align: center;
        color: #a81e2d;
        padding-bottom: 40px;
        @include mq(md) {
          @include fz_vw(72);
          padding-bottom: 20px;
        }
      }
      p {
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.6px;
        text-align: center;
        color: #000000;
        @include mq(md) {
          @include fz_vw(36);
        }
      }
      .backToBtn {
        display: block;
        text-align: center;
        padding-top: 80px;
        @include mq(md){
          padding-top: 40px;
        }
        a {
          display: inline-block;
          background-color: #b21d2e;
          padding: 15px;
          width: 100%;
          max-width: 255px;
          @include fz_vw(20);
          line-height: 1.55;
          letter-spacing: 1.2px;
          text-align: center;
          color: #ffffff;
          @include mq(md){
            @include fz_vw(32);
            padding: 10px;
          }
        }
      }
    }
  }
}
@charset "utf-8";

.columnLayout {
  display: flex;
  justify-content: space-between;

  @for $i from 1 through 8 {
    $width: percentage(1 / $i);

    .col#{$i} {
      flex-basis: floor($width);
    }
  }
}

.keyVisual {
  overflow: hidden;

  &Bg {
    width: 100%;
  }

  &Tit {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.58);
    font-weight: bold;
    @include fz_vw(48);
    line-height: 1.73;
    letter-spacing: 2.88px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 880px;
    margin: auto;
    padding: 0 20px;
    display: flex;
    align-items: center;

    @include mq(md) {
      @include fz_vw(60);
      padding: 0;
      left: 13%;
    }
  }
}

.pageBottomLayout {
  max-width: 880px;
  margin: 0 auto;
  padding: 30px 20px 10px;

  @include mq(md) {
    padding: 40px 20px 0;
  }

  /* アンカーリンクデザイン--------------------- */
  .pageTop {
    width: 55px;
    height: 40px;
    margin-left: auto;
    cursor: pointer;
  }

  /* パンくずリスト--------------------------- */
  .breadCrumb {
    ol {
      @include clearfix;
      position: relative;
      width: 100%;
      padding-left: 1em;
      word-break: break-all;
      text-align: left;
      line-height: 1;
      color: #acabab;

      @include mq(md) {
        max-width: initial;
        padding: 0 0 0 2em;
        margin-top: 26px;
        margin-bottom: 13px;
      }

      &::before {
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin: auto;
        content: "";
        background: url("/images/common/icon_home.svg") center/contain no-repeat;

        @include mq(md) {
          top: 0;
          width: 12px;
          height: 12px;
        }
      }

      & > li {
        display: inline;
        font-family: $base-font;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 1.6px;
        text-align: left;
        color: #747474;

        @include mq(md) {
          font-size: 1.1rem;
        }

        a {
          font-size: 1.2rem;
          line-height: 1.5;
          letter-spacing: 1.6px;
          text-align: left;

          @include mq(md) {
            font-size: 1.1rem;
          }
        }

        & + li::before {
          padding: 0 7px 0 5px;
          color: #b21d2e;
          content: ">";
          @include mq(md){
            padding: 0 
          }
        }
      }
    }
  }
}

@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */
// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;
// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;
// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;
//ベースフォント
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600&display=swap');
$GaramondPremrPro:'EB Garamond', serif;
$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;
// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1040px)",
  "max": "screen and (min-width : 1366px)",
) !default;
/* ================================================================================
importファイル
================================================================================ */
// YakuhanGochic
@import "lib/yakuhanjp-noto";
// @mixin定義
@import "foundation/mixin";
// @extend定義
@import "foundation/extend";
// リセット
@import "foundation/reset";
// 印刷
@import "foundation/print";
// 基本 
@import "layout/bace";
// ヘッダー
@import "layout/header";
// フッター
@import "layout/footer";
// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";
/************************************
トップページ
***********************************/
// トップページ
@import "page/index";
@import "page/overview";
@import "page/news";
@import "page/bookdonation";
@import "page/recipient-Institution";
@import "page/synopsis";
@import "page/whoweare";
@import "page/contact";
@import "page/application";
@import "page/search";
@import "page/404";
@import "page/books";
@import "page/privacy";
@import "page/terms";
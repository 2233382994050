@charset "utf-8";
/* ================================================================================
フッター
================================================================================ */
.supportedBy{
  position: relative;
  max-width: 100%;
  padding: 20px 0;

  @include mq(md) {
    padding: 20px 0 10px;
  }

  .supportedByLogo{
    max-width: 100%;
    width: 100%;
    background-color: #fff;

    img{
      max-width: 300px;
      width: 100%;
      margin: 0 20px 0 auto;
    }

    @include mq(max) {
      img{
        margin: 0 100px 0 auto;
      }
    }

    @include mq(md) {
      img{
        max-width: 215px;
        margin: 0 10px 0 auto;
      }
    }
  }
}
footer#footer {
  position: relative;
  background-color: #B21D2E;
  color: #fff;

  .inner {
    max-width: 880px;
    padding: 0 20px;
    margin: 0 auto;
    .flixBox {
      padding: 60px 0 55px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      border-bottom: solid 1px #fff;
      @include mq(md) {
        align-items: center;
        justify-content: center;
        padding: 11.27% 15px 27.35%;
      }
      .logo {
        width: 18.2352%;
        @include mq(md) {
          max-width: 40%;
          width: 100%;
          padding: 0;
          img {
            width: 100%;
            margin: 0 auto;
          }
        }
      }
      nav.footerNav {
        padding: 0 0 0 8%;
        @include mq(md) {
          padding: 0 0 0 35px;
        }
        ul {
          display: flex;
          @include mq(md) {
            display: block;
          }
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            &.spAnker {
              display: none;
            }
            @include mq(md) {
              padding-bottom: 20px;
              justify-content: flex-start;
              &.spAnker {
                display: block;
              }
              &.pcAnker {
                display: none;
              }
            }
            &:last-child {
              a{
                padding-right: 0;
              }
              @include mq(md) {
                padding-bottom: 0;
              }
            }
            &+li {
              border-left: 1px solid #fff;
              @include mq(md) {
                border: 0;
              }
            }
            &:nth-child(1) {
              @include mq(md) {
                order: 1;
              }
            }
            &:nth-child(2) {
              @include mq(md) {
                order: 3;
              }
            }
            &:nth-child(3) {
              @include mq(md) {
                order: 5;
              }
            }
            &:nth-child(4) {
              @include mq(md) {
                order: 2;
              }
            }
            &:nth-child(5) {
              @include mq(md) {
                order: 4;
              }
            }
            &:nth-child(6) {
              @include mq(md) {
                order: 6;
              }
            }
            a,
            span {
              @include fz_vw(16);
              line-height: 1;
              letter-spacing: .8px;
              text-align: left;
              color: #ffffff;
              display: block;
              padding: 0 12px;
              @include mq(md) {
                padding: 0;
                @include fz_vw(30);
                font-weight: bold;
                text-align: left;
                letter-spacing: 1.35px;
              }
              &.notHover {
                cursor: default;
                color: #a7a7a7;
              }
            }
          }
        }
      }
        
      .externalLink {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        bottom: 45px;
        @include mq(md) {
          max-width: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 6.6%;
        }
      }
      .snsTicon {
        flex-shrink: 0;
        width: 8.6%;
        a {
          display: block;
        }
        &.xIcon{
          width: 7.42%;
        }
        @include mq(md) {
          //right: 50px;
          //top: inherit;
          //bottom: -20px;
          //width: 9.615384%;
          position: static;
          width: 10.45%;

          img{
            width: 100%;
          }
        }
      }
      .snsFicon {
        flex-shrink: 0;
        width: 8.6%;
        margin-left: 15px;
        a {
          display: block;
        }
        @include mq(md) {
          //right: 0;
          //top: inherit;
          //bottom: -20px;
          //width: 9.615384%;
          position: static;
          width: 10.45%;
        }
      }
      .snsIicon {
        position: absolute;
        top: 0;
        right: 10px;
        width: 4.7058%;
        margin-top: 2.34117%;
        a {
          display: block;
        }
        @include mq(md) {
          //right: 0;
          //top: inherit;
          //bottom: -20px;
          //width: 9.615384%;
          position: static;
          width: 36px;
          margin-right: 20px;
        }
      }
    }
  }
  
  .tkfdLogo{
    flex-shrink: 0;
    max-width: 52.723%;
    width: 100%;
    margin-left: 30px;

    img{
      max-width: 184px;
      width: 100%;
    }
    @include mq(md) {
      max-width: 60%;
      margin-left: 25px;

      img{
        max-width: 100%;
        width: 100%;
      }
    }
  }

  small {
    display: block;
    text-align: right;
    @include fz_vw(10);
    color: #fff;
    @include mq(md) {
      padding: 6.7% 20px 6.7%;
      text-align: center;
      @include fz_vw(20);
    }
  }

  .footerBottom{
    display: flex;
    justify-content: space-between;
    max-width: 880px;
    padding: 20px 20px 30px;
    margin: 0 auto;

    @include mq(md) {
      display: block;
      padding: 5.3% 20px 0;
    }

    .footerOtherNav{
      display: flex;
      align-items: center;

      @include mq(md) {
        justify-content: center
      }

      li{
        @include fz_vw(12);
        line-height: 1;
        letter-spacing: 1.2px;
        border-right: 1px solid #fff;
        padding-right: 20px;

        @include mq(md) {
          display: block;
          @include fz_vw(22);
          padding-right: 10px;
        }

        &:last-of-type{
          border-right: none;
          padding-right: 0;
          padding-left: 20px;

          @include mq(md) {
            padding-right: 0;
            padding-left: 10px;
          }
        }
      }
      a{
        display: block;
        @include fz_vw(12);
        line-height: 1;
        letter-spacing: 1.2px;
        color: #fff;

        @include mq(md) {
          display: block;
          @include fz_vw(22);
        }
      }
    }
  }
}
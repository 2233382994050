@charset "utf-8";

.contact {
  max-width: 820px;
  margin: 0 auto;
  padding: 60px 20px 0;

  &Txt {
    @include fz_vw(20);
    line-height: 1.5;
    letter-spacing: 0.6px;
    text-align: center;
    margin-bottom: 80px;

    @include mq(md) {
      @include fz_vw(40);
    }
  }

  &Form {
    color: #000;

    dl {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      @include mq(md) {
        display: block;
        margin-bottom: 60px;
      }

      dt,
      dd {
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: solid 1px #eaeaea;
      }

      dt {
        @include fz_vw(20);
        line-height: 1.8;
        letter-spacing: 0.5px;
        flex-basis: 20%;

        @include mq(md) {
          @include fz_vw(40);
          border: none;
          margin-bottom: 0;
        }
      }

      dd {
        @include fz_vw(16);
        line-height: 1.71;
        letter-spacing: 0.7px;
        flex-basis: 80%;
        font-family: $base-font;

        @include mq(md) {
          @include fz_vw(32);
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          border: solid 1px #c7c7c7;
          border-radius: 0;
          background-color: #fff;
          outline: 0;
          @include fz_vw(14);
          color: #555;
          -webkit-appearance: none;

          @include mq(md) {
            @include fz_vw(30);
          }
        }

        textarea {
          min-height: 230px;
        }
      }
    }

    .g-recaptcha {
      margin-bottom: 30px;

      & > div {
        margin: auto;
      }
    }

    &BtnColumn {
      display: flex;
      justify-content: space-between;
      max-width: 560px;
      margin: auto;
      flex-wrap: wrap;

      @include mq(md) {
        margin-bottom: 40px;
      }

      .g-recaptcha {
        width: 100%;
        display: block;
      }
    }

    &Btn {
      font-family: $GaramondPremrPro;
      @include fz_vw(20);
      font-weight: bold;
      line-height: 1.55;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
      background-color: #b21d2e;
      border: none;
      padding: 15px 0;
      display: block;
      width: 250px;
      margin: auto;
      cursor: pointer;
      border-radius: 0;

      @include mq(md) {
        @include fz_vw(40);
        padding: 10px 0;
        width: 340px;
      }

      &.back {
        color: #b21d2e;
        border: 1px solid #b21d2e;
        background-color: #fff;

        @include mq(md) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.complete {
  max-width: 760px;
  margin: 0 auto;
  padding: 60px 20px 0;
  text-align: center;
  overflow: hidden;

  &Tit {
    @include fz_vw(40);
    line-height: 1.23;
    letter-spacing: 1px;
    color: #a81e2d;
    display: inline-block;
    position: relative;
    margin-bottom: 40px;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 110%;
      height: 1px;
      background-color: #a81e2d;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &::before {
      left: -130%;
    }

    &::after {
      right: -130%;
    }
  }

  &Txt {
    @include fz_vw(24);
    line-height: 1.5;
    letter-spacing: 0.6px;
    text-align: center;
    margin-bottom: 80px;

    @include mq(md) {
      @include fz_vw(36);
    }
  }

  &Btn {
    font-family: $GaramondPremrPro;
    @include fz_vw(20);
    font-weight: bold;
    line-height: 1.55;
    letter-spacing: 1.2px;
    color: #fff;
    background-color: #b21d2e;
    border: none;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: auto;
    cursor: pointer;

    @include mq(md) {
      @include fz_vw(40);
    }
  }
}

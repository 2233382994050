@charset "utf-8";
// #overview {
//   .keyVisual {
//     &Tit {
//       text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
//       color: #000;
//     }
//   }
// }
.overview {
  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    @include fz_vw(28);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.68px;
    text-align: center;
    color: #b21d2e;
    margin-bottom: 40px;
    @include mq(md) {
      @include fz_vw(46);
      margin-bottom: 20px;
    }
    span {
      border-bottom: 4px solid #b21d2e;
      @include mq(md) {
        border-bottom: 2px solid #b21d2e;
      }
    }
  }
  &Mission {
    padding: 60px 20px;
    @include mq(md) {
      padding: 40px 20px;
    }
    &Txt {
      @include fz_vw(38);
      line-height: 1.16;
      letter-spacing: 1.14px;
      text-align: center;
      font-weight: bold;
      max-width: 840px;
      margin: auto;
      @include mq(md) {
        @include fz_vw(40);
      }
    }
  }
  &Project {
    background-color: #f7f8f8;
    padding: 50px 0 70px;
    @include mq(md) {
      padding: 30px 0 40px;
    }
    &Intro {
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      margin-bottom: 60px;
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 30px;
      }
    }
    &Layout {
      @include mq(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .overviewProjectSubTit{
      flex-basis: 100%;
      @include fz_vw(24);
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.6px;
      text-align: center;
      color: #a81e2d;
      margin-bottom: 20px;
  
      @include mq(md) {
        @include fz_vw(48);
        margin: 60px auto 20px;
      }
    }
    &Box {
      background-color: #fff;
      flex-basis: 31%;
      @include mq(md) {
        margin-bottom: 30px;
      }
      @include mq(pc) {
        &:first-of-type,
        &:nth-of-type(2) {
          flex-basis: 100%;
          display: flex;
          .overviewProjectBox {
            &Img {
              flex-basis: 41%;
              max-width: 50%;
            }
            &Item {
              flex-basis: 60%;
              padding: 30px 50px;
            }
            &Tit {
              margin-left: 0;
            }
          }
        }
        &:first-of-type {
          margin-bottom: 40px;
        }
        // &:nth-of-type(2) {
        //   margin-bottom: 80px;
        // }
        &:nth-of-type(3) {
          .overviewProjectBoxTxt{
            word-break: break-all;
          }
        }
      }
      &Img {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
      }
      &Item {
        padding: 20px 20px 40px;
        @include mq(md) {
          padding: 20px 15px 15px 30px;
        }
      }
      &Tit {
        @include fz_vw(20);
        line-height: 1.25;
        letter-spacing: 0.5px;
        color: #b21d2e;
        margin-left: 15px;
        margin-bottom: 20px;
        position: relative;
        text-indent: -25px;
        @include mq(md) {
          @include fz_vw(40);
          text-indent: -18px;
          margin-left: 0;
          margin-bottom: 15px;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 1px;
          background-color: #b21d2e;
          margin-right: 10px;
          margin-bottom: 6px;
          @include mq(md) {
            width: 11px;
            margin-right: 7px;
          }
        }
      }
      &Txt {
        @include fz_vw(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        margin-bottom: 30px;
        @include mq(md) {
          @include fz_vw(32);
          margin-bottom: 20px;
        }
      }
      &Btn {
        background-color: #b21d2e;
        @include fz_vw(12);
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.42px;
        text-align: center;
        color: #fff;
        width: 45%;
        display: block;
        padding: 10px 0;
        margin-left: auto;
        @include mq(md) {
          @include fz_vw(30);
          width: 100%;
        }
      }
      &Link {
        @include fz_vw(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: #555;
        border-bottom: solid 1px #b21d2e;
        display: inline-block;
        position: relative;
        margin-left: 15px;
        margin-bottom: 10px;
        @include mq(md) {
          @include fz_vw(32);
        }
        &::before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 7px;
          border: solid #b21d2e;
          border-width: 1px 1px 0 0;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          left: -15px;
          margin: auto;
        }
      }
    }
  }
  &Pdf{
    &Layout{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px 0 0;

      @include mq(md) {
        display: block;
        padding: 0 30px;
        margin: 40px 0 0;
      }

      &Item{
        max-width: 50%;
        width: 100%;
        padding: 0 0 0 40px;

        @include mq(md) {
          max-width: 100%;
          padding: 0;
        }
      }
      &Img{
        max-width: 50%;
        width: 100%;

        a{
          display: block;
          max-width: 57.143%;
          margin: 0 auto;
        }

        @include mq(md) {
          max-width: 87.273%;
          margin: 0 auto 25px;

          a{
            max-width: 100%;
          }
        }
      }
      &Tit{
        @include fz_vw(24);
        line-height: 1.75;
        letter-spacing: 0.36px;
        color: #b21d2e;
        margin: 0 0 40px;

        @include mq(md) {
          @include fz_vw(40);
          line-height: 1.23;
          letter-spacing: .56px;
          margin: 0 0 30px;
        }
      }
      &Txt{
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.12px;
        color: #000;
        margin: 0 0 40px;

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.5;
          letter-spacing: .45px;
        }
      }
      &Btn{
        display: inline-block;
        max-width: 180px;
        width: 100%;
        padding: 14px 0 ;
        background-color: #B21D2E;
        @include fz_vw(13);
        color: #fff;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.65px;
        line-height: 1;

        @include mq(md){
          max-width: 100%;
          @include fz_vw(30);
          letter-spacing: .525px;
          padding: 12px 0;
          margin: 0 auto;
        }
      }
    }
  }
}

#overview{
  .whoweare {
    padding-top: 60px;
  
    @include mq(md) {
      padding-top: 40px;
    }
  
    .inner {
      max-width: 880px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    h2 {
      @include fz_vw(28);
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1.68px;
      text-align: center;
      color: #b21d2e;
      margin-bottom: 40px;
      @include mq(md) {
        @include fz_vw(46);
        margin-bottom: 25px;
      }
      span {
        border-bottom: 4px solid #b21d2e;
        @include mq(md) {
          border-bottom: 2px solid #b21d2e;
        }
      }
    }
  
    &Intro {
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      margin-bottom: 60px;

      a {
        color: #b21d2e;
        display: inline-block;
      }
  
      @include mq(md) {
        @include fz_vw(40);
        margin-bottom: 40px;
      }
    }
  
    &Logo {
      width: 42.26%;
      margin: 0 auto 64px;

      img{
        margin: 0 auto;
      }
  
      @include mq(md) {
        width: 60%;
        margin-bottom: 40px;
      }
    }
  
    &Tit {
      @include fz_vw(24);
      line-height: 1;
      letter-spacing: 0.6px;
      text-align: left;
      color: #a81e2d;
      margin-bottom: 20px;
  
      @include mq(md) {
        @include fz_vw(48);
      }
    }
  
    &Txt {
      @include fz_vw(16);
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-bottom: 64px;
  
      @include mq(md) {
        @include fz_vw(32);
        margin-bottom: 40px;
      }
    }
  
    &Btn {
      @include fz_vw(19);
      font-weight: bold;
      line-height: 1.55;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
      background-color: #b21d2e;
      padding: 15px 0;
      display: block;
      width: 250px;
      margin: 0 auto 82px;
      cursor: pointer;
  
      @include mq(md) {
        @include fz_vw(38);
        width: 70.423%;
        margin: 0 auto 60px;
      }
    }
  
    .flex{
      display: flex;
      justify-content: center;
      margin: 0 auto 50px;
  
      @include mq(md) {
        display: block;
        margin-bottom: 20px;
      }
    }
    &LogoBtn {
      @include fz_vw(20);
      font-weight: bold;
      line-height: 1.55;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
      background-color: #b21d2e;
      padding: 15px 0;
      display: block;
      width: 316px;
      margin: 0 15px;
      cursor: pointer;
  
      @include mq(md) {
        @include fz_vw(38);
        max-width: 100%;
        width: 80%;
        margin: 20px auto 0;
      }
    }
  }
}
@charset "utf-8";

/* ======================================================================

リセット

====================================================================== */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}
html {
  text-size-adjust: 100%;
}
body {
  *font: x-small;
  color: $basicColor;
  font-size: $mainRemFontSize;
  font-family: $base-font;
  font-weight: 500; // 横幅768pxまで
  @include mq(md) {
    font-size: $mainFontSpSize;
  }
}
html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; // Firefoxの標準ボールド対策
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}
q {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}
legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
fieldset {
  margin: 0 2px;
  border: 1px solid $borderColor;
  padding: .35em .625em .75em;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}
mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  th,
  td {
    border-collapse: collapse;
    border-spacing: 0;
  }
}
caption,
th {
  text-align: left;
}
hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid $borderColor;
}
img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}
input,
select {
  vertical-align: middle;
}
button,
select {
  text-transform: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  outline: none;
}
select::-ms-expand {
  display: none;
}
button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}
input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[type='checkbox'],
[type='radio'] {
  padding: 0;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}
// Firefox 18 以前
:-moz-placeholder {
  color: #ccc;
}
// Firefox 19 以降
::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
// IE 10 以降
:-ms-input-placeholder {
  color: #ccc;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[aria-busy='true'] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  color: inherit;
  width: 100%;
  outline: none;
  padding: 0;
  border-radius: 0;
  &::-ms-clear,
  &::-ms-reveal {
    visibility: hidden;
  }
}
[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden='false']:focus {
  clip: auto;
}
strong {
  font-weight: bold;
}
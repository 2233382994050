@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 100;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Thin.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 300;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 400;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 500;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 700;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJP_Noto";
  font-style: normal;
  font-weight: 900;
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.eot");
  src: url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff2") format("woff2"), url("/css/fonts/YakuHanJP/YakuHanJP-Black.woff") format("woff");
}

/* ================================================================================
フォントサイズ VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/*Placeholder*/
/*PC設定*/
/* EXTEND
------------------------------------------------------------- */
/* ======================================================================

リセット

====================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  outline: none;
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  color: inherit;
  width: 100%;
  outline: none;
  padding: 0;
  border-radius: 0;
}

input::-ms-clear, input::-ms-reveal,
textarea::-ms-clear,
textarea::-ms-reveal,
select::-ms-clear,
select::-ms-reveal {
  visibility: hidden;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* ================================================================================

印刷時の定義

================================================================================ */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

*::before, *::after {
  box-sizing: border-box;
}

.hoge {
  width: 100%;
  background-color: antiquewhite;
  height: 800px;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.tabOnly {
  display: none !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tabOnly {
    display: block !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "EB Garamond", serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;
}

body.fixed {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

@media screen and (max-width: 767px) {
  main {
    margin-top: 60px;
  }
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.highlight {
  display: inline !important;
  background-color: #FFFF5A !important;
  padding: 0 2px 2px !important;
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
.detailModalContent {
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  background: #fff;
  position: fixed;
  display: none;
  z-index: 1002;
  display: none;
}

.detailModalContent .close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 100;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .detailModalContent .close {
    top: 20px;
    right: 20px;
  }
}

.detailModalContent .close a {
  display: block;
  text-indent: -9999px;
  width: 5vw;
  height: 5vw;
  max-width: 60px;
  max-height: 60px;
  background: url(/images/common/modal_close_btn.svg) no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .detailModalContent .close a {
    width: 6.4vw;
    height: 6.4vw;
    background: url(/images/common/modal_close_btn_sp.svg) no-repeat;
    background-size: contain;
  }
}

.detailModalContent .modalInner {
  border: 10px solid #B21D2E;
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 120px;
  height: 100vh;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner {
    padding-bottom: 16vw;
  }
}

.detailModalContent .modalInner h3.subTtl {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  text-align: center;
  margin: 120px auto 47px;
}

@media screen and (min-width: 1040px) {
  .detailModalContent .modalInner h3.subTtl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner h3.subTtl {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner h3.subTtl {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin: 16vw auto 5.33vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .detailModalContent .modalInner h3.subTtl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner h3.subTtl {
    font-size: 4.6875vw;
  }
}

.detailModalContent .modalInner img.dateImg {
  margin: 0 auto;
  width: 80vw;
  max-width: 1200px;
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner img.dateImg {
    width: 75%;
  }
}

/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* ================================================================
ヘッダー
================================================================ */
header#header {
  background-color: #b21d2e;
  padding: 25px 0;
  position: relative;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  header#header {
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 61px;
  }
}

header#header .spNavigations {
  display: none;
}

header#header .spNavigations.open {
  transform: translateY(0%);
}

@media screen and (max-width: 767px) {
  header#header .spNavigations {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    background: rgba(168, 30, 45, 0.95);
    transform: translateY(-130%);
    z-index: 1;
    transition: 2s;
    transition: transform .8s, opacity .8s, -webkit-transform .8s;
    overflow: scroll;
  }
}

header#header .spNavigations .txtFlix {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 100px;
}

header#header .spNavigations .txtFlix p {
  font-size: 2.1rem;
  font-size: 2.01923vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.19px;
  text-align: left;
  color: #ffffff;
  margin: 0 auto 0 0;
}

@media screen and (min-width: 1040px) {
  header#header .spNavigations .txtFlix p {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767px) {
  header#header .spNavigations .txtFlix p {
    font-size: 2.73438vw;
  }
}

header#header .spNavigations .txtFlix .snsF {
  width: 34px;
}

header#header .spNavigations .txtFlix .snsF.xIcon {
  width: 26px;
  margin-bottom: 3px;
}

@media screen and (max-width: 767px) {
  header#header .spNavigations .txtFlix .snsF {
    margin-left: 15px;
  }
}

header#header .spNavigations ul {
  padding-top: 30px;
}

header#header .spNavigations ul li {
  padding: 0 20px;
  margin-bottom: 12px;
}

header#header .spNavigations ul li.spAnker {
  display: block;
}

header#header .spNavigations ul li.pcAnker {
  display: none;
}

header#header .spNavigations ul li a,
header#header .spNavigations ul li span {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1;
  letter-spacing: 2.17px;
  text-align: left;
  color: #ffffff;
  display: block;
  padding: 0 10px;
}

@media screen and (min-width: 1040px) {
  header#header .spNavigations ul li a,
  header#header .spNavigations ul li span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  header#header .spNavigations ul li a,
  header#header .spNavigations ul li span {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  header#header .spNavigations ul li a,
  header#header .spNavigations ul li span {
    padding: 15px 0;
    background-color: #fff;
    color: #A81E2D;
    font-size: 3rem;
    font-size: 2.88462vw;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  header#header .spNavigations ul li a,
  header#header .spNavigations ul li span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  header#header .spNavigations ul li a,
  header#header .spNavigations ul li span {
    font-size: 3.90625vw;
  }
}

header#header .spNavigations ul li a.notHover,
header#header .spNavigations ul li span.notHover {
  background-color: #a7a7a7;
}

header#header .inner {
  max-width: 1040px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  /*ナビゲーション*/
  /*検索ボタン*/
  /*SPグローバルメニュー*/
}

@media screen and (max-width: 767px) {
  header#header .inner {
    height: 100%;
  }
}

header#header .inner .inside {
  width: 100%;
  padding-left: 6.25%;
  display: flex;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #b21d2e;
}

@media screen and (max-width: 767px) {
  header#header .inner .inside {
    padding: 18px;
    position: fixed;
  }
}

header#header .inner .inside .logo {
  width: 15.8974%;
  position: relative;
  z-index: 99999;
}

@media screen and (max-width: 767px) {
  header#header .inner .inside .logo {
    width: 225px;
  }
}

header#header .inner .inside .logo a {
  display: block;
}

header#header .inner nav.globalTopNav {
  display: block;
  flex: 1;
  position: relative;
  padding: 5px 0 0 6.25%;
  z-index: 1000;
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    padding: 7.16145% 0 0 0;
    transition: .8s;
    z-index: 0;
    width: 100%;
    height: 50%;
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav.open {
    transform: translateY(0);
    opacity: 1;
  }
}

header#header .inner nav.globalTopNav ul {
  display: flex;
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul {
    display: block;
    padding-bottom: 13.671875%;
  }
}

header#header .inner nav.globalTopNav ul li.spAnker {
  display: none;
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li {
    padding: 0 20px 10px;
  }
  header#header .inner nav.globalTopNav ul li.spAnker {
    display: block;
  }
  header#header .inner nav.globalTopNav ul li.pcAnker {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .inner nav.globalTopNav ul li:first-child a {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li:last-child {
    padding-bottom: 0;
  }
}

header#header .inner nav.globalTopNav ul li + li {
  border-left: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li + li {
    border: 0;
  }
}

header#header .inner nav.globalTopNav ul li a,
header#header .inner nav.globalTopNav ul li span {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1;
  letter-spacing: 2.17px;
  text-align: left;
  color: #ffffff;
  display: block;
  padding: 0 20px;
}

@media screen and (min-width: 1040px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    font-size: 2.08333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    padding: 0 1.8vw;
  }
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    padding: 8px 0;
    background-color: #fff;
    color: #A81E2D;
    font-size: 3rem;
    font-size: 2.88462vw;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  header#header .inner nav.globalTopNav ul li a,
  header#header .inner nav.globalTopNav ul li span {
    font-size: 3.90625vw;
  }
}

header#header .inner nav.globalTopNav ul li a.notHover,
header#header .inner nav.globalTopNav ul li span.notHover {
  color: #a7a7a7;
  cursor: default;
}

header#header .inner nav.globalTopNav .txtBox {
  display: none;
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav .txtBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px;
    width: 100%;
  }
}

header#header .inner nav.globalTopNav .txtBox p {
  font-size: 2.1rem;
  font-size: 2.01923vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.19px;
  text-align: left;
  color: #ffffff;
}

@media screen and (min-width: 1040px) {
  header#header .inner nav.globalTopNav .txtBox p {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767px) {
  header#header .inner nav.globalTopNav .txtBox p {
    font-size: 2.73438vw;
  }
}

header#header .inner nav.globalTopNav .txtBox a.snsF {
  display: block;
  width: 10.9890%;
}

header#header .inner #searchBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.9846%;
  margin-right: 5%;
  width: 2.26923%;
  cursor: pointer;
  z-index: 10001;
}

@media screen and (max-width: 767px) {
  header#header .inner #searchBtn {
    margin-top: 18px;
    width: 20px;
    margin-right: 14.3229%;
  }
}

header#header .inner #searchBtn button {
  cursor: pointer;
  background-color: transparent;
}

header#header .inner .menuTrigger {
  display: none;
}

@media screen and (max-width: 767px) {
  header#header .inner .menuTrigger {
    display: inline-block;
    transition: all .4s;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-right: 5.859375%;
  }
}

header#header .inner .menuTrigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  border-radius: 4px;
}

header#header .inner .menuTrigger span:nth-of-type(1) {
  top: 0;
}

header#header .inner .menuTrigger span:nth-of-type(2) {
  top: 9px;
}

header#header .inner .menuTrigger span:nth-of-type(3) {
  bottom: 0;
}

header#header .inner .menuTrigger.active span:nth-of-type(1) {
  transform: translateY(9px) rotate(-45deg);
}

header#header .inner .menuTrigger.active span:nth-of-type(2) {
  opacity: 0;
}

header#header .inner .menuTrigger.active span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
}

/*検索フォーム*/
#searchLayout {
  position: absolute;
  width: 100%;
  padding-right: 5.25%;
  top: -120px;
  left: 0;
  transform: translateY(-100%);
  z-index: 100;
  transition: top .8s;
}

@media screen and (max-width: 767px) {
  #searchLayout {
    position: fixed;
    width: 100%;
    transform: translateY(0);
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #searchLayout.addOpen {
    top: 60px !important;
  }
}

#searchLayout .searcInner {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #searchLayout .searcInner {
    background: rgba(255, 255, 255, 0.8);
    padding: 3.07692% 15px;
  }
}

#searchLayout .searcInner form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#searchLayout .searcInner .searchIcon {
  background-color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #searchLayout .searcInner .searchIcon {
    height: 40px;
  }
}

#searchLayout .searcInner .searchIcon::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(/images/common/search_icon_gray.svg) center/contain no-repeat;
}

#searchLayout .searcInner .searchTxt {
  font-family: "EB Garamond", serif;
  font-size: 1.6rem;
  line-height: 2;
  letter-spacing: 0.35px;
  outline: none;
  appearance: none;
  color: #000;
  width: 200px;
  height: 35px;
  border: 0;
}

#searchLayout .searcInner .searchTxt::placeholder {
  color: #c7c7c7;
}

#searchLayout .searcInner .searchTxt:-ms-input-placeholder {
  color: #c7c7c7;
}

#searchLayout .searcInner .searchTxt::-ms-input-placeholder {
  color: #c7c7c7;
}

@media screen and (max-width: 767px) {
  #searchLayout .searcInner .searchTxt {
    width: 73.7%;
    height: 40px;
  }
}

#searchLayout .searcInner .searchSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #555;
  width: 59px;
  height: 35px;
  border: solid 1px #fff;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #searchLayout .searcInner .searchSubmit {
    width: 26.3%;
    height: 40px;
    border: none;
  }
}

#searchLayout .searcInner .searchSubmit span {
  color: #fff;
  font-size: 1.6rem;
  line-height: 2;
  letter-spacing: 0.35px;
  text-align: center;
}

#searchLayout .searcInner .searchSubmit input {
  display: none;
}

/* ================================================================================
フッター
================================================================================ */
.supportedBy {
  position: relative;
  max-width: 100%;
  padding: 20px 0;
}

@media screen and (max-width: 767px) {
  .supportedBy {
    padding: 20px 0 10px;
  }
}

.supportedBy .supportedByLogo {
  max-width: 100%;
  width: 100%;
  background-color: #fff;
}

.supportedBy .supportedByLogo img {
  max-width: 300px;
  width: 100%;
  margin: 0 20px 0 auto;
}

@media screen and (min-width: 1366px) {
  .supportedBy .supportedByLogo img {
    margin: 0 100px 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .supportedBy .supportedByLogo img {
    max-width: 215px;
    margin: 0 10px 0 auto;
  }
}

footer#footer {
  position: relative;
  background-color: #B21D2E;
  color: #fff;
}

footer#footer .inner {
  max-width: 880px;
  padding: 0 20px;
  margin: 0 auto;
}

footer#footer .inner .flixBox {
  padding: 60px 0 55px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  border-bottom: solid 1px #fff;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox {
    align-items: center;
    justify-content: center;
    padding: 11.27% 15px 27.35%;
  }
}

footer#footer .inner .flixBox .logo {
  width: 18.2352%;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox .logo {
    max-width: 40%;
    width: 100%;
    padding: 0;
  }
  footer#footer .inner .flixBox .logo img {
    width: 100%;
    margin: 0 auto;
  }
}

footer#footer .inner .flixBox nav.footerNav {
  padding: 0 0 0 8%;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav {
    padding: 0 0 0 35px;
  }
}

footer#footer .inner .flixBox nav.footerNav ul {
  display: flex;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul {
    display: block;
  }
}

footer#footer .inner .flixBox nav.footerNav ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}

footer#footer .inner .flixBox nav.footerNav ul li.spAnker {
  display: none;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li {
    padding-bottom: 20px;
    justify-content: flex-start;
  }
  footer#footer .inner .flixBox nav.footerNav ul li.spAnker {
    display: block;
  }
  footer#footer .inner .flixBox nav.footerNav ul li.pcAnker {
    display: none;
  }
}

footer#footer .inner .flixBox nav.footerNav ul li:last-child a {
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:last-child {
    padding-bottom: 0;
  }
}

footer#footer .inner .flixBox nav.footerNav ul li + li {
  border-left: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li + li {
    border: 0;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(1) {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(2) {
    order: 3;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(3) {
    order: 5;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(4) {
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(5) {
    order: 4;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li:nth-child(6) {
    order: 6;
  }
}

footer#footer .inner .flixBox nav.footerNav ul li a,
footer#footer .inner .flixBox nav.footerNav ul li span {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1;
  letter-spacing: .8px;
  text-align: left;
  color: #ffffff;
  display: block;
  padding: 0 12px;
}

@media screen and (min-width: 1040px) {
  footer#footer .inner .flixBox nav.footerNav ul li a,
  footer#footer .inner .flixBox nav.footerNav ul li span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li a,
  footer#footer .inner .flixBox nav.footerNav ul li span {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li a,
  footer#footer .inner .flixBox nav.footerNav ul li span {
    padding: 0;
    font-size: 3rem;
    font-size: 2.88462vw;
    font-weight: bold;
    text-align: left;
    letter-spacing: 1.35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  footer#footer .inner .flixBox nav.footerNav ul li a,
  footer#footer .inner .flixBox nav.footerNav ul li span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  footer#footer .inner .flixBox nav.footerNav ul li a,
  footer#footer .inner .flixBox nav.footerNav ul li span {
    font-size: 3.90625vw;
  }
}

footer#footer .inner .flixBox nav.footerNav ul li a.notHover,
footer#footer .inner .flixBox nav.footerNav ul li span.notHover {
  cursor: default;
  color: #a7a7a7;
}

footer#footer .inner .flixBox .externalLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 45px;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox .externalLink {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 6.6%;
  }
}

footer#footer .inner .flixBox .snsTicon {
  flex-shrink: 0;
  width: 8.6%;
}

footer#footer .inner .flixBox .snsTicon a {
  display: block;
}

footer#footer .inner .flixBox .snsTicon.xIcon {
  width: 7.42%;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox .snsTicon {
    position: static;
    width: 10.45%;
  }
  footer#footer .inner .flixBox .snsTicon img {
    width: 100%;
  }
}

footer#footer .inner .flixBox .snsFicon {
  flex-shrink: 0;
  width: 8.6%;
  margin-left: 15px;
}

footer#footer .inner .flixBox .snsFicon a {
  display: block;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox .snsFicon {
    position: static;
    width: 10.45%;
  }
}

footer#footer .inner .flixBox .snsIicon {
  position: absolute;
  top: 0;
  right: 10px;
  width: 4.7058%;
  margin-top: 2.34117%;
}

footer#footer .inner .flixBox .snsIicon a {
  display: block;
}

@media screen and (max-width: 767px) {
  footer#footer .inner .flixBox .snsIicon {
    position: static;
    width: 36px;
    margin-right: 20px;
  }
}

footer#footer .tkfdLogo {
  flex-shrink: 0;
  max-width: 52.723%;
  width: 100%;
  margin-left: 30px;
}

footer#footer .tkfdLogo img {
  max-width: 184px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  footer#footer .tkfdLogo {
    max-width: 60%;
    margin-left: 25px;
  }
  footer#footer .tkfdLogo img {
    max-width: 100%;
    width: 100%;
  }
}

footer#footer small {
  display: block;
  text-align: right;
  font-size: 1rem;
  font-size: 0.96154vw;
  color: #fff;
}

@media screen and (min-width: 1040px) {
  footer#footer small {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer small {
    font-size: 1.30208vw;
  }
}

@media screen and (max-width: 767px) {
  footer#footer small {
    padding: 6.7% 20px 6.7%;
    text-align: center;
    font-size: 2rem;
    font-size: 1.92308vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  footer#footer small {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  footer#footer small {
    font-size: 2.60417vw;
  }
}

footer#footer .footerBottom {
  display: flex;
  justify-content: space-between;
  max-width: 880px;
  padding: 20px 20px 30px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom {
    display: block;
    padding: 5.3% 20px 0;
  }
}

footer#footer .footerBottom .footerOtherNav {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav {
    justify-content: center;
  }
}

footer#footer .footerBottom .footerOtherNav li {
  font-size: 1.2rem;
  font-size: 1.15385vw;
  line-height: 1;
  letter-spacing: 1.2px;
  border-right: 1px solid #fff;
  padding-right: 20px;
}

@media screen and (min-width: 1040px) {
  footer#footer .footerBottom .footerOtherNav li {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav li {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav li {
    display: block;
    font-size: 2.2rem;
    font-size: 2.11538vw;
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  footer#footer .footerBottom .footerOtherNav li {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav li {
    font-size: 2.86458vw;
  }
}

footer#footer .footerBottom .footerOtherNav li:last-of-type {
  border-right: none;
  padding-right: 0;
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav li:last-of-type {
    padding-right: 0;
    padding-left: 10px;
  }
}

footer#footer .footerBottom .footerOtherNav a {
  display: block;
  font-size: 1.2rem;
  font-size: 1.15385vw;
  line-height: 1;
  letter-spacing: 1.2px;
  color: #fff;
}

@media screen and (min-width: 1040px) {
  footer#footer .footerBottom .footerOtherNav a {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav a {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav a {
    display: block;
    font-size: 2.2rem;
    font-size: 2.11538vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  footer#footer .footerBottom .footerOtherNav a {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  footer#footer .footerBottom .footerOtherNav a {
    font-size: 2.86458vw;
  }
}

/* 見出し装飾
------------------------------------------------------------- */
/* アニメーション関連
------------------------------------------------------------- */
/* ボタン装飾
------------------------------------------------------------- */
html:not([data-whatinput="mouse"]):not([data-whatinput="touch"]) input:focus,
html:not([data-whatinput="mouse"]):not([data-whatinput="touch"]) button:focus {
  outline: none;
}

.moreBtnCom {
  display: block;
  text-align: center;
}

.moreBtnCom a {
  max-width: 110px;
  margin: 0 auto;
  background-color: #b21d2e;
  color: #fff;
  font-size: 1.9rem;
  font-size: 1.82692vw;
  line-height: 1.68;
  letter-spacing: 1.14px;
  text-align: center;
  color: #ffffff;
  padding: 5px 25px;
  display: block;
}

@media screen and (min-width: 1040px) {
  .moreBtnCom a {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  .moreBtnCom a {
    font-size: 2.47396vw;
  }
}

@media screen and (max-width: 767px) {
  .moreBtnCom a {
    max-width: none;
    width: 100%;
    font-size: 4rem;
    font-size: 3.84615vw;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .moreBtnCom a {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .moreBtnCom a {
    font-size: 5.20833vw;
  }
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

.keyVisual {
  overflow: hidden;
}

.keyVisualBg {
  width: 100%;
}

.keyVisualTit {
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.58);
  font-weight: bold;
  font-size: 4.8rem;
  font-size: 4.61538vw;
  line-height: 1.73;
  letter-spacing: 2.88px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 880px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1040px) {
  .keyVisualTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) {
  .keyVisualTit {
    font-size: 6.25vw;
  }
}

@media screen and (max-width: 767px) {
  .keyVisualTit {
    font-size: 6rem;
    font-size: 5.76923vw;
    padding: 0;
    left: 13%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .keyVisualTit {
    font-size: 6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .keyVisualTit {
    font-size: 7.8125vw;
  }
}

.pageBottomLayout {
  max-width: 880px;
  margin: 0 auto;
  padding: 30px 20px 10px;
  /* アンカーリンクデザイン--------------------- */
  /* パンくずリスト--------------------------- */
}

@media screen and (max-width: 767px) {
  .pageBottomLayout {
    padding: 40px 20px 0;
  }
}

.pageBottomLayout .pageTop {
  width: 55px;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
}

.pageBottomLayout .breadCrumb ol {
  position: relative;
  width: 100%;
  padding-left: 1em;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
}

.pageBottomLayout .breadCrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .pageBottomLayout .breadCrumb ol {
    max-width: initial;
    padding: 0 0 0 2em;
    margin-top: 26px;
    margin-bottom: 13px;
  }
}

.pageBottomLayout .breadCrumb ol::before {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin: auto;
  content: "";
  background: url("/images/common/icon_home.svg") center/contain no-repeat;
}

@media screen and (max-width: 767px) {
  .pageBottomLayout .breadCrumb ol::before {
    top: 0;
    width: 12px;
    height: 12px;
  }
}

.pageBottomLayout .breadCrumb ol > li {
  display: inline;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: left;
  color: #747474;
}

@media screen and (max-width: 767px) {
  .pageBottomLayout .breadCrumb ol > li {
    font-size: 1.1rem;
  }
}

.pageBottomLayout .breadCrumb ol > li a {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 1.6px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .pageBottomLayout .breadCrumb ol > li a {
    font-size: 1.1rem;
  }
}

.pageBottomLayout .breadCrumb ol > li + li::before {
  padding: 0 7px 0 5px;
  color: #b21d2e;
  content: ">";
}

@media screen and (max-width: 767px) {
  .pageBottomLayout .breadCrumb ol > li + li::before {
    padding: 0;
  }
}

/************************************
トップページ
***********************************/
/* ================================================================================
トップページ
================================================================================ */
#indexTop {
  /*キービジュアルスライドショー*/
  /*MISSION*/
  /*ニュース&TOPICS*/
  /*ニュース&TOPICS 2カラム*/
  /*BOOK DONATION*/
  /*SYNOPSIS*/
}

#indexTop .topSlideContents {
  display: block;
}

#indexTop .topSlideContents .inner {
  width: 100%;
}

#indexTop .topSlideContents .inner .swiper-container {
  /*ページネーション*/
}

#indexTop .topSlideContents .inner .swiper-container .swiper-slide img {
  width: 100%;
}

#indexTop .topSlideContents .inner .swiper-container .swiper-pagination {
  bottom: 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .topSlideContents .inner .swiper-container .swiper-pagination {
    bottom: 10px;
  }
}

#indexTop .topSlideContents .inner .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #indexTop .topSlideContents .inner .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
  }
}

#indexTop .topSlideContents .inner .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #b3b3b3;
}

#indexTop .missonLayout {
  background-color: #f7f8f8;
}

#indexTop .missonLayout .inner {
  max-width: 870px;
  padding: 45px 20px 35px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner {
    padding: 13.02083% 20px 6.5104%;
  }
}

#indexTop .missonLayout .inner h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  #indexTop .missonLayout .inner h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .missonLayout .inner h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .missonLayout .inner h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .missonLayout .inner h2 span {
  border-bottom: 4px solid #B21D2E;
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner h2 span {
    border-bottom: 2px solid #B21D2E;
  }
}

#indexTop .missonLayout .inner p {
  font-size: 3rem;
  font-size: 2.88462vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.14px;
  text-align: center;
  color: #000000;
}

@media screen and (min-width: 1040px) {
  #indexTop .missonLayout .inner p {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner p {
    font-size: 3.90625vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner p {
    font-size: 4rem;
    font-size: 3.84615vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .missonLayout .inner p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .missonLayout .inner p {
    font-size: 5.20833vw;
  }
}

#indexTop .missonLayout .inner .moreBtnCom {
  padding: 35px 0 0 0;
}

@media screen and (max-width: 767px) {
  #indexTop .missonLayout .inner .moreBtnCom {
    padding: 20px 0 0 0;
  }
}

#indexTop .newsAndTopocs {
  width: 100%;
}

#indexTop .newsAndTopocs .inner {
  padding: 55px 20px 35px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner {
    padding: 12.3697% 20px 13.0208%;
  }
}

#indexTop .newsAndTopocs .inner h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  #indexTop .newsAndTopocs .inner h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .newsAndTopocs .inner h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .newsAndTopocs .inner h2 span {
  border-bottom: 4px solid #B21D2E;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner h2 span {
    border-bottom: 2px solid #B21D2E;
  }
}

#indexTop .newsAndTopocs .inner .wrap {
  width: 100%;
  position: relative;
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner .wrap {
    padding: 0;
  }
}

#indexTop .newsAndTopocs .inner #newsSwiper {
  width: 100%;
  max-width: 845px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide:nth-child(n + 4) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide {
    margin-bottom: 15px;
  }
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a {
  display: block;
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews {
  display: block;
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews .thumbnail {
  height: 0;
  padding-top: 75%;
  position: relative;
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.07;
  color: #000000;
  padding: 5px 0 10px;
}

@media screen and (min-width: 1040px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.txt {
    font-size: 4.16667vw;
  }
}

#indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.more {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.07;
  color: #b21d2e;
}

@media screen and (min-width: 1040px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.more {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.more {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner #newsSwiper .swiper-wrapper .swiper-slide a .postNews p.more {
    display: none;
  }
}

#indexTop .newsAndTopocs .inner .newslNext, #indexTop .newsAndTopocs .inner .newslPrev {
  top: 30%;
  width: 3.125%;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner .newslNext, #indexTop .newsAndTopocs .inner .newslPrev {
    display: none;
  }
}

#indexTop .newsAndTopocs .inner .newslNext {
  background-image: url("/images/common/slide_arrow_right.svg");
  right: 0;
}

#indexTop .newsAndTopocs .inner .newslPrev {
  background-image: url("/images/common/slide_arrow_left.svg");
  left: 0;
}

#indexTop .newsAndTopocs .inner .moreBtnCom {
  padding: 35px 0 0 0;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopocs .inner .moreBtnCom {
    padding: 0;
  }
}

#indexTop .newsAndTopicsTwoColumn {
  width: 100%;
}

#indexTop .newsAndTopicsTwoColumn .inner {
  max-width: 840px;
  padding: 60px 20px 35px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner {
    padding: 12.3697% 20px 8.0208%;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner h2 span {
  border-bottom: 4px solid #B21D2E;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner h2 span {
    border-bottom: 2px solid #B21D2E;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .twoColumnLayout {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .twoColumnLayout {
    display: block;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox {
  max-width: 380px;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox {
    max-width: 100%;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox:not(:last-child) {
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox:not(:last-child) {
    margin-right: 0;
    padding-bottom: 8.5104%;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsImg {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsImg {
    margin-bottom: 4.5104%;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsImg img {
  width: 100%;
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsTit {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsTit {
    margin-bottom: 4.5104%;
  }
}

#indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsTxt {
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .newsAndTopicsTwoColumn .inner .newsAndTopicsBox .newsAndTopicsTxt {
    margin-bottom: 4.5104%;
  }
}

#indexTop .bookLayout .inner {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner {
    display: block;
  }
}

#indexTop .bookLayout .inner .bgBack {
  width: 32.2115%;
  background-image: url("/images/index/book_photo.jpg");
  padding-top: 36.61923%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
}

@media screen and (min-width: 1366px) {
  #indexTop .bookLayout .inner .bgBack {
    padding-top: 380px;
    width: 42.2115%;
    background-image: url("/images/index/book_photo_long.jpg");
  }
}

@media screen and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bgBack {
    padding-top: 380px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bgBack {
    width: 100%;
    background-image: url("/images/index/book_photo_sp.jpg");
    padding-top: 58.59375%;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout {
  background-color: #b21d2e;
  flex: 1;
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook {
  max-width: 565px;
  width: 100%;
  padding: 40px 0 0 40px;
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook {
    max-width: none;
    padding: 6.510416% 20px 9.11458%;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  line-height: 1;
  letter-spacing: 1.68px;
  color: #ffffff;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    text-align: center;
    margin-bottom: 6.8681318%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 span {
  display: inline-block;
  border-bottom: 4px solid #fff;
  padding-bottom: 5px;
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook h2 span {
    border-bottom: 2px solid #fff;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
  font-size: 2.3rem;
  font-size: 2.21154vw;
  line-height: 1.3;
  letter-spacing: 0.58px;
  text-align: left;
  color: #ffffff;
}

@media screen and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
    font-size: 2.99479vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook p.txt {
    font-size: 4.16667vw;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom {
  padding-top: 30px;
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom {
    padding-top: 4.120879%;
  }
}

#indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
  background-color: #fff;
  color: #b21d2e;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  max-width: 220px;
  margin: 0;
  font-weight: bold;
}

@media screen and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
    max-width: none;
    font-size: 4rem;
    font-size: 3.84615vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bookLayout .inner .bookTxtLayout .ineerBook .moreBtnCom a {
    font-size: 5.20833vw;
  }
}

#indexTop .stnopisLayout {
  padding: 50px 0;
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout {
    padding: 13.0208% 20px;
  }
}

#indexTop .stnopisLayout .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner {
    padding: 0;
  }
}

#indexTop .stnopisLayout .inner .txtBox {
  border-bottom: 1px solid #231815;
}

#indexTop .stnopisLayout .inner .txtBox h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  #indexTop .stnopisLayout .inner .txtBox h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .stnopisLayout .inner .txtBox h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .stnopisLayout .inner .txtBox h2 span {
  display: inline-block;
  border-bottom: 4px solid #B21D2E;
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox h2 span {
    border-bottom: 2px solid #B21D2E;
  }
}

#indexTop .stnopisLayout .inner .txtBox p.txt {
  max-width: 710px;
  margin: 0 auto;
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #000000;
  padding-bottom: 15px;
}

@media screen and (min-width: 1040px) {
  #indexTop .stnopisLayout .inner .txtBox p.txt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox p.txt {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox p.txt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding-bottom: 4.8076%;
    max-width: none;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .stnopisLayout .inner .txtBox p.txt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox p.txt {
    font-size: 4.16667vw;
  }
}

#indexTop .stnopisLayout .inner .txtBox .moreBtnCom {
  padding: 0 0 32px 0;
}

@media screen and (max-width: 767px) {
  #indexTop .stnopisLayout .inner .txtBox .moreBtnCom {
    padding-bottom: 6.86813%;
  }
}

#indexTop .whoWeAreLayout .inner {
  max-width: 840px;
  padding: 50px 20px 50px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner {
    padding-bottom: 10.4166%;
  }
}

#indexTop .whoWeAreLayout .inner h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  #indexTop .whoWeAreLayout .inner h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .whoWeAreLayout .inner h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner h2 {
    font-size: 5.98958vw;
  }
}

#indexTop .whoWeAreLayout .inner h2 span {
  border-bottom: 4px solid #B21D2E;
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner h2 span {
    border-bottom: 2px solid #B21D2E;
  }
}

#indexTop .whoWeAreLayout .inner .logoBox {
  padding-bottom: 65px;
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner .logoBox {
    padding: 17.1703% 0 13.7362% 0;
  }
}

#indexTop .whoWeAreLayout .inner .logoBox img {
  margin: 0 auto;
  max-width: 225px;
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner .logoBox img {
    width: 65.3846%;
  }
}

#indexTop .whoWeAreLayout .inner p.txt {
  font-size: 2.1rem;
  font-size: 2.01923vw;
  line-height: 1.3;
  font-weight: bold;
  color: #231815;
  text-align: center;
}

@media screen and (min-width: 1040px) {
  #indexTop .whoWeAreLayout .inner p.txt {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner p.txt {
    font-size: 2.73438vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner p.txt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    text-align: left;
    line-height: 1.5;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #indexTop .whoWeAreLayout .inner p.txt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner p.txt {
    font-size: 4.16667vw;
  }
}

#indexTop .whoWeAreLayout .inner p.txt span {
  color: #b21d2e;
  display: inline-block;
}

#indexTop .whoWeAreLayout .inner .moreBtnCom {
  padding-top: 45px;
}

#indexTop .whoWeAreLayout .inner .moreBtnCom a {
  max-width: 200px;
}

@media screen and (max-width: 767px) {
  #indexTop .whoWeAreLayout .inner .moreBtnCom a {
    max-width: none;
  }
}

.overview .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.overview h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  .overview h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .overview h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .overview h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overview h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overview h2 {
    font-size: 5.98958vw;
  }
}

.overview h2 span {
  border-bottom: 4px solid #b21d2e;
}

@media screen and (max-width: 767px) {
  .overview h2 span {
    border-bottom: 2px solid #b21d2e;
  }
}

.overviewMission {
  padding: 60px 20px;
}

@media screen and (max-width: 767px) {
  .overviewMission {
    padding: 40px 20px;
  }
}

.overviewMissionTxt {
  font-size: 3.8rem;
  font-size: 3.65385vw;
  line-height: 1.16;
  letter-spacing: 1.14px;
  text-align: center;
  font-weight: bold;
  max-width: 840px;
  margin: auto;
}

@media screen and (min-width: 1040px) {
  .overviewMissionTxt {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewMissionTxt {
    font-size: 4.94792vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewMissionTxt {
    font-size: 4rem;
    font-size: 3.84615vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewMissionTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewMissionTxt {
    font-size: 5.20833vw;
  }
}

.overviewProject {
  background-color: #f7f8f8;
  padding: 50px 0 70px;
}

@media screen and (max-width: 767px) {
  .overviewProject {
    padding: 30px 0 40px;
  }
}

.overviewProjectIntro {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 60px;
}

@media screen and (min-width: 1040px) {
  .overviewProjectIntro {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectIntro {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectIntro {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProjectIntro {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProjectIntro {
    font-size: 4.6875vw;
  }
}

@media screen and (min-width: 768px) {
  .overviewProjectLayout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.overviewProject .overviewProjectSubTit {
  flex-basis: 100%;
  font-size: 2.4rem;
  font-size: 2.30769vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: center;
  color: #a81e2d;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .overviewProject .overviewProjectSubTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProject .overviewProjectSubTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProject .overviewProjectSubTit {
    font-size: 4.8rem;
    font-size: 4.61538vw;
    margin: 60px auto 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProject .overviewProjectSubTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProject .overviewProjectSubTit {
    font-size: 6.25vw;
  }
}

.overviewProjectBox {
  background-color: #fff;
  flex-basis: 31%;
}

@media screen and (max-width: 767px) {
  .overviewProjectBox {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .overviewProjectBox:first-of-type, .overviewProjectBox:nth-of-type(2) {
    flex-basis: 100%;
    display: flex;
  }
  .overviewProjectBox:first-of-type .overviewProjectBoxImg, .overviewProjectBox:nth-of-type(2) .overviewProjectBoxImg {
    flex-basis: 41%;
    max-width: 50%;
  }
  .overviewProjectBox:first-of-type .overviewProjectBoxItem, .overviewProjectBox:nth-of-type(2) .overviewProjectBoxItem {
    flex-basis: 60%;
    padding: 30px 50px;
  }
  .overviewProjectBox:first-of-type .overviewProjectBoxTit, .overviewProjectBox:nth-of-type(2) .overviewProjectBoxTit {
    margin-left: 0;
  }
  .overviewProjectBox:first-of-type {
    margin-bottom: 40px;
  }
  .overviewProjectBox:nth-of-type(3) .overviewProjectBoxTxt {
    word-break: break-all;
  }
}

.overviewProjectBoxImg {
  width: 100%;
}

.overviewProjectBoxImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.overviewProjectBoxItem {
  padding: 20px 20px 40px;
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxItem {
    padding: 20px 15px 15px 30px;
  }
}

.overviewProjectBoxTit {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: #b21d2e;
  margin-left: 15px;
  margin-bottom: 20px;
  position: relative;
  text-indent: -25px;
}

@media screen and (min-width: 1040px) {
  .overviewProjectBoxTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxTit {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxTit {
    font-size: 4rem;
    font-size: 3.84615vw;
    text-indent: -18px;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProjectBoxTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProjectBoxTit {
    font-size: 5.20833vw;
  }
}

.overviewProjectBoxTit::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 1px;
  background-color: #b21d2e;
  margin-right: 10px;
  margin-bottom: 6px;
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxTit::before {
    width: 11px;
    margin-right: 7px;
  }
}

.overviewProjectBoxTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  .overviewProjectBoxTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxTxt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProjectBoxTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProjectBoxTxt {
    font-size: 4.16667vw;
  }
}

.overviewProjectBoxBtn {
  background-color: #b21d2e;
  font-size: 1.2rem;
  font-size: 1.15385vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: center;
  color: #fff;
  width: 45%;
  display: block;
  padding: 10px 0;
  margin-left: auto;
}

@media screen and (min-width: 1040px) {
  .overviewProjectBoxBtn {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxBtn {
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxBtn {
    font-size: 3rem;
    font-size: 2.88462vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProjectBoxBtn {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProjectBoxBtn {
    font-size: 3.90625vw;
  }
}

.overviewProjectBoxLink {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #555;
  border-bottom: solid 1px #b21d2e;
  display: inline-block;
  position: relative;
  margin-left: 15px;
  margin-bottom: 10px;
}

@media screen and (min-width: 1040px) {
  .overviewProjectBoxLink {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxLink {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewProjectBoxLink {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewProjectBoxLink {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewProjectBoxLink {
    font-size: 4.16667vw;
  }
}

.overviewProjectBoxLink::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: solid #b21d2e;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -15px;
  margin: auto;
}

.overviewPdfLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0;
}

@media screen and (max-width: 767px) {
  .overviewPdfLayout {
    display: block;
    padding: 0 30px;
    margin: 40px 0 0;
  }
}

.overviewPdfLayoutItem {
  max-width: 50%;
  width: 100%;
  padding: 0 0 0 40px;
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutItem {
    max-width: 100%;
    padding: 0;
  }
}

.overviewPdfLayoutImg {
  max-width: 50%;
  width: 100%;
}

.overviewPdfLayoutImg a {
  display: block;
  max-width: 57.143%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutImg {
    max-width: 87.273%;
    margin: 0 auto 25px;
  }
  .overviewPdfLayoutImg a {
    max-width: 100%;
  }
}

.overviewPdfLayoutTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.75;
  letter-spacing: 0.36px;
  color: #b21d2e;
  margin: 0 0 40px;
}

@media screen and (min-width: 1040px) {
  .overviewPdfLayoutTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutTit {
    font-size: 4rem;
    font-size: 3.84615vw;
    line-height: 1.23;
    letter-spacing: .56px;
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewPdfLayoutTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewPdfLayoutTit {
    font-size: 5.20833vw;
  }
}

.overviewPdfLayoutTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #000;
  margin: 0 0 40px;
}

@media screen and (min-width: 1040px) {
  .overviewPdfLayoutTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutTxt {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    line-height: 1.5;
    letter-spacing: .45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewPdfLayoutTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewPdfLayoutTxt {
    font-size: 4.6875vw;
  }
}

.overviewPdfLayoutBtn {
  display: inline-block;
  max-width: 180px;
  width: 100%;
  padding: 14px 0;
  background-color: #B21D2E;
  font-size: 1.3rem;
  font-size: 1.25vw;
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.65px;
  line-height: 1;
}

@media screen and (min-width: 1040px) {
  .overviewPdfLayoutBtn {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutBtn {
    font-size: 1.69271vw;
  }
}

@media screen and (max-width: 767px) {
  .overviewPdfLayoutBtn {
    max-width: 100%;
    font-size: 3rem;
    font-size: 2.88462vw;
    letter-spacing: .525px;
    padding: 12px 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .overviewPdfLayoutBtn {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .overviewPdfLayoutBtn {
    font-size: 3.90625vw;
  }
}

#overview .whoweare {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  #overview .whoweare {
    padding-top: 40px;
  }
}

#overview .whoweare .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

#overview .whoweare h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  #overview .whoweare h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweare h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweare h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweare h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweare h2 {
    font-size: 5.98958vw;
  }
}

#overview .whoweare h2 span {
  border-bottom: 4px solid #b21d2e;
}

@media screen and (max-width: 767px) {
  #overview .whoweare h2 span {
    border-bottom: 2px solid #b21d2e;
  }
}

#overview .whoweareIntro {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 60px;
}

@media screen and (min-width: 1040px) {
  #overview .whoweareIntro {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareIntro {
    font-size: 3.125vw;
  }
}

#overview .whoweareIntro a {
  color: #b21d2e;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  #overview .whoweareIntro {
    font-size: 4rem;
    font-size: 3.84615vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweareIntro {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweareIntro {
    font-size: 5.20833vw;
  }
}

#overview .whoweareLogo {
  width: 42.26%;
  margin: 0 auto 64px;
}

#overview .whoweareLogo img {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #overview .whoweareLogo {
    width: 60%;
    margin-bottom: 40px;
  }
}

#overview .whoweareTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: left;
  color: #a81e2d;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  #overview .whoweareTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareTit {
    font-size: 4.8rem;
    font-size: 4.61538vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweareTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweareTit {
    font-size: 6.25vw;
  }
}

#overview .whoweareTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-bottom: 64px;
}

@media screen and (min-width: 1040px) {
  #overview .whoweareTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareTxt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweareTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweareTxt {
    font-size: 4.16667vw;
  }
}

#overview .whoweareBtn {
  font-size: 1.9rem;
  font-size: 1.82692vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 0 auto 82px;
  cursor: pointer;
}

@media screen and (min-width: 1040px) {
  #overview .whoweareBtn {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareBtn {
    font-size: 2.47396vw;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareBtn {
    font-size: 3.8rem;
    font-size: 3.65385vw;
    width: 70.423%;
    margin: 0 auto 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweareBtn {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweareBtn {
    font-size: 4.94792vw;
  }
}

#overview .whoweare .flex {
  display: flex;
  justify-content: center;
  margin: 0 auto 50px;
}

@media screen and (max-width: 767px) {
  #overview .whoweare .flex {
    display: block;
    margin-bottom: 20px;
  }
}

#overview .whoweareLogoBtn {
  font-size: 2rem;
  font-size: 1.92308vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 316px;
  margin: 0 15px;
  cursor: pointer;
}

@media screen and (min-width: 1040px) {
  #overview .whoweareLogoBtn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareLogoBtn {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  #overview .whoweareLogoBtn {
    font-size: 3.8rem;
    font-size: 3.65385vw;
    max-width: 100%;
    width: 80%;
    margin: 20px auto 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #overview .whoweareLogoBtn {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #overview .whoweareLogoBtn {
    font-size: 4.94792vw;
  }
}

.news {
  padding-top: 60px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .news {
    padding-top: 40px;
    margin-bottom: 20px;
  }
}

.news .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.news h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  text-transform: uppercase;
  margin-bottom: 60px;
}

@media screen and (min-width: 1040px) {
  .news h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .news h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .news h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .news h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .news h2 {
    font-size: 5.98958vw;
  }
}

.news h2 span {
  border-bottom: 4px solid #b21d2e;
}

@media screen and (max-width: 767px) {
  .news h2 span {
    border-bottom: 2px solid #b21d2e;
  }
}

.newsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .newsList {
    margin-bottom: 30px;
  }
}

.newsList::after {
  content: '';
  display: block;
  flex-basis: calc((100% - 48px)/3);
}

@media screen and (max-width: 767px) {
  .newsList::after {
    flex-basis: 48%;
  }
}

.newsListItem {
  flex-basis: calc((100% - 48px)/3);
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .newsListItem {
    flex-basis: 48%;
    margin-bottom: 40px;
  }
}

.newsListItemLink {
  display: block;
  cursor: pointer;
}

.newsListItemImg {
  margin-bottom: 10px;
  height: 0;
  padding-top: 75%;
  position: relative;
}

.newsListItemImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
}

.newsListItemDate {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.3rem;
  font-size: 1.25vw;
  letter-spacing: 0.33px;
  color: #555;
  margin-bottom: 6px;
  position: relative;
  padding-left: 10px;
}

@media screen and (min-width: 1040px) {
  .newsListItemDate {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemDate {
    font-size: 1.69271vw;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemDate {
    font-size: 2.6rem;
    font-size: 2.5vw;
    letter-spacing: 0.65px;
    color: #666;
    padding-left: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsListItemDate {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsListItemDate {
    font-size: 3.38542vw;
  }
}

.newsListItemDate::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 1px;
  background-color: #b21d2e;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .newsListItemDate::before {
    width: 10px;
  }
}

.newsListItemTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  letter-spacing: 0.4px;
  color: #333;
  margin-bottom: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 1040px) {
  .newsListItemTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemTxt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    color: #000;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsListItemTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsListItemTxt {
    font-size: 4.16667vw;
  }
}

.newsListItemMore {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.07;
  color: #b21d2e;
}

@media screen and (min-width: 1040px) {
  .newsListItemMore {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemMore {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .newsListItemMore {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsListItemMore {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsListItemMore {
    font-size: 4.16667vw;
  }
}

.newsPager {
  max-width: 140px;
  margin: auto;
}

.newsPager ol {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.newsPager ol li {
  flex-basis: auto;
  font-size: 2.4rem;
  font-size: 2.30769vw;
}

@media screen and (min-width: 1040px) {
  .newsPager ol li {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .newsPager ol li {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .newsPager ol li {
    font-size: 5.2rem;
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsPager ol li {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsPager ol li {
    font-size: 6.77083vw;
  }
}

.newsPager ol li a {
  color: #c7c7c7;
}

.newsPager ol li.current {
  text-decoration: underline;
  color: #a81e2d;
}

.newsPager ol li.prev, .newsPager ol li.next {
  position: absolute;
  top: 0;
}

.newsPager ol li.prev::after, .newsPager ol li.next::after {
  content: '';
  width: 10px;
  height: 10px;
  border: solid 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
  cursor: pointer;
}

.newsPager ol li.prev a, .newsPager ol li.next a {
  color: #fff;
}

.newsPager ol li.prev.hide, .newsPager ol li.next.hide {
  opacity: 0;
  visibility: hidden;
}

.newsPager ol li.prev {
  right: 100%;
}

.newsPager ol li.prev::after {
  right: 10px;
  border-color: transparent transparent #a81e2d #a81e2d;
}

.newsPager ol li.next {
  left: 100%;
}

.newsPager ol li.next::after {
  left: 10px;
  border-color: #a81e2d #a81e2d transparent transparent;
}

.newsSingle {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .newsSingle {
    margin-bottom: 30px;
  }
}

.newsSingleTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #333;
  margin-bottom: 10px;
}

@media screen and (min-width: 1040px) {
  .newsSingleTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    line-height: 1.5;
    letter-spacing: 0.9px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsSingleTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsSingleTit {
    font-size: 4.6875vw;
  }
}

.newsSingleDate {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  letter-spacing: 0.35px;
  color: #555;
  margin-bottom: 40px;
  padding-left: 10px;
  position: relative;
}

@media screen and (min-width: 1040px) {
  .newsSingleDate {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleDate {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleDate {
    font-size: 2.8rem;
    font-size: 2.69231vw;
    letter-spacing: 0.7px;
    color: #666;
    margin-bottom: 30px;
    padding-left: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsSingleDate {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsSingleDate {
    font-size: 3.64583vw;
  }
}

.newsSingleDate::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 1px;
  background-color: #b21d2e;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .newsSingleDate::before {
    width: 10px;
  }
}

.newsSingleContent {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .newsSingleContent {
    margin-bottom: 50px;
  }
}

.newsSingleContent img {
  width: 100%;
  max-width: 480px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 767px) {
  .newsSingleContent img {
    margin-bottom: 25px;
  }
}

.newsSingleContent p {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  letter-spacing: 0.4px;
  color: #000;
}

@media screen and (min-width: 1040px) {
  .newsSingleContent p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleContent p {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsSingleContent p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    letter-spacing: 0.9px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .newsSingleContent p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsSingleContent p {
    font-size: 4.6875vw;
  }
}

.newsSingleContent a {
  color: #000;
  text-decoration: underline;
}

.newsSingleContent .wp-video {
  margin: 0 auto;
  max-width: 100%;
}

.newsSingleContent video {
  width: 100%;
  height: auto;
}

.newsSingleContent iframe {
  display: block;
  max-width: 640px;
  width: 100%;
  height: calc(640px * 0.5625);
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .newsSingleContent iframe {
    max-width: 84vw;
    height: calc(84vw * 0.5625);
  }
}

.newsSingleBtn {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 60px auto 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .newsSingleBtn {
    font-size: 2.2rem;
    width: 100%;
    margin: 0 auto;
    line-height: 1.68;
    letter-spacing: 1.14px;
    margin: 60px auto 20px;
    padding: 5px 25px;
  }
}

.donation {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .donation {
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.donation .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.donation h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  .donation h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .donation h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .donation h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donation h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donation h2 {
    font-size: 5.98958vw;
  }
}

.donation h2 span {
  border-bottom: 4px solid #b21d2e;
}

@media screen and (max-width: 767px) {
  .donation h2 span {
    border-bottom: 2px solid #b21d2e;
  }
}

.donationProcess {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .donationProcess {
    margin-bottom: 70px;
  }
}

.donationProcessBox {
  padding-bottom: 25px;
  border-bottom: solid 1px #b21d2e;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

@media screen and (min-width: 768px) {
  .donationProcessBox {
    padding-bottom: 40px;
    margin-top: 60px;
  }
}

.donationProcessBox::before {
  font-size: 6.2rem;
  font-size: 5.96154vw;
  font-weight: bold;
  line-height: 0.3;
  color: #b21d2e;
  background-color: rgba(178, 29, 46, 0.13);
  border-radius: 50%;
  width: 4.615385vw;
  height: 4.615385vw;
  max-width: 48px;
  max-height: 48px;
  display: block;
  margin-top: 25px;
}

@media screen and (min-width: 1040px) {
  .donationProcessBox::before {
    font-size: 6.2rem;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessBox::before {
    font-size: 8.07292vw;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessBox::before {
    font-size: 7.8rem;
    font-size: 7.5vw;
    width: 8.073vw;
    height: 8.073vw;
    max-width: 62px;
    max-height: 62px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationProcessBox::before {
    font-size: 7.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationProcessBox::before {
    font-size: 10.15625vw;
  }
}

.donationProcessBox:nth-of-type(1)::before {
  content: "1";
}

.donationProcessBox:nth-of-type(2)::before {
  content: "2";
}

.donationProcessBox:nth-of-type(3)::before {
  content: "3";
}

.donationProcessBox:nth-of-type(4)::before {
  content: "4";
}

.donationProcessBox:not(:last-of-type) {
  margin-bottom: 60px;
}

.donationProcessBox:not(:last-of-type)::after {
  content: "";
  position: absolute;
  bottom: -54px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 32px solid transparent;
  border-top: 22px solid #b21d2e;
}

.donationProcessTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  flex-basis: 52.38%;
}

@media screen and (min-width: 1040px) {
  .donationProcessTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt {
    font-size: 4rem;
    font-size: 3.84615vw;
    flex-basis: calc(100% - 8vw - 20px);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationProcessTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationProcessTxt {
    font-size: 5.20833vw;
  }
}

.donationProcessTxt span {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  display: block;
  margin-top: 20px;
}

@media screen and (min-width: 1040px) {
  .donationProcessTxt span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt span {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt span {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationProcessTxt span {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationProcessTxt span {
    font-size: 4.6875vw;
  }
}

.donationProcessTxt span.minTxt {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #666;
}

@media screen and (min-width: 1040px) {
  .donationProcessTxt span.minTxt {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt span.minTxt {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessTxt span.minTxt {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationProcessTxt span.minTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationProcessTxt span.minTxt {
    font-size: 3.64583vw;
  }
}

.donationProcessImg {
  flex-basis: 37.62%;
}

@media screen and (max-width: 767px) {
  .donationProcessImg {
    flex-basis: 80%;
    margin: auto;
  }
}

.donationProcessImg img {
  width: 100%;
}

.donationProcessNote {
  margin-top: 10px;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #666;
}

@media screen and (min-width: 1040px) {
  .donationProcessNote {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessNote {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .donationProcessNote {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationProcessNote {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationProcessNote {
    font-size: 3.64583vw;
  }
}

.donationRcpt {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .donationRcpt {
    margin-bottom: 70px;
    /******アンカーリンク対応*****/
    padding-top: 60px;
  }
}

.donationRcptEndTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  .donationRcptEndTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptEndTxt {
    font-size: 3.125vw;
  }
}

.donationRcptEndTxt a {
  color: #b21d2e;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .donationRcptEndTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptEndTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptEndTxt {
    font-size: 4.6875vw;
  }
}

.donationRcptTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 70px;
}

@media screen and (min-width: 1040px) {
  .donationRcptTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptTxt {
    font-size: 3.125vw;
  }
}

.donationRcptTxt a {
  color: #b21d2e;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .donationRcptTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptTxt {
    font-size: 4.6875vw;
  }
}

.donationRcptMap {
  max-width: 940px;
  margin: auto;
  padding: 0 20px;
}

.donationRcptMap svg {
  width: 100%;
  height: 100%;
}

.donationRcptMap text {
  font-family: "EB Garamond", serif;
  font-size: 2.8rem;
  font-size: 2.69231vw;
}

@media screen and (min-width: 1040px) {
  .donationRcptMap text {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptMap text {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptMap text {
    font-size: 6.4rem;
    font-size: 6.15385vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptMap text {
    font-size: 6.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptMap text {
    font-size: 8.33333vw;
  }
}

.donationRcptMinTxt {
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 0.5px;
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .donationRcptMinTxt {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.8px;
    margin-top: 40px;
  }
}

@media screen and (min-width: 768px) {
  .donationVoiceBox {
    display: flex;
    justify-content: space-between;
  }
}

.donationVoiceBox:not(:last-of-type) {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .donationVoiceBox:not(:last-of-type) {
    margin-bottom: 50px;
  }
}

.donationVoiceBoxItem {
  flex-basis: calc(62% - 40px);
}

.donationVoiceBoxItem:first-of-type {
  flex-basis: 38%;
}

.donationVoiceBoxItem img {
  width: 100%;
}

.donationVoiceBoxItemName {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-top: 20px;
}

@media screen and (min-width: 1040px) {
  .donationVoiceBoxItemName {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemName {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemName {
    font-size: 4.2rem;
    font-size: 4.03846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationVoiceBoxItemName {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationVoiceBoxItemName {
    font-size: 5.46875vw;
  }
}

.donationVoiceBoxItemName span {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #666;
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 1040px) {
  .donationVoiceBoxItemName span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemName span {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemName span {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationVoiceBoxItemName span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationVoiceBoxItemName span {
    font-size: 3.64583vw;
  }
}

.donationVoiceBoxItemTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
}

@media screen and (min-width: 1040px) {
  .donationVoiceBoxItemTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .donationVoiceBoxItemTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationVoiceBoxItemTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationVoiceBoxItemTxt {
    font-size: 4.6875vw;
  }
}

.recipients {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .recipients {
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.recipients .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.recipients h2 {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: center;
  color: #b21d2e;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  .recipients h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .recipients h2 {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .recipients h2 {
    font-size: 4.6rem;
    font-size: 4.42308vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .recipients h2 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .recipients h2 {
    font-size: 5.98958vw;
  }
}

.recipients h2 span {
  border-bottom: 4px solid #b21d2e;
}

@media screen and (max-width: 767px) {
  .recipients h2 span {
    border-bottom: 2px solid #b21d2e;
  }
}

.recipientsRcptTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 70px;
}

@media screen and (min-width: 1040px) {
  .recipientsRcptTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .recipientsRcptTxt {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .recipientsRcptTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .recipientsRcptTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .recipientsRcptTxt {
    font-size: 4.6875vw;
  }
}

.recipientsRcptMap {
  max-width: 940px;
  margin: auto;
  padding: 0 20px;
}

.recipientsRcptMap svg {
  width: 100%;
  height: 100%;
}

.recipientsRcptMap text {
  font-family: "EB Garamond", serif;
  font-size: 2.8rem;
  font-size: 2.69231vw;
}

@media screen and (min-width: 1040px) {
  .recipientsRcptMap text {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .recipientsRcptMap text {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .recipientsRcptMap text {
    font-size: 6.4rem;
    font-size: 6.15385vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .recipientsRcptMap text {
    font-size: 6.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .recipientsRcptMap text {
    font-size: 8.33333vw;
  }
}

.recipientsRcptMapTxt {
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 0.5px;
  margin-top: 30px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .recipientsRcptMapTxt {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.8px;
    margin-top: 20px;
  }
}

.recipientsRcptMinTxt {
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: 0.5px;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .recipientsRcptMinTxt {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.8px;
    margin-top: 20px;
  }
}

.donationRcptDesTtl {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  color: #B21D2E;
}

@media screen and (min-width: 1040px) {
  .donationRcptDesTtl {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesTtl {
    font-size: 3.125vw;
  }
}

.donationRcptDesTtl:nth-of-type(2) {
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  .donationRcptDesTtl {
    font-size: 4.2rem;
    font-size: 4.03846vw;
    margin-bottom: 4.6875vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptDesTtl {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptDesTtl {
    font-size: 5.46875vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesTtl:nth-of-type(2) {
    margin-top: 9.114vw;
  }
}

.donationRcptDesBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.donationRcptDesBox .txtBox {
  width: 50%;
  text-align: start;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .txtBox {
    width: 100%;
  }
}

.donationRcptDesBox .txtBox strong {
  font-size: 2.4rem;
  font-size: 2.30769vw;
}

@media screen and (min-width: 1040px) {
  .donationRcptDesBox .txtBox strong {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .txtBox strong {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .txtBox strong {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptDesBox .txtBox strong {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptDesBox .txtBox strong {
    font-size: 4.6875vw;
  }
}

.donationRcptDesBox .txtBox p {
  font-size: 2.4rem;
  font-size: 2.30769vw;
}

@media screen and (min-width: 1040px) {
  .donationRcptDesBox .txtBox p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .txtBox p {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .txtBox p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptDesBox .txtBox p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptDesBox .txtBox p {
    font-size: 4.6875vw;
  }
}

.donationRcptDesBox .pdfBox {
  width: 50%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .pdfBox {
    margin-top: 7.822vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .pdfBox {
    width: 100%;
  }
}

.donationRcptDesBox .pdfBox .pdfBoxInner {
  width: 57.6%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .pdfBox .pdfBoxInner {
    width: 70%;
  }
}

.donationRcptDesBox .pdfBox img {
  margin: 0 auto;
  width: 100%;
}

.donationRcptDesBox .pdfBox .downloadBtn {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  background-color: #B21D2E;
  font-size: 1.3rem;
  font-size: 1.25vw;
  color: #fff;
  text-decoration: none;
  margin-top: 32px;
  letter-spacing: 0.3px;
  line-height: 1.5;
}

@media screen and (min-width: 1040px) {
  .donationRcptDesBox .pdfBox .downloadBtn {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .pdfBox .downloadBtn {
    font-size: 1.69271vw;
  }
}

@media screen and (max-width: 767px) {
  .donationRcptDesBox .pdfBox .downloadBtn {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding: 2.346vw 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationRcptDesBox .pdfBox .downloadBtn {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationRcptDesBox .pdfBox .downloadBtn {
    font-size: 4.16667vw;
  }
}

.downloadList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0 30px 20px;
  border-bottom: 1px dashed #C7C7C7;
  font-size: 1.8rem;
  font-size: 1.73077vw;
}

@media screen and (min-width: 1040px) {
  .downloadList {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .downloadList {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadList {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding: 7.8125vw 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .downloadList {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .downloadList {
    font-size: 4.16667vw;
  }
}

.downloadList:first-of-type {
  background-color: #F4F5F5;
  padding: 16px 0 16px 20px;
  border-bottom: none;
  font-size: 2rem;
  font-size: 1.92308vw;
}

@media screen and (min-width: 1040px) {
  .downloadList:first-of-type {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .downloadList:first-of-type {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadList:first-of-type {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    padding: 3.911vw 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .downloadList:first-of-type {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .downloadList:first-of-type {
    font-size: 4.6875vw;
  }
}

.downloadList dt {
  flex-basis: 60%;
  align-self: center;
}

.downloadList dt span {
  display: block;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #b21d2e;
  margin-top: 5px;
}

@media screen and (min-width: 1040px) {
  .downloadList dt span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .downloadList dt span {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadList dt span {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .downloadList dt span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .downloadList dt span {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadList dt {
    flex-basis: 100%;
  }
  .downloadList dt p {
    letter-spacing: 0.25px;
  }
}

.downloadList dd {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .downloadList dd {
    flex-basis: 100%;
    padding: 7.161vw 4.30vw 0;
    justify-content: space-between;
  }
}

.downloadList dd p {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .downloadList dd p {
    width: 100%;
    letter-spacing: 0.25px;
    font-size: 3.2rem;
    font-size: 3.07692vw;
    color: #A81E2D;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .downloadList dd p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .downloadList dd p {
    font-size: 4.16667vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadList dd p.greenTxt {
    color: #007732;
  }
  .downloadList dd p.blueTxt {
    color: #1D409D;
  }
}

.downloadList dd a {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .downloadList dd a:first-of-type {
    width: 45%;
  }
  .downloadList dd a:first-of-type img {
    width: 12.648vw;
  }
  .downloadList dd a:last-of-type {
    width: 45%;
  }
  .downloadList dd a:last-of-type img {
    width: 19.791vw;
  }
}

.downloadList dd a img {
  margin: 0 auto;
}

.downloadList dd .empty {
  width: 40%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (max-width: 767px) {
  .keyVisualTitRecipient {
    left: 5%;
  }
}

.recipientInstitution {
  padding: 0 0 60px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution {
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .btnBox {
    padding: 0 20px;
  }
}

.recipientInstitution .recipientInstitutionBtn {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 0 auto;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBtn {
    font-size: 2.2rem;
    width: 100%;
    margin: 0 auto;
    line-height: 1.68;
    letter-spacing: 1.14px;
    margin: 0 auto 20px;
    padding: 5px 25px;
  }
}

.recipientInstitution .followUpButton img {
  width: 7rem;
  position: fixed;
  z-index: 1;
  right: 4%;
  bottom: 30vh;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .followUpButton img {
    width: 5rem;
    right: 2%;
  }
}

.recipientInstitution .followUpButton img.hide {
  opacity: 0;
  transition: .6s;
  visibility: hidden;
}

.recipientInstitution .categoryColumn {
  background-color: #f4f5f5;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn {
    background-color: #fff;
  }
}

.recipientInstitution .categoryColumn .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 60px 20px 45px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .inner {
    padding: 60px 20px 0;
    position: relative;
  }
}

.recipientInstitution .categoryColumn .categoryArea {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.68px;
  color: #b21d2e;
  margin-bottom: 40px;
}

.recipientInstitution .categoryColumn .categoryArea .number {
  font-size: 2.4rem;
  letter-spacing: 1.44px;
  margin-left: 5px;
}

.recipientInstitution .categoryColumn .categoryNav {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryNav {
    position: absolute;
    top: 120%;
    left: calc(0 - 20px);
    width: calc(100% - 40px);
  }
}

.recipientInstitution .categoryColumn .activeSp {
  border: solid 1px #666666;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.17;
  letter-spacing: 2.16px;
  color: #666666;
  padding: 17px 20px;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.recipientInstitution .categoryColumn .activeSp::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 0 auto 0 0;
  background-image: url(/images/recipient-Institution/icon_accordion.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 6%;
  top: 42%;
  transition: .4s;
}

.recipientInstitution .categoryColumn .activeSp.open {
  border-bottom: solid 1px #c7c7c7;
}

.recipientInstitution .categoryColumn .activeSp.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.recipientInstitution .categoryColumn .selectBtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.recipientInstitution .categoryColumn .selectBtn .nationalFlag {
  max-width: 38px;
  margin-right: 15px;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .selectBtn .nationalFlag {
    max-width: 15vw;
    margin-right: 0;
  }
}

.recipientInstitution .categoryColumn .selectBtn .nationaltxt {
  max-width: 98px;
  flex: 1;
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: #231815;
  text-decoration: underline;
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .selectBtn .nationaltxt {
    max-width: 75vw;
  }
}

.recipientInstitution .categoryColumn .selectBtn .number {
  display: inline-block;
  color: #a81e2d;
  text-decoration: none;
  margin-left: 3px;
}

.recipientInstitution .categoryColumn .categoryMenu {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu {
    background-color: #fff;
    border-left: solid 1px #666666;
    border-right: solid 1px #666666;
    border-bottom: solid 1px #666666;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
    width: 100%;
    position: relative;
    z-index: 1;
    height: 31rem;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu.open {
    width: 100%;
    visibility: visible;
    opacity: 1;
    transition: .4s;
    transform: translateY(0);
  }
}

.recipientInstitution .categoryColumn .categoryMenu .categoryList {
  width: 20%;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .categoryList {
    width: 100%;
    background-color: #fff;
    margin: 0;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .categoryList a {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .categoryList a {
    padding: 17px 20px;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .categoryList a:hover {
    background-color: #f4f5f5;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .categoryList .nationalFlag {
  max-width: 38px;
  margin-right: 15px;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .categoryList .nationalFlag {
    max-width: 15vw;
    margin-right: 0;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .categoryList .nationaltxt {
  max-width: 98px;
  flex: 1;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #231815;
  text-decoration: underline;
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .categoryList .nationaltxt {
    max-width: 75vw;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .categoryList .number {
  display: inline-block;
  color: #a81e2d;
  text-decoration: none;
  margin-left: 3px;
}

.recipientInstitution .categoryColumn .categoryMenu .childCategoryList {
  width: 12%;
  margin-right: 15px;
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .childCategoryList {
    width: 100%;
    background-color: #fff;
    margin: 0;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .childCategoryList a {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .childCategoryList a {
    padding: 17px 20px;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .childCategoryList a:hover {
    background-color: #f4f5f5;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .childCategoryList .nationalFlag {
  max-width: 38px;
  margin-right: 15px;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .childCategoryList .nationalFlag {
    max-width: 15vw;
    margin-right: 0;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .childCategoryList .nationaltxt {
  max-width: 98px;
  flex: 1;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #231815;
  text-decoration: underline;
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .categoryColumn .categoryMenu .childCategoryList .nationaltxt {
    max-width: 75vw;
  }
}

.recipientInstitution .categoryColumn .categoryMenu .childCategoryList .number {
  display: inline-block;
  color: #a81e2d;
  text-decoration: none;
  margin-left: 3px;
}

.recipientInstitution .recipientInstitutionBookList {
  max-width: 880px;
  margin: 0 auto 60px;
  padding: 0 20px 20px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList {
    position: relative;
    padding: 30px 0 20px;
  }
}

.recipientInstitution .recipientInstitutionBookList .recipientInstitutionByNational {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .recipientInstitutionByNational {
    padding-top: 40px;
    margin-top: 40px;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTit {
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: #231815;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTit {
    padding: 0 20px;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTit .number {
  color: #a81e2d;
  margin-left: 10px;
}

.recipientInstitution .recipientInstitutionBookList .byNationalTit .byNationalFlag {
  width: 56px;
  margin-right: 20px;
}

.recipientInstitution .recipientInstitutionBookList .byNationalTit .byNationalFlag img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable {
    border-top: solid 1px #c7c7c7;
    border-bottom: solid 1px #c7c7c7;
    padding-top: 20px;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable thead {
  background-color: #f4f5f5;
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable tbody tr {
  border-bottom: dashed 1px #c7c7c7;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable tbody tr {
    border: none;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable tr:last-child .byNationalYear::after {
    display: none;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTh {
  padding: 15px 20px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #666666;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTh {
    display: block;
    padding: 0 12px 20px 20px;
    background-color: #f4f5f5;
    width: 30%;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd {
  padding: 20px;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  position: relative;
  word-break: keep-all;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd {
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
    padding: 0;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd .byNationalTdTxt {
    width: 70%;
    padding: 0 20px 20px 12px;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalInstitution {
  width: 365px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalInstitution {
    width: auto;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalLibrary {
  width: 375px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalLibrary {
    width: auto;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalYear::after {
  right: 20%;
  left: auto;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd.byNationalYear::after {
    content: "";
    display: block;
    width: 90%;
    border-bottom: dashed 1px #c7c7c7;
    position: absolute;
    left: 5%;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd:first-child .byNationalTh,
  .recipientInstitution .recipientInstitutionBookList .byNationalTable .byNationalTd:first-child .byNationalTdTxt {
    padding-top: 20px;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalChildTit {
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: #231815;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .recipientInstitution .recipientInstitutionBookList .byNationalChildTit {
    padding: 40px 20px 0;
  }
}

.recipientInstitution .recipientInstitutionBookList .byNationalChildTit .number {
  color: #a81e2d;
  margin-left: 10px;
}

.recipientInstitution .recipientInstitutionBookList .byNationalChildTit .byNationalFlag {
  width: 56px;
  margin-right: 20px;
}

.recipientInstitution .recipientInstitutionBookList .byNationalChildTit .byNationalFlag img {
  width: 100%;
}

.donationVoice {
  margin-top: 110px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .donationVoice {
    margin-top: 18.22vw;
    margin-bottom: 0;
  }
}

.donationVoice .donationVoiceBoxItemTxt {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .donationVoice .donationVoiceBoxItemTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .donationVoice .donationVoiceBoxItemTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .donationVoice .donationVoiceBoxItemTxt {
    font-size: 4.6875vw;
  }
}

.donationVoice .video {
  position: relative;
  cursor: pointer;
  transition: opacity .3s;
}

.donationVoice .video:hover {
  opacity: .7;
}

.donationVoice .video::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  width: 25.064%;
  padding-bottom: 25.064%;
  background-image: url(/images/bookdonation/video_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#donation .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: none;
}

#donation .overlay .modalBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  cursor: pointer;
}

#donation .overlay .modalMovie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  max-width: 1020px;
  width: 100%;
  padding: 0 20px;
}

@media screen and (min-width: 1366px) {
  #donation .overlay .modalMovie {
    max-width: 1400px;
  }
}

@media screen and (max-width: 767px) {
  #donation .overlay .modalMovie {
    width: 100%;
  }
}

#donation .overlay .modalMovie .video video {
  width: 100%;
}

#donation .overlay .modalMovie .modalMovieBtn {
  display: block;
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 0 15px auto;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 767px) {
  #donation .overlay .modalMovie .modalMovieBtn {
    width: 40px;
    height: 16px;
    margin-bottom: 20px;
  }
}

#donation .overlay .modalMovie .modalMovieBtn span {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transition: all .3s ease-out;
}

#donation .overlay .modalMovie .modalMovieBtn span:nth-of-type(1) {
  bottom: auto;
  transform: rotate(45deg);
}

#donation .overlay .modalMovie .modalMovieBtn span:nth-of-type(2) {
  opacity: 0;
}

#donation .overlay .modalMovie .modalMovieBtn span:nth-of-type(3) {
  top: auto;
  transform: rotate(-45deg);
}

.synopsis {
  padding: 60px 0 0;
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis {
    padding-top: 0;
    margin: 60px auto 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .synopsis {
    position: relative;
    z-index: 2;
    padding-top: 0;
    margin: 60px auto 0;
    overflow: hidden;
  }
}

.synopsis .synopsisBtn {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 30px auto 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisBtn {
    font-size: 2.2rem;
    width: 100%;
    margin: 0 auto;
    line-height: 1.68;
    letter-spacing: 1.14px;
    margin: 60px auto 20px;
    padding: 5px 25px;
  }
}

.synopsis .categoryColumn {
  max-width: 880px;
  margin: 0 auto 60px;
  padding: 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn {
    position: relative;
    margin-bottom: 50px;
  }
}

.synopsis .categoryColumnCurrent {
  font-size: 2rem;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: #a81e2d;
  display: block;
  background-color: #fff;
  padding: 2.2% 5.8%;
  border: solid 1px #a81e2d;
  position: relative;
  z-index: 2;
}

.synopsis .categoryColumnCurrent::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 0 auto 0 0;
  background-image: url(/images/synopsis/icon_accordion.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 6%;
  top: 42%;
  transition: .4s;
}

.synopsis .categoryColumnCurrent.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.synopsis .categoryColumn ul {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn ul {
    display: block;
    width: calc(100% - 40px);
    position: absolute;
    margin-top: -1px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
    border-top: solid 1px #c7c7c7;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn ul {
    display: block;
    width: calc(100% - 40px);
    position: absolute;
    margin-top: -1px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
    border-top: solid 1px #c7c7c7;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

.synopsis .categoryColumn ul.open {
  visibility: visible;
  opacity: 1;
  transition: .4s;
  transform: translateY(0);
}

.synopsis .categoryColumn ul li {
  margin-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn ul li {
    margin-right: 0;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn ul li {
    margin-right: 0;
    background-color: #fff;
  }
}

.synopsis .categoryColumn ul li:last-child {
  margin-right: 0;
}

.synopsis .categoryColumn ul li a {
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0;
  text-align: center;
  color: #666666;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn ul li a {
    display: block;
    background-color: #fff;
    padding: 4.4% 5.8%;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn ul li a {
    display: block;
    background-color: #fff;
    padding: 4.4% 5.8%;
    text-align: left;
  }
}

.synopsis .categoryColumn ul li a:hover {
  color: #a81e2d;
  padding-bottom: 5px;
  border-bottom: solid 1px #a81e2d;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn ul li a:hover {
    padding: 4.4% 5.8%;
    border-bottom: none;
    color: #000;
    opacity: 1;
    background-color: #f4f5f5;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn ul li a:hover {
    padding: 4.4% 5.8%;
    border-bottom: none;
    color: #000;
    opacity: 1;
    background-color: #f4f5f5;
  }
}

.synopsis .categoryColumn ul li.active a {
  color: #a81e2d;
  padding-bottom: 5px;
  border-bottom: solid 1px #a81e2d;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .synopsis .categoryColumn ul li.active {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .categoryColumn ul li.active {
    display: none;
  }
}

.synopsis .synopsisNote {
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.4px;
  color: #333;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisNote {
    font-size: 1.5rem;
    line-height: 1.43;
    letter-spacing: 0.7px;
    margin-top: 30px;
    margin-bottom: -5px;
    padding-bottom: 40px;
  }
}

.synopsis .synopsisNote::before {
  content: '';
  display: inline-block;
  margin: auto 10px;
  width: 8px;
  height: 1px;
  background-color: #333;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisNote::before {
    margin: auto 7px;
    transform: translateY(-3.5px);
  }
}

.synopsis .synopsisNote span {
  order: -1;
  display: inline-block;
  ont-family: GaramondPremrPro;
  font-size: 1.4rem;
  line-height: 0.86;
  letter-spacing: 0.35px;
  text-align: left;
  color: #a81e2d;
  padding: 4px 10px;
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisNote span {
    letter-spacing: 0.7px;
    padding: 4px 7px;
    margin-bottom: 7px;
  }
}

.synopsis .synopsisNote span::before {
  content: "";
  display: inline-block;
  background-image: url(/images/synopsis/icon_book.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisNote span::before {
    width: 11px;
    height: 10.5px;
    background-image: url(/images/synopsis/icon_book_sp.svg);
    margin-right: 5px;
    vertical-align: middle;
  }
}

.synopsis .synopsisColumn {
  position: relative;
}

.synopsis #synopsisLoadPoint {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.synopsis #synopsisLoadPoint::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #000;
  transform: translateY(150%);
  transition: opacity 1s;
  opacity: 0;
}

.synopsis #synopsisLoadPoint.loading::after {
  opacity: 1;
  animation: load 1.1s infinite linear;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.synopsis .postNum {
  display: none;
}

.synopsis .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.synopsis .inner .synopsisColumnTitle {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: left;
  color: #b21d2e;
  margin-bottom: 20px;
  visibility: hidden;
  position: absolute;
}

@media screen and (max-width: 767px) {
  .synopsis .inner .synopsisColumnTitle {
    font-size: 2.3rem;
    line-height: 1.07;
    margin-bottom: 4%;
  }
}

.synopsis .inner .synopsisColumnTitle + .synopsisCardLayout {
  visibility: hidden;
  position: absolute;
}

.synopsis .inner .synopsisColumnTitle.active {
  visibility: visible;
  position: relative;
}

.synopsis .inner .synopsisColumnTitle.active + .synopsisCardLayout {
  visibility: visible;
  position: relative;
}

.synopsis .synopsisCardLayout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout:not(:last-child) {
    margin-bottom: 0;
  }
}

.synopsis .synopsisCardLayout::after, .synopsis .synopsisCardLayout::before {
  content: "";
  display: block;
  width: 24%;
  height: 0;
}

.synopsis .synopsisCardLayout::before {
  order: 1;
}

.synopsis .synopsisCardLayout .synopsisCardBox {
  width: 24%;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCardBox {
    flex-basis: 49%;
    padding-top: 61px;
    margin-top: -61px;
    margin-bottom: 2%;
  }
}

.synopsis .synopsisCardLayout .synopsisCard {
  height: 100%;
  background-color: #f7f8f8;
  padding: 15px 20px 50px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCard {
    padding: 15px 5.3% 50px;
  }
}

.synopsis .synopsisCardLayout .synopsisCard .txtBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCategory {
  display: inline-block;
  ont-family: GaramondPremrPro;
  font-size: 1.6rem;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: left;
  color: #a81e2d;
  padding: 4px 10px;
  background-color: #ffffff;
  opacity: 0;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCategory::before {
  content: "";
  display: inline-block;
  background-image: url(/images/synopsis/icon_book.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12.2px;
  height: 11.7px;
  margin-right: 6px;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCard .synopsisCategory::before {
    width: 12.5px;
    height: 12px;
    background-image: url(/images/synopsis/icon_book_sp.svg);
    margin-right: 7px;
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCard .synopsisCategory {
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    padding: 6px 4px 4px;
  }
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCategory.show {
  opacity: 1;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisId {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: right;
  opacity: 0;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisId.show {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCard .synopsisId {
    font-size: 1.4rem;
    line-height: 0.38;
    letter-spacing: 0.4px;
  }
}

.synopsis .synopsisCardLayout .synopsisCard a:hover .synopsisCardVisual,
.synopsis .synopsisCardLayout .synopsisCard a:hover .synopsisCardTitle {
  opacity: 0.7;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCardVisual {
  margin-bottom: 15px;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCardVisual img {
  width: 100%;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCardTitle {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.35px;
  text-align: left;
  text-decoration: underline;
  color: #000000;
}

.synopsis .synopsisCardLayout .synopsisCard .synopsisCardTitle em {
  font-style: italic;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisCardLayout .synopsisCard .synopsisCardTitle {
    line-height: 1.36;
    letter-spacing: 0.7px;
  }
}

.synopsis .synopsisArticle {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle {
    display: block;
  }
}

.synopsis .synopsisArticle .synopsisArticleRightColumn {
  flex-basis: 33.57%;
  margin-right: 60px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleRightColumn {
    width: 60%;
    margin: 0 auto;
  }
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisArticleVisual {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .txtBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleRightColumn .txtBox {
    margin-bottom: 20%;
  }
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisCategory {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: left;
  color: #a81e2d;
  padding: 5px 12px;
  border: solid 1px #A81E2D;
  background-color: #ffffff;
  opacity: 0;
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisCategory::before {
  content: "";
  display: inline-block;
  background-image: url(/images/synopsis/icon_book.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 13px;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisCategory::before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisCategory {
    font-size: 1.6rem;
    line-height: 0.38;
    letter-spacing: 0.8px;
    padding: 3% 6% 2%;
  }
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisCategory.show {
  opacity: 1;
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisId {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: right;
  opacity: 0;
}

.synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisId.show {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleRightColumn .synopsisId {
    font-size: 1.5rem;
    line-height: 0.38;
    letter-spacing: 0.4px;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn {
  flex-basis: 55.83%;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn {
    width: 100%;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory {
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.5px;
  text-align: left;
  color: #a81e2d;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory {
    line-height: 1.42;
    letter-spacing: 0.95px;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle {
  font-size: 3.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle em {
  font-style: italic;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle {
    line-height: 1.29;
    letter-spacing: 1.05px;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication {
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.7px;
    margin-bottom: 10%;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor {
  font-size: 2.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor {
    font-size: 2rem;
    line-height: 1.5;
    letter-spacing: 0.7px;
  }
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  margin-bottom: 20px;
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p {
  margin-bottom: 15px;
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p:last-of-type {
  margin-bottom: 0;
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt em,
.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt i {
  font-style: italic;
}

.synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt b {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .synopsis .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt {
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0.9px;
  }
}

#synopsis .newsPager {
  max-width: 750px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager {
    position: relative;
    z-index: 1;
    max-width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    padding: 0 75px 0 0;
    margin: 0 auto 80px;
  }
  #synopsis .newsPager dl {
    max-width: 75px;
    width: 100%;
  }
  #synopsis .newsPager dl .pageAccordionArea {
    position: relative;
  }
  #synopsis .newsPager dl #pageAccordionBox {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
  }
  #synopsis .newsPager dl #pageAccordionBox.open {
    visibility: visible;
    opacity: 1;
    transition: .4s;
    transform: translateY(0);
  }
  #synopsis .newsPager .maxPage {
    font-size: 2.3rem;
    line-height: 1;
    letter-spacing: 0.46px;
    color: #c7c7c7;
    margin: 0 0 0 10px;
  }
}

#synopsis .newsPager ol {
  flex-wrap: wrap;
  justify-content: flex-start;
}

#synopsis .newsPager ol.center {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager ol {
    position: static;
    display: block;
    width: 100%;
    max-height: 215px;
    overflow-y: scroll;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

#synopsis .newsPager ol li {
  width: 35px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager ol li {
    width: auto;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager ol li a {
    display: block;
    font-size: 2.3rem;
    line-height: 1;
    letter-spacing: .572px;
    padding: 10px 15px 10px;
  }
  #synopsis .newsPager ol li a:hover {
    color: #000;
    background-color: #f4f5f5;
  }
}

#synopsis .newsPager ol li.current a {
  color: #b21d2e;
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager ol li.current {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager .next {
    position: absolute;
    width: 7px;
    top: 15px;
    left: calc(100% - 27px);
    padding: 0;
  }
  #synopsis .newsPager .next::after {
    display: none;
  }
  #synopsis .newsPager .next a {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #synopsis .newsPager .prev {
    position: absolute;
    width: 7px;
    top: 15px;
    left: calc(100% - 57px);
    padding: 0;
  }
  #synopsis .newsPager .prev::after {
    display: none;
  }
  #synopsis .newsPager .prev a {
    display: block;
  }
}

#synopsis .newsPager .currentPage {
  cursor: pointer;
  font-size: 2.3rem;
  line-height: 1;
  letter-spacing: .572px;
  color: #a81e2d;
  display: block;
  background-color: #fff;
  padding: 10px 15px 10px;
  border: solid 1px #a81e2d;
  position: relative;
  z-index: 2;
}

#synopsis .newsPager .currentPage::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 6px;
  margin: 0 auto 0 0;
  background-image: url(/images/synopsis/icon_accordion.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 14%;
  top: 44%;
  transition: .4s;
}

#synopsis .newsPager .currentPage.open {
  border-bottom: solid 1px #c7c7c7;
}

#synopsis .newsPager .currentPage.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.whoweare {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .whoweare {
    padding-top: 40px;
  }
}

.whoweare .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

.whoweareIntro {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin-bottom: 60px;
}

@media screen and (min-width: 1040px) {
  .whoweareIntro {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .whoweareIntro {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .whoweareIntro {
    font-size: 4rem;
    font-size: 3.84615vw;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .whoweareIntro {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .whoweareIntro {
    font-size: 5.20833vw;
  }
}

.whoweareLogo {
  width: 42.26%;
  margin: 0 auto 80px;
}

@media screen and (max-width: 767px) {
  .whoweareLogo {
    width: 60%;
    margin-bottom: 40px;
  }
}

.whoweareTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: left;
  color: #a81e2d;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .whoweareTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .whoweareTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .whoweareTit {
    font-size: 4.8rem;
    font-size: 4.61538vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .whoweareTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .whoweareTit {
    font-size: 6.25vw;
  }
}

.whoweareTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  .whoweareTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .whoweareTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .whoweareTxt {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .whoweareTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .whoweareTxt {
    font-size: 4.16667vw;
  }
}

.whoweareBtn {
  font-size: 1.9rem;
  font-size: 1.82692vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 60px auto 0;
  cursor: pointer;
}

@media screen and (min-width: 1040px) {
  .whoweareBtn {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  .whoweareBtn {
    font-size: 2.47396vw;
  }
}

@media screen and (max-width: 767px) {
  .whoweareBtn {
    font-size: 3.8rem;
    font-size: 3.65385vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .whoweareBtn {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .whoweareBtn {
    font-size: 4.94792vw;
  }
}

.contact {
  max-width: 820px;
  margin: 0 auto;
  padding: 60px 20px 0;
}

.contactTxt {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  .contactTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .contactTxt {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .contactTxt {
    font-size: 4rem;
    font-size: 3.84615vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .contactTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contactTxt {
    font-size: 5.20833vw;
  }
}

.contactForm {
  color: #000;
}

.contactForm dl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .contactForm dl {
    display: block;
    margin-bottom: 60px;
  }
}

.contactForm dl dt,
.contactForm dl dd {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: solid 1px #eaeaea;
}

.contactForm dl dt {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.8;
  letter-spacing: 0.5px;
  flex-basis: 20%;
}

@media screen and (min-width: 1040px) {
  .contactForm dl dt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dt {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dt {
    font-size: 4rem;
    font-size: 3.84615vw;
    border: none;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .contactForm dl dt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contactForm dl dt {
    font-size: 5.20833vw;
  }
}

.contactForm dl dd {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.71;
  letter-spacing: 0.7px;
  flex-basis: 80%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

@media screen and (min-width: 1040px) {
  .contactForm dl dd {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dd {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dd {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .contactForm dl dd {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contactForm dl dd {
    font-size: 4.16667vw;
  }
}

.contactForm dl dd input,
.contactForm dl dd textarea {
  width: 100%;
  padding: 10px;
  border: solid 1px #c7c7c7;
  border-radius: 0;
  background-color: #fff;
  outline: 0;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  color: #555;
  -webkit-appearance: none;
}

@media screen and (min-width: 1040px) {
  .contactForm dl dd input,
  .contactForm dl dd textarea {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dd input,
  .contactForm dl dd textarea {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .contactForm dl dd input,
  .contactForm dl dd textarea {
    font-size: 3rem;
    font-size: 2.88462vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .contactForm dl dd input,
  .contactForm dl dd textarea {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contactForm dl dd input,
  .contactForm dl dd textarea {
    font-size: 3.90625vw;
  }
}

.contactForm dl dd textarea {
  min-height: 230px;
}

.contactForm .g-recaptcha {
  margin-bottom: 30px;
}

.contactForm .g-recaptcha > div {
  margin: auto;
}

.contactFormBtnColumn {
  display: flex;
  justify-content: space-between;
  max-width: 560px;
  margin: auto;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .contactFormBtnColumn {
    margin-bottom: 40px;
  }
}

.contactFormBtnColumn .g-recaptcha {
  width: 100%;
  display: block;
}

.contactFormBtn {
  font-family: "EB Garamond", serif;
  font-size: 2rem;
  font-size: 1.92308vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  border: none;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: auto;
  cursor: pointer;
  border-radius: 0;
}

@media screen and (min-width: 1040px) {
  .contactFormBtn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .contactFormBtn {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .contactFormBtn {
    font-size: 4rem;
    font-size: 3.84615vw;
    padding: 10px 0;
    width: 340px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .contactFormBtn {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contactFormBtn {
    font-size: 5.20833vw;
  }
}

.contactFormBtn.back {
  color: #b21d2e;
  border: 1px solid #b21d2e;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .contactFormBtn.back {
    margin-bottom: 25px;
  }
}

.complete {
  max-width: 760px;
  margin: 0 auto;
  padding: 60px 20px 0;
  text-align: center;
  overflow: hidden;
}

.completeTit {
  font-size: 4rem;
  font-size: 3.84615vw;
  line-height: 1.23;
  letter-spacing: 1px;
  color: #a81e2d;
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  .completeTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .completeTit {
    font-size: 5.20833vw;
  }
}

.completeTit::before, .completeTit::after {
  content: "";
  display: block;
  width: 110%;
  height: 1px;
  background-color: #a81e2d;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.completeTit::before {
  left: -130%;
}

.completeTit::after {
  right: -130%;
}

.completeTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  .completeTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .completeTxt {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .completeTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .completeTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .completeTxt {
    font-size: 4.6875vw;
  }
}

.completeBtn {
  font-family: "EB Garamond", serif;
  font-size: 2rem;
  font-size: 1.92308vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  color: #fff;
  background-color: #b21d2e;
  border: none;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: auto;
  cursor: pointer;
}

@media screen and (min-width: 1040px) {
  .completeBtn {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .completeBtn {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .completeBtn {
    font-size: 4rem;
    font-size: 3.84615vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .completeBtn {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .completeBtn {
    font-size: 5.20833vw;
  }
}

#application .keyVisualTit {
  font-size: 3.6rem;
  font-size: 3.46154vw;
}

@media screen and (min-width: 1040px) {
  #application .keyVisualTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) {
  #application .keyVisualTit {
    font-size: 4.6875vw;
  }
}

@media screen and (max-width: 767px) {
  #application .keyVisualTit {
    font-size: 4.8rem;
    font-size: 4.61538vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .keyVisualTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .keyVisualTit {
    font-size: 6.25vw;
  }
}

#application .enterBtn, #application .returnBtn {
  font-family: "EB Garamond", serif;
  font-size: 1.9rem;
  font-size: 1.82692vw;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 60px auto 0;
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
}

@media screen and (min-width: 1040px) {
  #application .enterBtn, #application .returnBtn {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) {
  #application .enterBtn, #application .returnBtn {
    font-size: 2.47396vw;
  }
}

@media screen and (max-width: 767px) {
  #application .enterBtn, #application .returnBtn {
    font-size: 3.8rem;
    font-size: 3.65385vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .enterBtn, #application .returnBtn {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .enterBtn, #application .returnBtn {
    font-size: 4.94792vw;
  }
}

#application .enterBtn.disabled, #application .disabled.returnBtn {
  background-color: #8d8d8d;
}

#application .returnBtn {
  color: #b21d2e;
  background-color: #fff;
  border: solid 1px #b21d2e;
}

#application .required {
  display: inline-block;
  width: 55px;
  height: 15px;
  background: url(/images/application/required.svg) center/contain no-repeat;
  margin-left: 10px;
}

#application .required.indent {
  margin-left: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #application .required.indent {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  #application .required.indent {
    margin-left: 10px;
  }
}

#application .gate {
  padding: 50px 0;
}

@media screen and (max-width: 767px) {
  #application .gate {
    padding: 40px 0 70px;
  }
}

#application .gate .inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

#application .gate .gateTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #000;
  margin: 0 0 10px;
}

@media screen and (min-width: 1040px) {
  #application .gate .gateTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .gate .gateTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .gate .gateTit {
    font-size: 4.6875vw;
  }
}

#application .gate .gateProcessBox {
  position: relative;
  padding: 40px 0;
  border-bottom: solid 1px #b21d2e;
}

#application .gate .gateProcessBox.flex {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox.flex {
    display: block;
  }
}

#application .gate .gateProcessBox:not(:last-of-type) {
  margin: 0 0 12px;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox:not(:last-of-type) {
    margin: 0 0 6px;
  }
}

#application .gate .gateProcessBox:not(:last-of-type)::after {
  content: "";
  position: absolute;
  bottom: -54px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 32px solid transparent;
  border-top: 22px solid #b21d2e;
}

#application .gate .gateProcessBox .gateProcessTxt {
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessTxt {
    margin-right: 0;
  }
}

#application .gate .gateProcessBox .gateProcessImg {
  display: flex;
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessImg {
    justify-content: center;
    padding: 9.161vw 4.30vw 0;
  }
}

#application .gate .gateProcessBox .gateProcessImg a {
  display: flex;
  flex-flow: column;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  text-align: center;
  flex-shrink: 0;
  margin-right: 40px;
}

@media screen and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessImg a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessImg a {
    font-size: 2.08333vw;
  }
}

#application .gate .gateProcessBox .gateProcessImg a .img {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

#application .gate .gateProcessBox .gateProcessImg a img {
  display: block;
  margin: 0 auto 10px;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessImg a {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    letter-spacing: 0.25px;
    margin: 0 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessImg a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessImg a {
    font-size: 4.16667vw;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessImg a img {
    width: 12.648vw;
  }
  #application .gate .gateProcessBox .gateProcessImg a:last-of-type img {
    width: 19.791vw;
  }
}

#application .gate .gateProcessBox .gateProcessBoxNum {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #b21d2e;
  margin: 0 0 10px;
}

@media screen and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessBoxNum {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxNum {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxNum {
    font-size: 4.2rem;
    font-size: 4.03846vw;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessBoxNum {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxNum {
    font-size: 5.46875vw;
  }
}

#application .gate .gateProcessBox .gateProcessBoxTxt {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #000;
}

@media screen and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessBoxTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxTxt {
    font-size: 3.125vw;
  }
}

#application .gate .gateProcessBox .gateProcessBoxTxt a {
  color: inherit;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxTxt {
    font-size: 4.2rem;
    font-size: 4.03846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #application .gate .gateProcessBox .gateProcessBoxTxt {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #application .gate .gateProcessBox .gateProcessBoxTxt {
    font-size: 5.46875vw;
  }
}

.application {
  padding: 60px 20px 0;
}

.applicationClosed {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.7;
  letter-spacing: 0.5px;
  text-align: center;
  color: #333;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  .applicationClosed {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .applicationClosed {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .applicationClosed {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .applicationClosed {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .applicationClosed {
    font-size: 4.6875vw;
  }
}

.applicationTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.42;
  letter-spacing: 0.6px;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  .applicationTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .applicationTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .applicationTit {
    font-size: 4.4rem;
    font-size: 4.23077vw;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .applicationTit {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .applicationTit {
    font-size: 5.72917vw;
  }
}

.application .passField {
  font-family: "EB Garamond", serif;
  display: block;
  width: 100%;
  max-width: 580px;
  margin: auto;
  border: solid 1px #c7c7c7;
  background-color: #fff;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  padding: 12px 20px;
}

@media screen and (min-width: 1040px) {
  .application .passField {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .application .passField {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .application .passField {
    font-size: 3.4rem;
    font-size: 3.26923vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .application .passField {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .application .passField {
    font-size: 4.42708vw;
  }
}

.application .errorMsg {
  display: none;
  text-align: center;
  padding: 30px 0 0;
  color: #b21d2e;
}

.application .loginFailed {
  display: block;
}

.form {
  padding: 40px 20px 0;
  max-width: 880px;
  margin: auto;
  overflow: hidden;
}

.form .error {
  background-color: #fff2f3;
}

.form .checkBox,
.form .radioBtn {
  display: block;
}

@media screen and (max-width: 767px) {
  .form .checkBox,
  .form .radioBtn {
    margin: 0 auto;
    padding: 20px 0;
  }
}

.form .checkBox.error,
.form .radioBtn.error {
  padding: 10px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .form .checkBox.error,
  .form .radioBtn.error {
    padding: 20px;
  }
}

.form .checkBox .errorTxt,
.form .radioBtn .errorTxt {
  text-indent: -26px;
}

.form .checkBox p,
.form .radioBtn p {
  position: relative;
  font-size: 1.8rem;
  font-size: 1.73077vw;
  color: #231815;
  cursor: pointer;
  padding-left: 30px;
}

@media screen and (min-width: 1040px) {
  .form .checkBox p,
  .form .radioBtn p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .form .checkBox p,
  .form .radioBtn p {
    font-size: 2.34375vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .form .checkBox p,
  .form .radioBtn p {
    padding-left: 2.885vw;
  }
}

@media screen and (max-width: 767px) {
  .form .checkBox p,
  .form .radioBtn p {
    font-size: 4rem;
    font-size: 3.84615vw;
    line-height: 1.35;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .form .checkBox p,
  .form .radioBtn p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .form .checkBox p,
  .form .radioBtn p {
    font-size: 5.20833vw;
  }
}

.form .checkBox p::before,
.form .radioBtn p::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  border: solid 1px #231815;
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .form .checkBox p::before,
  .form .radioBtn p::before {
    top: 0.481vw;
    width: 1.731vw;
    height: 1.731vw;
  }
}

.form .checkBox p::after,
.form .radioBtn p::after {
  content: '';
  position: absolute;
  top: 8px;
  left: 3px;
  width: 12px;
  height: 12px;
  transform: scale(0.5);
  transition: .3s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .form .checkBox p::after,
  .form .radioBtn p::after {
    top: 0.769vw;
    left: 0.288vw;
    width: 1.154vw;
    height: 1.154vw;
  }
}

.form .checkBox input,
.form .radioBtn input {
  opacity: 0;
  position: absolute;
  width: 20px;
  padding: 9px;
  margin-top: 2px;
}

.form .checkBox input:checked + p::after,
.form .radioBtn input:checked + p::after {
  transform: scale(1);
  background: #a81e2d;
}

.form .radioBtn:first-of-type {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .form .radioBtn:first-of-type {
    margin-bottom: 0;
  }
}

.form .radioBtn.inline {
  display: inline-block;
}

.form .radioBtn.inline:first-of-type {
  margin-right: 40px;
  margin-bottom: 0;
}

.form .errorTxt {
  display: block;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.75;
  letter-spacing: 0.4px;
  color: #ff001a;
  position: relative;
  margin-top: 10px;
}

@media screen and (min-width: 1040px) {
  .form .errorTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .form .errorTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .form .errorTxt {
    font-size: 3.4rem;
    font-size: 3.26923vw;
    line-height: 1.41;
    letter-spacing: 0.85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .form .errorTxt {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .form .errorTxt {
    font-size: 4.42708vw;
  }
}

.form .errorTxt::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 12px;
  margin-right: 5px;
  background: url(/images/application/icon_warning.svg) center/contain no-repeat;
}

@media screen and (max-width: 767px) {
  .form .errorTxt::before {
    width: 17px;
    height: 15px;
    margin-right: 8px;
  }
}

.form .errorTxt.ib {
  display: inline-block;
  margin-left: 20px;
}

.formFlow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .formFlow {
    padding: 0 30px;
    margin-bottom: 20px;
  }
}

.formFlowTxt {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px 0 46px;
  background-color: #f4f5f5;
  color: #666;
  max-width: 33.333%;
  flex-basis: 33.333%;
}

@media screen and (max-width: 767px) {
  .formFlowTxt {
    display: none;
    max-width: 100%;
    flex-basis: 100%;
    justify-content: center;
    padding: 0 20px;
    height: 50px;
  }
  .formFlowTxt::before, .formFlowTxt::after {
    display: none;
  }
}

.formFlowTxt:not(:last-of-type)::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -50px;
  margin-top: -30px;
  border: 30px solid transparent;
  border-left: 20px solid #f4f5f5;
  z-index: 2;
}

.formFlowTxt:not(:last-of-type)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -60px;
  margin-top: -36px;
  border: 36px solid transparent;
  border-left: 24px solid #fff;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .formFlowTxt:first-of-type {
    padding-left: 22px;
  }
  .formFlowTxt:last-of-type {
    padding-right: 22px;
  }
}

.formFlowTxt.current {
  background-color: #a81e2d;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .formFlowTxt.current {
    display: flex;
  }
}

.formFlowTxt.current:not(:last-of-type)::before {
  border-left-color: #a81e2d;
}

.formFlowTxt p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  letter-spacing: 1.08px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 1040px) {
  .formFlowTxt p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFlowTxt p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formFlowTxt p {
    font-size: 4.2rem;
    font-size: 4.03846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFlowTxt p {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFlowTxt p {
    font-size: 5.46875vw;
  }
}

.formFlowNum {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .formFlowNum {
    display: none;
  }
}

.formFlowNum li {
  font-size: 5.6rem;
  font-size: 5.38462vw;
  line-height: 2.68;
  letter-spacing: 1.4px;
  color: #c7c7c7;
}

@media screen and (min-width: 1040px) {
  .formFlowNum li {
    font-size: 5.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formFlowNum li {
    font-size: 7.29167vw;
  }
}

.formFlowNum li:not(:first-child) {
  margin-left: 25px;
}

.formFlowNum li.current {
  font-size: 8rem;
  font-size: 7.69231vw;
  line-height: 1.88;
  letter-spacing: 2px;
  color: #a81e2d;
  position: relative;
}

@media screen and (min-width: 1040px) {
  .formFlowNum li.current {
    font-size: 8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFlowNum li.current {
    font-size: 10.41667vw;
  }
}

.formFlowNum li.current::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 6px solid #a81e2d;
}

.formLead {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: #333;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  .formLead {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .formLead {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .formLead {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formLead {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formLead {
    font-size: 4.6875vw;
  }
}

.formLead a {
  text-decoration: underline;
  color: #333;
}

.formLeadLink {
  margin: 60px 0 30px;
}

@media screen and (max-width: 767px) {
  .formLeadLink {
    margin-bottom: 20px 0 40px;
  }
}

.formLeadLink a {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.89;
  letter-spacing: 0.45px;
  color: #a81e2d;
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 1040px) {
  .formLeadLink a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formLeadLink a {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formLeadLink a {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formLeadLink a {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formLeadLink a {
    font-size: 4.6875vw;
  }
}

.formField .attention {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.89;
  letter-spacing: 0.45px;
  color: #ff001a;
  background-color: #f4f5f5;
  padding: 6px 10px;
  display: inline-block;
  margin-bottom: 30px;
}

@media screen and (min-width: 1040px) {
  .formField .attention {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formField .attention {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formField .attention {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding: 4px 6px;
    margin: 0 20px 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField .attention {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField .attention {
    font-size: 4.16667vw;
  }
}

.formFieldTit {
  font-size: 2.7rem;
  font-size: 2.59615vw;
  font-size: 27px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.68px;
  color: #000;
  padding-bottom: 20px;
  border-bottom: solid 1px #c7c7c7;
}

@media screen and (min-width: 1040px) {
  .formFieldTit {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldTit {
    font-size: 3.51563vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldTit {
    font-size: 4rem;
    font-size: 3.84615vw;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldTit {
    font-size: 5.20833vw;
  }
}

.formFieldTit .modalBtn {
  display: inline-block;
  max-width: 80px;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 0 20px;
}

@media screen and (max-width: 767px) {
  .formFieldTit .modalBtn {
    max-width: 60px;
    margin: 0 0 0 15px;
  }
}

.formFieldSubTit {
  font-size: 2rem;
  font-size: 1.92308vw;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 0.5px;
  color: #b21d2e;
  padding: 0 10px 5px;
}

@media screen and (min-width: 1040px) {
  .formFieldSubTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldSubTit {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldSubTit {
    font-size: 4rem;
    font-size: 3.84615vw;
    padding: 0 0 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldSubTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldSubTit {
    font-size: 5.20833vw;
  }
}

.formFieldList {
  padding: 30px 0;
  border: solid #c7c7c7;
  border-top: none;
  border-bottom: none;
  border-width: 1px 0;
  margin-bottom: 60px;
}

.formFieldList.first {
  margin-bottom: 10px;
}

.formFieldList.second {
  margin-bottom: 65px;
}

.formFieldList.bottom {
  padding: 30px 0;
  margin-bottom: 0;
  border-bottom: dotted 1px #c7c7c7;
}

.formFieldList.question {
  padding: 0;
  border: none;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .formFieldList.question {
    padding: 0 20px;
  }
}

.formFieldList dt, .formFieldList dd {
  padding: 0 10px;
}

@media screen and (max-width: 767px) {
  .formFieldList dt, .formFieldList dd {
    padding: 0;
  }
}

.formFieldList dt {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #333;
  padding-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formFieldList dt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dt {
    font-size: 2.34375vw;
  }
}

.formFieldList dt .red {
  display: inline-block;
  color: #a81e2d;
  padding-top: 4px;
}

.formFieldList dt .red a {
  color: #a81e2d;
}

@media screen and (max-width: 767px) {
  .formFieldList dt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dt {
    font-size: 4.6875vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dt.error {
    padding-top: 15px;
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.formFieldList dt span:not([class]) {
  display: block;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.5;
  letter-spacing: 0.35px;
}

@media screen and (min-width: 1040px) {
  .formFieldList dt span:not([class]) {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dt span:not([class]) {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dt span:not([class]) {
    font-size: 3rem;
    font-size: 2.88462vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dt span:not([class]) {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dt span:not([class]) {
    font-size: 3.90625vw;
  }
}

.formFieldList dt strong {
  font-weight: bold;
}

.formFieldList dt.twoColumn {
  display: inline-block;
  width: 31%;
  vertical-align: top;
  padding-top: 8px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .formFieldList dt.twoColumn {
    padding-top: 13px;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dt.twoColumn {
    display: block;
    width: 100%;
    padding-top: 0;
  }
}

.formFieldList dt a {
  text-decoration: underline;
}

.formFieldList dt .modalBtn {
  display: inline-block;
  max-width: 80px;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 0 20px;
}

@media screen and (max-width: 767px) {
  .formFieldList dt .modalBtn {
    max-width: 60px;
    margin: 5px 0 0 15px;
  }
}

.formFieldList dd:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: dotted 1px #c7c7c7;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd:not(:last-child) {
    margin-bottom: 30px;
  }
}

.formFieldList dd:not(:last-child).error::after {
  content: "";
  width: 100%;
  border-bottom: dotted 1px #c7c7c7;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.formFieldList dd.twoColumn {
  display: inline-block;
  width: 68%;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd.twoColumn {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
}

.formFieldList dd.error {
  padding-bottom: 20px;
  margin-bottom: 70px;
  position: relative;
  border: none;
}

@media screen and (max-width: 767px) {
  .formFieldList dd.error {
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.formFieldList dd p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #333;
}

@media screen and (min-width: 1040px) {
  .formFieldList dd p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dd p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dd p {
    font-size: 4.6875vw;
  }
}

.formFieldList dd span {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #333;
}

@media screen and (min-width: 1040px) {
  .formFieldList dd span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd span {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd span {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dd span {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dd span {
    font-size: 4.6875vw;
  }
}

.formFieldList dd .note {
  display: block;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.5;
  letter-spacing: 0.35px;
  color: #666;
  margin-top: 10px;
}

@media screen and (min-width: 1040px) {
  .formFieldList dd .note {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .note {
    font-size: 1.82292vw;
  }
}

.formFieldList dd .note.left {
  padding-left: 30px;
}

.formFieldList dd .note + .note {
  margin-top: 3px;
}

.formFieldList dd .note.red {
  color: #a81e2d;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .note {
    font-size: 3rem;
    font-size: 2.88462vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dd .note {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dd .note {
    font-size: 3.90625vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .note.left {
    padding-left: 0;
  }
}

.formFieldList dd .row {
  display: flex;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .row {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .row span:not([class]) {
    display: block;
    margin-bottom: 12px;
  }
}

.formFieldList dd .row .selectBox + .note {
  margin: 0 0 0 10px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .row .selectBox + .note {
    margin: 10px 0 0;
  }
}

.formFieldList dd .address label {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .address label {
    display: block;
  }
}

.formFieldList dd .address label:not(:first-child) {
  margin-top: 20px;
}

.formFieldList dd .address label span {
  width: 13%;
  color: #333;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .address label input {
    margin-top: 12px;
  }
}

.formFieldList dd .tel span:not([class]) {
  padding-top: 10px;
  margin-right: 15px;
}

.formFieldList dd .tel label:first-of-type {
  flex-basis: 35%;
  flex: inherit;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .tel label:first-of-type {
    display: block;
    width: calc(100% - 30px);
    margin-top: 12px;
    margin-bottom: 25px;
  }
}

.formFieldList dd .tel label:last-of-type {
  flex-basis: auto;
  flex: 1;
}

.formFieldList dd .titleName {
  justify-content: space-between;
}

.formFieldList dd .titleName .selectBox {
  flex-basis: 20%;
  margin-right: 45px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .titleName .selectBox {
    width: calc(100% - 130px);
  }
}

.formFieldList dd .titleName span:not([class]) {
  padding-top: 10px;
  margin-right: 15px;
}

.formFieldList dd .titleName label {
  flex-basis: 28.4%;
}

.formFieldList dd .titleName label:not(:last-child) {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .titleName label:not(:last-child) {
    margin-right: 0;
  }
}

.formFieldList dd .annual {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .annual {
    display: flex;
  }
}

.formFieldList dd .annual p {
  padding-top: 6px;
  margin-right: 15px;
}

.formFieldList dd .annual .required {
  height: 40px !important;
}

.formFieldList dd .annual .selectBox {
  width: auto;
  flex: 1;
}

.formFieldList dd .collection {
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .collection {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .collection span:not([class]) {
    width: calc(100% - 200px);
  }
}

.formFieldList dd .collection .selectBox {
  max-width: 260px;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .collection .selectBox {
    max-width: 200px;
    margin-bottom: 25px;
  }
}

.formFieldList dd .which {
  align-items: center;
  margin-bottom: 30px;
}

.formFieldList dd .which .checkBox {
  margin-right: 30px;
}

.formFieldList dd .fileArea {
  padding: 20px 0 0;
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .fileArea {
    padding: 10px 0 0;
    margin: 10px 0 0;
  }
}

.formFieldList dd .fileArea .fileAreaTit {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #333;
  margin: 0 0 20px;
}

@media screen and (min-width: 1040px) {
  .formFieldList dd .fileArea .fileAreaTit {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .fileArea .fileAreaTit {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .fileArea .fileAreaTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dd .fileArea .fileAreaTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dd .fileArea .fileAreaTit {
    font-size: 4.6875vw;
  }
}

.formFieldList dd .fileArea .red {
  color: #a81e2d;
}

.formFieldList dd .fileArea a {
  text-decoration: underline;
}

.formFieldList dd .otherArea {
  padding: 20px 0 0;
  margin: 10px 0 0;
}

@media screen and (max-width: 767px) {
  .formFieldList dd .otherArea {
    padding: 20px 0 0;
    margin: 0;
  }
}

.formFieldList dd .otherArea .otherAreaTit {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #333;
  margin: 0 0 20px;
}

@media screen and (min-width: 1040px) {
  .formFieldList dd .otherArea .otherAreaTit {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .otherArea .otherAreaTit {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formFieldList dd .otherArea .otherAreaTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formFieldList dd .otherArea .otherAreaTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formFieldList dd .otherArea .otherAreaTit {
    font-size: 4.6875vw;
  }
}

.formFieldList dd .otherArea .red {
  color: #a81e2d;
}

.formFieldList dd .otherArea a {
  text-decoration: underline;
}

.formField input, .formField select, .formField textarea {
  font-family: "EB Garamond", serif;
  width: 100%;
  border: solid 1px #c7c7c7;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  padding: 0 20px;
  height: 40px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;
}

@media screen and (min-width: 1040px) {
  .formField input, .formField select, .formField textarea {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formField input, .formField select, .formField textarea {
    font-size: 2.08333vw;
  }
}

.formField input::placeholder, .formField select::placeholder, .formField textarea::placeholder {
  color: #999;
}

.formField input:-ms-input-placeholder, .formField select:-ms-input-placeholder, .formField textarea:-ms-input-placeholder {
  color: #999;
}

.formField input::-ms-input-placeholder, .formField select::-ms-input-placeholder, .formField textarea::-ms-input-placeholder {
  color: #999;
}

.formField input.mt20, .formField select.mt20, .formField textarea.mt20 {
  margin-top: 20px;
}

.formField input:disabled, .formField select:disabled, .formField textarea:disabled {
  background-color: rgba(239, 239, 239, 0.3);
}

@media screen and (max-width: 767px) {
  .formField input, .formField select, .formField textarea {
    font-size: 3.4rem;
    font-size: 3.26923vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField input, .formField select, .formField textarea {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField input, .formField select, .formField textarea {
    font-size: 4.42708vw;
  }
}

.formField textarea {
  min-height: 180px;
  resize: vertical;
}

.formField .selectBox {
  position: relative;
  width: 100%;
  max-width: 360px;
}

@media screen and (max-width: 767px) {
  .formField .selectBox {
    width: calc(100% - 30px);
  }
}

.formField .selectBox::after {
  content: "";
  width: 14px;
  height: 6px;
  display: block;
  background: url(/images/application/icon_down.svg) center/contain no-repeat;
  position: absolute;
  top: 17px;
  right: 20px;
  margin: auto;
}

.formField .selectBox select {
  max-width: 360px;
  color: #999;
  background-color: #fff;
  border-radius: 0;
}

.formField .selectBox select.selected {
  color: #000;
}

.formField .selectBox.min {
  max-width: 270px;
}

.formField .selectBox.min select {
  max-width: 270px;
}

@media screen and (max-width: 767px) {
  .formField .selectBox.min {
    max-width: 90%;
  }
  .formField .selectBox.min select {
    max-width: 100%;
  }
}

.formField .selectBox.big {
  max-width: 100%;
}

.formField .selectBox.big select {
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .formField .selectBox.big {
    width: 100%;
  }
}

.formField .bgArea {
  margin: 0 calc(50% - 50vw) 30px;
  padding: 50px calc((50vw - 50%) + 60px);
  background-color: #f7f8f8;
}

@media screen and (max-width: 767px) {
  .formField .bgArea {
    padding: 30px 20px;
    margin-bottom: 40px;
  }
}

.formField .caution {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #a81e2d;
}

@media screen and (min-width: 1040px) {
  .formField .caution {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formField .caution {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formField .caution {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField .caution {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField .caution {
    font-size: 4.6875vw;
  }
}

.formField .upload {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

@media screen and (max-width: 767px) {
  .formField .upload {
    display: block;
  }
}

.formField .upload p:not([class]) {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  margin-right: 30px;
}

@media screen and (min-width: 1040px) {
  .formField .upload p:not([class]) {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formField .upload p:not([class]) {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formField .upload p:not([class]) {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField .upload p:not([class]) {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField .upload p:not([class]) {
    font-size: 4.6875vw;
  }
}

@media screen and (min-width: 768px) {
  .formField .upload p:not([class]) .required {
    display: block !important;
    margin-left: 0 !important;
  }
}

.formField .uploadBtn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 520px;
}

.formField .uploadBtn input {
  width: calc(100% - 126px);
}

.formField .uploadBtn label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #666;
  width: 126px;
  height: 40px;
  cursor: pointer;
}

.formField .uploadBtn label span {
  color: #fff;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.94;
  letter-spacing: 0.96px;
  text-align: center;
}

@media screen and (min-width: 1040px) {
  .formField .uploadBtn label span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formField .uploadBtn label span {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .formField .uploadBtn label span {
    font-size: 3.4rem;
    font-size: 3.26923vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField .uploadBtn label span {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField .uploadBtn label span {
    font-size: 4.42708vw;
  }
}

.formField .uploadBtn input[type=file] {
  display: none;
}

.formField .uploadNote {
  width: 100%;
  margin-top: 3px;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  color: #666;
}

@media screen and (min-width: 1040px) {
  .formField .uploadNote {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formField .uploadNote {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .formField .uploadNote {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formField .uploadNote {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formField .uploadNote {
    font-size: 3.64583vw;
  }
}

.formAgree {
  margin: 40px 0 80px;
}

@media screen and (max-width: 767px) {
  .formAgree {
    margin: 20px 0 40px;
  }
}

.formAgree p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formAgree p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formAgree p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formAgree p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formAgree p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formAgree p {
    font-size: 4.6875vw;
  }
}

.formAgree .checkBox {
  margin-bottom: 30px;
}

.formAgree .checkBox.agree {
  padding: 12px 0 20px;
}

.formAgree .checkBox p {
  margin: 0;
}

.formAgreeCaution {
  color: #b21d2e;
  font-size: 1.6rem;
  font-size: 1.53846vw;
}

@media screen and (min-width: 1040px) {
  .formAgreeCaution {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formAgreeCaution {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .formAgreeCaution {
    font-size: 3.4rem;
    font-size: 3.26923vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formAgreeCaution {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formAgreeCaution {
    font-size: 4.42708vw;
  }
}

.formSelectHead {
  margin-bottom: 55px;
}

.formSelectHead.error {
  padding: 15px calc(50vw - 50%);
  margin: 0 calc(50% - 50vw) 40px;
}

@media screen and (max-width: 767px) {
  .formSelectHead.error {
    padding: 20px calc(50vw - 50%);
    margin-bottom: 50px;
  }
}

.formSelectHead.error .formSelectTxt {
  color: #ff001a;
}

.formSelectTit {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #333;
  margin-bottom: 10px;
}

@media screen and (min-width: 1040px) {
  .formSelectTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .formSelectTit {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .formSelectTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formSelectTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formSelectTit {
    font-size: 4.6875vw;
  }
}

.formSelectTit a {
  text-decoration: underline;
  color: #333;
}

.formSelectTxt {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.88;
  letter-spacing: 0.96px;
  color: #ff001a;
}

@media screen and (min-width: 1040px) {
  .formSelectTxt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formSelectTxt {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .formSelectTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formSelectTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formSelectTxt {
    font-size: 4.6875vw;
  }
}

.formSelectGenre {
  margin-bottom: 20px;
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  .formSelectGenre {
    margin-bottom: 5px;
    padding-left: 0;
  }
}

.formSelectGenre p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #333;
}

@media screen and (min-width: 1040px) {
  .formSelectGenre p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formSelectGenre p {
    font-size: 2.34375vw;
  }
}

.formSelectList {
  border: solid #c7c7c7;
  border-width: 1px 0;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}

.formSelectList:not(:last-of-type) {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .formSelectList:not(:last-of-type) {
    margin-bottom: 90px;
  }
}

.formSelectList::after {
  content: "";
  width: 30%;
}

@media screen and (max-width: 767px) {
  .formSelectList {
    display: block;
    margin: 0 calc(50% - 50vw);
  }
}

.formSelectList li {
  flex-basis: 30%;
  padding: 20px 0;
}

@media screen and (min-width: 768px) {
  .formSelectList li:nth-child(3n-2) {
    padding-left: 10px;
  }
  .formSelectList li:nth-child(3n) {
    padding-right: 10px;
  }
  .formSelectList li:nth-child(6n-2) {
    position: relative;
  }
  .formSelectList li:nth-child(6n-2)::after {
    content: "";
    width: 100vw;
    max-width: 840px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f7f8f8;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  .formSelectList li {
    padding: 0;
  }
  .formSelectList li:nth-child(2n) {
    background-color: #f7f8f8;
  }
}

.formSelectList li > p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  color: #231815;
  padding-left: 30px;
}

@media screen and (min-width: 1040px) {
  .formSelectList li > p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formSelectList li > p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formSelectList li > p {
    font-size: 4rem;
    font-size: 3.84615vw;
    line-height: 1.35;
    margin: 0 calc(50% - 50vw);
    padding: 20px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formSelectList li > p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formSelectList li > p {
    font-size: 5.20833vw;
  }
}

.formConfirmLead {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: #333;
  margin-bottom: 80px;
}

@media screen and (min-width: 1040px) {
  .formConfirmLead {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmLead {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmLead {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmLead {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmLead {
    font-size: 4.6875vw;
  }
}

.formConfirmTit {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.8;
  letter-spacing: 0.5px;
  color: #b21d2e;
  position: relative;
  margin-left: 25px;
  text-indent: -25px;
  padding-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formConfirmTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmTit {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmTit {
    font-size: 4rem;
    font-size: 3.84615vw;
    margin-left: 18px;
    text-indent: -18px;
    padding: 30px 20px 20px 38px;
    background-color: #f7f8f8;
    border-bottom: solid 1px #c7c7c7;
    margin: 0 calc(50% - 50vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmTit {
    font-size: 5.20833vw;
  }
}

.formConfirmTit::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 1px;
  background-color: #b21d2e;
  margin-right: 10px;
  margin-bottom: 6px;
}

@media screen and (max-width: 767px) {
  .formConfirmTit::before {
    width: 11px;
    margin-right: 7px;
  }
}

.formConfirmEdit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  position: absolute;
  bottom: 20px;
  right: 0;
  margin: auto;
  background-color: #ce2437;
  font-size: 1.6rem;
  letter-spacing: 0.8px;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .formConfirmEdit {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    right: 20px;
    width: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmEdit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmEdit {
    font-size: 4.16667vw;
  }
}

.formConfirmEdit::before {
  content: '';
  width: 5px;
  height: 5px;
  border: 0px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  transform: rotate(-135deg);
  display: inline-block;
  margin-right: 10px;
}

.formConfirmList {
  padding: 40px 30px;
  background-color: #f7f8f8;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .formConfirmList {
    margin: 0 calc(50% - 50vw) 70px;
    padding: 30px 20px;
  }
}

.formConfirmList.second {
  margin-top: 20px;
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .formConfirmList.second {
    margin-bottom: 70px;
  }
}

.formConfirmList.first {
  padding: 40px 0;
  margin-bottom: 10px;
  background-color: inherit;
}

@media screen and (max-width: 767px) {
  .formConfirmList.first {
    margin: 0 auto 10px;
  }
}

.formConfirmList.bottom {
  padding: 30px 0;
  background-color: inherit;
}

@media screen and (max-width: 767px) {
  .formConfirmList.bottom {
    margin: 0 auto 20px;
  }
}

.formConfirmList dt, .formConfirmList dd {
  padding: 0 10px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dt, .formConfirmList dd {
    padding: 0;
  }
}

.formConfirmList dt {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #555;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dt {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dt {
    font-size: 4.6875vw;
  }
}

.formConfirmList dt span:not([class]) {
  display: block;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.5;
  letter-spacing: 0.35px;
  color: #333;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dt span:not([class]) {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dt span:not([class]) {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dt span:not([class]) {
    font-size: 3rem;
    font-size: 2.88462vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dt span:not([class]) {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dt span:not([class]) {
    font-size: 3.90625vw;
  }
}

.formConfirmList dt .red {
  display: inline-block;
  color: #a81e2d;
  padding-top: 4px;
}

.formConfirmList dt .red a {
  color: #a81e2d;
}

.formConfirmList dt .note {
  display: block;
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.5;
  letter-spacing: 0.35px;
  color: #666;
  margin-top: 10px;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dt .note {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dt .note {
    font-size: 1.82292vw;
  }
}

.formConfirmList dt .note.red {
  color: #a81e2d;
}

@media screen and (max-width: 767px) {
  .formConfirmList dt .note {
    font-size: 3rem;
    font-size: 2.88462vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dt .note {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dt .note {
    font-size: 3.90625vw;
  }
}

.formConfirmList dt.border {
  border-top: dotted 1px #c7c7c7;
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dt.border {
    padding-top: 20px;
  }
}

.formConfirmList dt strong {
  color: #000;
}

.formConfirmList dt.twoColumn {
  display: inline-block;
  width: 31%;
  vertical-align: top;
  padding-top: 2px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dt.twoColumn {
    display: block;
    width: 100%;
    padding-top: 0;
  }
}

.formConfirmList dt a {
  text-decoration: underline;
}

.formConfirmList dd:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: dotted 1px #c7c7c7;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd:not(:last-child) {
    margin-bottom: 20px;
  }
}

.formConfirmList dd p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #000;
  display: inline-block;
  margin-right: 15px;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dd p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dd p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dd p {
    font-size: 4.6875vw;
  }
}

.formConfirmList dd p span {
  color: #555;
  margin-right: 20px;
}

.formConfirmList dd .fileArea .red {
  color: #a81e2d;
}

.formConfirmList dd .fileArea a {
  text-decoration: underline;
}

.formConfirmList dd .otherArea .red {
  color: #a81e2d;
}

.formConfirmList dd .otherArea a {
  text-decoration: underline;
}

.formConfirmList dd .address p {
  display: block;
}

.formConfirmList dd .address p:not(:last-child) {
  margin-bottom: 15px;
}

.formConfirmList dd .address .flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .address .flex {
    display: block;
  }
}

.formConfirmList dd .address .flex:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .address .flex p {
    margin: 4px 0 28px;
  }
}

.formConfirmList dd .address .flex span {
  width: 13%;
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #666;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dd .address .flex span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .address .flex span {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .address .flex span {
    width: 100%;
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dd .address .flex span {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dd .address .flex span {
    font-size: 4.6875vw;
  }
}

.formConfirmList dd .row {
  display: flex;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .row {
    display: block;
  }
}

.formConfirmList dd .row .required {
  height: auto !important;
}

.formConfirmList dd .collection {
  margin-bottom: 0;
}

.formConfirmList dd .collection p {
  margin-right: 140px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .collection p {
    margin: 0;
  }
  .formConfirmList dd .collection p:first-child {
    margin-bottom: 20px;
  }
}

.formConfirmList dd.twoColumn {
  display: inline-block;
  width: 68%;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd.twoColumn {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
}

.formConfirmList dd .fileArea {
  padding: 20px 0 0;
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .fileArea {
    padding: 10px 0 0;
    margin: 10px 0 0;
  }
}

.formConfirmList dd .fileArea .fileAreaTit {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #555;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dd .fileArea .fileAreaTit {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .fileArea .fileAreaTit {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .fileArea .fileAreaTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dd .fileArea .fileAreaTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dd .fileArea .fileAreaTit {
    font-size: 4.6875vw;
  }
}

.formConfirmList dd .otherArea {
  padding: 20px 0 0;
  margin: 20px 0 0;
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .otherArea {
    padding: 20px 0 0;
    margin: 10px 0 0;
  }
}

.formConfirmList dd .otherArea .otherAreaTit {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #555;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formConfirmList dd .otherArea .otherAreaTit {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .otherArea .otherAreaTit {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmList dd .otherArea .otherAreaTit {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmList dd .otherArea .otherAreaTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmList dd .otherArea .otherAreaTit {
    font-size: 4.6875vw;
  }
}

.formConfirm .bgArea {
  margin: 0 calc(50% - 50vw) 40px;
  padding: 50px calc((50vw - 50%) + 40px);
  background-color: #f7f8f8;
}

@media screen and (max-width: 767px) {
  .formConfirm .bgArea {
    padding: 30px 20px;
    margin-bottom: 40px;
  }
}

.formConfirm .caution {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #555;
}

@media screen and (min-width: 1040px) {
  .formConfirm .caution {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .caution {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .caution {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirm .caution {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirm .caution {
    font-size: 4.6875vw;
  }
}

.formConfirm .upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.formConfirm .upload::before {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(/images/application/icon_success.svg) center/contain no-repeat;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .formConfirm .upload::before {
    margin-bottom: 15px;
  }
}

.formConfirm .upload:not(:last-of-type) {
  margin-bottom: 30px;
}

.formConfirm .upload:first-of-type {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .formConfirm .upload:first-of-type {
    margin-top: 20px;
  }
}

.formConfirm .upload p {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  margin-right: 30px;
}

@media screen and (min-width: 1040px) {
  .formConfirm .upload p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .upload p {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .upload p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin: 0 0 15px;
    display: inline-block;
    flex-basis: calc(100% - 28px);
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirm .upload p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirm .upload p {
    font-size: 4.6875vw;
  }
}

@media screen and (min-width: 768px) {
  .formConfirm .upload p .required {
    display: block !important;
    margin-left: 0 !important;
  }
}

.formConfirm .upload img {
  margin-right: 15px;
}

.formConfirm .upload span, .formConfirm .upload a {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: #555;
  display: inline-block;
  text-decoration: underline;
  align-self: flex-end;
}

@media screen and (min-width: 1040px) {
  .formConfirm .upload span, .formConfirm .upload a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .upload span, .formConfirm .upload a {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirm .upload span, .formConfirm .upload a {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirm .upload span, .formConfirm .upload a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirm .upload span, .formConfirm .upload a {
    font-size: 3.64583vw;
  }
}

.formConfirmSelect {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .formConfirmSelect {
    margin-top: 30px;
  }
}

.formConfirmSelectGenre {
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 1.8rem;
  font-size: 1.73077vw;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.45px;
  color: #000;
}

@media screen and (min-width: 1040px) {
  .formConfirmSelectGenre {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmSelectGenre {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmSelectGenre {
    margin-bottom: 5px;
    padding-left: 0;
  }
}

.formConfirmSelectList {
  border-top: solid 1px #c7c7c7;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}

.formConfirmSelectList:not(:last-of-type) {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .formConfirmSelectList:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.formConfirmSelectList::after {
  content: "";
  width: 30%;
}

@media screen and (max-width: 767px) {
  .formConfirmSelectList {
    display: block;
  }
}

.formConfirmSelectList li {
  flex-basis: 28%;
  padding: 20px 0;
}

@media screen and (min-width: 768px) {
  .formConfirmSelectList li:nth-child(3n-2) {
    padding-left: 10px;
    position: relative;
  }
  .formConfirmSelectList li:nth-child(3n-2)::after {
    content: "";
    width: 100vw;
    max-width: 840px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: dotted 1px #c7c7c7;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmSelectList li {
    padding: 10px 0;
  }
  .formConfirmSelectList li:nth-child(2n) {
    background-color: #f7f8f8;
  }
}

.formConfirmSelectList li p {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #333;
  margin: 0;
  text-indent: -1em;
  padding-left: 1em;
}

@media screen and (min-width: 1040px) {
  .formConfirmSelectList li p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmSelectList li p {
    font-size: 2.08333vw;
  }
}

.formConfirmSelectList li p::before {
  content: '・';
}

.formConfirmTxt {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.33;
  letter-spacing: 0.45px;
  color: #000;
  margin-bottom: 20px;
}

@media screen and (min-width: 1040px) {
  .formConfirmTxt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmTxt {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .formConfirmTxt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formConfirmTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formConfirmTxt {
    font-size: 4.6875vw;
  }
}

.formConfirmColumn {
  display: flex;
  justify-content: space-between;
  max-width: 580px;
  margin: auto;
}

.formConfirmColumn .returnBtn {
  margin-right: 10px !important;
}

.formCompleteTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.17;
  letter-spacing: 0.6px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 100px;
}

@media screen and (min-width: 1040px) {
  .formCompleteTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .formCompleteTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .formCompleteTit {
    font-size: 4.4rem;
    font-size: 4.23077vw;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .formCompleteTit {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .formCompleteTit {
    font-size: 5.72917vw;
  }
}

.download {
  padding: 140px 20px 60px;
  text-align: center;
}

.downloadBtn {
  font-size: 2.8rem;
  font-size: 2.69231vw;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #000;
  text-decoration: underline;
}

@media screen and (min-width: 1040px) {
  .downloadBtn {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .downloadBtn {
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) {
  .downloadBtn {
    font-size: 4.4rem;
    font-size: 4.23077vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .downloadBtn {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .downloadBtn {
    font-size: 5.72917vw;
  }
}

.search {
  padding-top: 60px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .search {
    padding-top: 40px;
    margin-bottom: 20px;
  }
}

.search .inner {
  max-width: 760px;
  margin: 0 auto;
  padding: 0 20px;
}

.searchTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: solid 1px #eaeaea;
  margin-bottom: 10px;
}

@media screen and (min-width: 1040px) {
  .searchTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .searchTit {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .searchTit {
    font-size: 4.2rem;
    font-size: 4.03846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .searchTit {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .searchTit {
    font-size: 5.46875vw;
  }
}

.searchNum {
  font-size: 1.4rem;
  font-size: 1.34615vw;
  letter-spacing: 0.35px;
  color: #333;
  margin-bottom: 50px;
}

@media screen and (min-width: 1040px) {
  .searchNum {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .searchNum {
    font-size: 1.82292vw;
  }
}

@media screen and (max-width: 767px) {
  .searchNum {
    font-size: 2.8rem;
    font-size: 2.69231vw;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .searchNum {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .searchNum {
    font-size: 3.64583vw;
  }
}

.searchNum span {
  color: #a81e2d;
}

.searchResults {
  margin-bottom: 30px;
}

.searchResultsItem {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .searchResultsItem {
    margin-bottom: 30px;
  }
}

.searchResultsTit {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #a81e2d;
}

@media screen and (min-width: 1040px) {
  .searchResultsTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .searchResultsTit {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  .searchResultsTit {
    font-size: 3.2rem;
    font-size: 3.07692vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .searchResultsTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .searchResultsTit {
    font-size: 4.16667vw;
  }
}

.searchResultsTxt {
  font-size: 1.8rem;
  font-size: 1.73077vw;
  line-height: 1.33;
  letter-spacing: 0.45px;
  color: #555;
  padding-top: 15px;
  word-break: break-all;
}

@media screen and (min-width: 1040px) {
  .searchResultsTxt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .searchResultsTxt {
    font-size: 2.34375vw;
  }
}

@media screen and (max-width: 767px) {
  .searchResultsTxt {
    font-size: 2.8rem;
    font-size: 2.69231vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .searchResultsTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .searchResultsTxt {
    font-size: 3.64583vw;
  }
}

.searchPager {
  max-width: 210px;
  margin: auto;
}

.searchPager ol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchPager ol li {
  flex-basis: auto;
  font-size: 2.4rem;
  font-size: 2.30769vw;
  color: #a81e2d;
}

@media screen and (min-width: 1040px) {
  .searchPager ol li {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .searchPager ol li {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  .searchPager ol li {
    font-size: 5.2rem;
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  .searchPager ol li {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .searchPager ol li {
    font-size: 6.77083vw;
  }
}

.searchPager ol li a {
  color: #c7c7c7;
}

.searchPager ol li.current {
  text-decoration: underline;
}

.searchPager ol li.prev a, .searchPager ol li.next a {
  display: block;
  color: #fff;
  width: 10px;
  height: 10px;
  position: relative;
}

.searchPager ol li.prev a::after, .searchPager ol li.next a::after {
  content: '';
  border: solid 1px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}

.searchPager ol li.prev.hide, .searchPager ol li.next.hide {
  opacity: 0;
  visibility: hidden;
}

.searchPager ol li.prev a::after {
  border-color: transparent transparent #a81e2d #a81e2d;
}

.searchPager ol li.next a::after {
  border-color: #a81e2d #a81e2d transparent transparent;
}

.searchBackBtn {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 60px auto 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .searchBackBtn {
    font-size: 2.2rem;
    width: 100%;
    margin: 0 auto;
    line-height: 1.68;
    letter-spacing: 1.14px;
    margin: 60px auto 20px;
    padding: 5px 25px;
  }
}

#errorTop .errorLayout {
  display: block;
}

#errorTop .errorLayout .inner {
  max-width: 880px;
  padding: 60px 20px 0;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner {
    padding-bottom: 60px;
  }
}

#errorTop .errorLayout .inner h2 {
  font-size: 4rem;
  font-size: 3.84615vw;
  line-height: 1.23;
  letter-spacing: 1px;
  text-align: center;
  color: #a81e2d;
  padding-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  #errorTop .errorLayout .inner h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner h2 {
    font-size: 5.20833vw;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner h2 {
    font-size: 7.2rem;
    font-size: 6.92308vw;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #errorTop .errorLayout .inner h2 {
    font-size: 7.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #errorTop .errorLayout .inner h2 {
    font-size: 9.375vw;
  }
}

#errorTop .errorLayout .inner p {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
}

@media screen and (min-width: 1040px) {
  #errorTop .errorLayout .inner p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner p {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #errorTop .errorLayout .inner p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #errorTop .errorLayout .inner p {
    font-size: 4.6875vw;
  }
}

#errorTop .errorLayout .inner .backToBtn {
  display: block;
  text-align: center;
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner .backToBtn {
    padding-top: 40px;
  }
}

#errorTop .errorLayout .inner .backToBtn a {
  display: inline-block;
  background-color: #b21d2e;
  padding: 15px;
  width: 100%;
  max-width: 255px;
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 1040px) {
  #errorTop .errorLayout .inner .backToBtn a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner .backToBtn a {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  #errorTop .errorLayout .inner .backToBtn a {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #errorTop .errorLayout .inner .backToBtn a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #errorTop .errorLayout .inner .backToBtn a {
    font-size: 4.16667vw;
  }
}

#books .books,
#synopsisSingle .books {
  max-width: 880px;
  width: 100%;
  padding: 0 20px;
  margin: 60px auto 0;
  background-color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books,
  #synopsisSingle .books {
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  #books .books,
  #synopsisSingle .books {
    position: relative;
    z-index: 2;
    padding-bottom: 30px;
    margin: 60px auto 0;
    overflow: hidden;
  }
}

#books .books .booksNote,
#synopsisSingle .books .booksNote {
  font-size: 1.4rem;
  line-height: 1.75;
  letter-spacing: 0.4px;
  color: #333;
  margin: 0 auto 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #books .books .booksNote,
  #synopsisSingle .books .booksNote {
    font-size: 1.3rem;
    line-height: 1.43;
    letter-spacing: 0.7px;
    margin: 0 auto 30px;
  }
}

#books .books .booksList,
#synopsisSingle .books .booksList {
  max-width: 100%;
  width: 100%;
  margin: 0 auto 15px;
}

@media screen and (max-width: 767px) {
  #books .books .booksList,
  #synopsisSingle .books .booksList {
    margin: 0 auto 15px;
  }
}

#books .books .booksList .flex,
#synopsisSingle .books .booksList .flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .flex,
  #synopsisSingle .books .booksList .flex {
    display: block;
  }
}

#books .books .booksList .heade,
#synopsisSingle .books .booksList .heade {
  background-color: #f4f5f5;
}

#books .books .booksList .heade .booksListHead,
#synopsisSingle .books .booksList .heade .booksListHead {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: 0.35px;
  color: #000;
}

#books .books .booksList .heade .booksListHead.num,
#synopsisSingle .books .booksList .heade .booksListHead.num {
  max-width: 52px;
  width: 100%;
  padding: 14px 0 13px 20px;
}

#books .books .booksList .heade .booksListHead.title,
#synopsisSingle .books .booksList .heade .booksListHead.title {
  max-width: 33.691%;
  width: 100%;
  padding: 14px 0 13px 20px;
}

#books .books .booksList .heade .booksListHead.author,
#synopsisSingle .books .booksList .heade .booksListHead.author {
  max-width: 28.572%;
  width: 100%;
  padding: 14px 0 13px 40px;
}

#books .books .booksList .heade .booksListHead.publish,
#synopsisSingle .books .booksList .heade .booksListHead.publish {
  padding: 14px 10px 13px 40px;
}

#books .books .booksList .booksListBody .booksBody,
#synopsisSingle .books .booksList .booksListBody .booksBody {
  border-bottom: dashed 1px #c7c7c7;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .booksBody,
  #synopsisSingle .books .booksList .booksListBody .booksBody {
    display: block;
    padding: 15px 0;
  }
  #books .books .booksList .booksListBody .booksBody:first-of-type,
  #synopsisSingle .books .booksList .booksListBody .booksBody:first-of-type {
    border-top: dashed 1px #c7c7c7;
  }
}

#books .books .booksList .booksListBody a,
#synopsisSingle .books .booksList .booksListBody a {
  transition: opacity .3s;
}

#books .books .booksList .booksListBody a:hover,
#synopsisSingle .books .booksList .booksListBody a:hover {
  opacity: .7;
}

#books .books .booksList .booksListBody .booksListCon,
#synopsisSingle .books .booksList .booksListBody .booksListCon {
  margin: 0;
  padding: 0;
}

#books .books .booksList .booksListBody .booksListCon.isActive a,
#synopsisSingle .books .booksList .booksListBody .booksListCon.isActive a {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .booksListCon,
  #synopsisSingle .books .booksList .booksListBody .booksListCon {
    display: block;
  }
}

#books .books .booksList .booksListBody .num,
#synopsisSingle .books .booksList .booksListBody .num {
  max-width: 52px;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: #000;
  padding: 13px 0 13px 20px;
  word-break: break-all;
}

#books .books .booksList .booksListBody .num a,
#synopsisSingle .books .booksList .booksListBody .num a {
  display: block;
  color: inherit;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .num,
  #synopsisSingle .books .booksList .booksListBody .num {
    max-width: 100%;
    width: 100%;
    letter-spacing: 0.35px;
    padding: 0;
  }
  #books .books .booksList .booksListBody .num a,
  #synopsisSingle .books .booksList .booksListBody .num a {
    padding: 0;
  }
  #books .books .booksList .booksListBody .num a::before,
  #synopsisSingle .books .booksList .booksListBody .num a::before {
    display: inline-block;
  }
}

#books .books .booksList .booksListBody .title,
#synopsisSingle .books .booksList .booksListBody .title {
  max-width: 33.691%;
  width: 100%;
  padding: 13px 0 13px 20px;
}

#books .books .booksList .booksListBody .title a,
#synopsisSingle .books .booksList .booksListBody .title a {
  display: block;
  color: inherit;
}

#books .books .booksList .booksListBody .title a em,
#synopsisSingle .books .booksList .booksListBody .title a em {
  font-style: italic;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .title,
  #synopsisSingle .books .booksList .booksListBody .title {
    max-width: 100%;
    width: auto;
    padding: 0;
  }
  #books .books .booksList .booksListBody .title a,
  #synopsisSingle .books .booksList .booksListBody .title a {
    padding: 0;
  }
}

#books .books .booksList .booksListBody .cat,
#synopsisSingle .books .booksList .booksListBody .cat {
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: #b21d2e;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .cat,
  #synopsisSingle .books .booksList .booksListBody .cat {
    letter-spacing: .35px;
    padding: 0;
  }
}

#books .books .booksList .booksListBody .tit,
#synopsisSingle .books .booksList .booksListBody .tit {
  display: block;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: 0.32px;
  color: #000;
}

#books .books .booksList .booksListBody .tit em,
#synopsisSingle .books .booksList .booksListBody .tit em {
  font-style: italic;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .tit,
  #synopsisSingle .books .booksList .booksListBody .tit {
    font-size: 1.9rem;
    line-height: 1.26;
    letter-spacing: .38px;
    padding: 8px 0 0;
  }
}

#books .books .booksList .booksListBody .subTit,
#synopsisSingle .books .booksList .booksListBody .subTit {
  font-size: 1.4rem;
  line-height: 1.29;
  letter-spacing: 0.28px;
  color: #666;
  margin-top: 8px;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .subTit,
  #synopsisSingle .books .booksList .booksListBody .subTit {
    line-height: 1.25;
    letter-spacing: .35px;
    padding: 0;
  }
}

#books .books .booksList .booksListBody .author,
#synopsisSingle .books .booksList .booksListBody .author {
  max-width: 28.572%;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: 0.4px;
  color: #000;
  padding: 13px 0 13px 40px;
}

#books .books .booksList .booksListBody .author a,
#synopsisSingle .books .booksList .booksListBody .author a {
  display: block;
  color: inherit;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .author,
  #synopsisSingle .books .booksList .booksListBody .author {
    max-width: 100%;
    width: auto;
    line-height: 1.09;
    letter-spacing: .24px;
    clear: both;
    padding: 8px 0 0;
  }
  #books .books .booksList .booksListBody .author a,
  #synopsisSingle .books .booksList .booksListBody .author a {
    padding: 8px 0 0;
  }
}

#books .books .booksList .booksListBody .publish,
#synopsisSingle .books .booksList .booksListBody .publish {
  flex-grow: 1;
  font-size: 1.4rem;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #000;
  padding: 13px 10px 13px 40px;
}

#books .books .booksList .booksListBody .publish a,
#synopsisSingle .books .booksList .booksListBody .publish a {
  display: block;
  color: inherit;
}

@media screen and (max-width: 767px) {
  #books .books .booksList .booksListBody .publish,
  #synopsisSingle .books .booksList .booksListBody .publish {
    width: auto;
    line-height: 1.25;
    letter-spacing: .35px;
    color: #666;
    padding: 8px 0 0;
  }
  #books .books .booksList .booksListBody .publish a,
  #synopsisSingle .books .booksList .booksListBody .publish a {
    padding: 8px 0 0;
  }
}

#books .books .categoryColumn,
#synopsisSingle .books .categoryColumn {
  max-width: 880px;
  margin: 0 auto 45px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn,
  #synopsisSingle .books .categoryColumn {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn,
  #synopsisSingle .books .categoryColumn {
    position: relative;
    margin-bottom: 50px;
  }
}

#books .books .categoryColumnCurrent,
#synopsisSingle .books .categoryColumnCurrent {
  font-size: 2rem;
  line-height: 1.7;
  letter-spacing: 0.5px;
  color: #a81e2d;
  display: block;
  background-color: #fff;
  padding: 2.2% 5.8%;
  border: solid 1px #a81e2d;
  position: relative;
  z-index: 2;
}

#books .books .categoryColumnCurrent::after,
#synopsisSingle .books .categoryColumnCurrent::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 0 auto 0 0;
  background-image: url(/images/synopsis/icon_accordion.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 6%;
  top: 42%;
  transition: .4s;
}

#books .books .categoryColumnCurrent.open::after,
#synopsisSingle .books .categoryColumnCurrent.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

#books .books .categoryColumn ul,
#synopsisSingle .books .categoryColumn ul {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn ul,
  #synopsisSingle .books .categoryColumn ul {
    display: block;
    width: 100%;
    position: absolute;
    margin-top: -1px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
    border-top: solid 1px #c7c7c7;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn ul,
  #synopsisSingle .books .categoryColumn ul {
    display: block;
    width: 100%;
    position: absolute;
    margin-top: -1px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
    border-top: solid 1px #c7c7c7;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

#books .books .categoryColumn ul.open,
#synopsisSingle .books .categoryColumn ul.open {
  visibility: visible;
  opacity: 1;
  transition: .4s;
  transform: translateY(0);
}

#books .books .categoryColumn ul li,
#synopsisSingle .books .categoryColumn ul li {
  margin-right: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn ul li,
  #synopsisSingle .books .categoryColumn ul li {
    margin-right: 0;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn ul li,
  #synopsisSingle .books .categoryColumn ul li {
    margin-right: 0;
    background-color: #fff;
  }
}

#books .books .categoryColumn ul li:last-child,
#synopsisSingle .books .categoryColumn ul li:last-child {
  margin-right: 0;
}

#books .books .categoryColumn ul li a,
#synopsisSingle .books .categoryColumn ul li a {
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0;
  text-align: center;
  color: #666666;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn ul li a,
  #synopsisSingle .books .categoryColumn ul li a {
    display: block;
    background-color: #fff;
    padding: 4.4% 5.8%;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn ul li a,
  #synopsisSingle .books .categoryColumn ul li a {
    display: block;
    background-color: #fff;
    padding: 4.4% 5.8%;
    text-align: left;
  }
}

#books .books .categoryColumn ul li a:hover,
#synopsisSingle .books .categoryColumn ul li a:hover {
  color: #a81e2d;
  padding-bottom: 5px;
  border-bottom: solid 1px #a81e2d;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn ul li a:hover,
  #synopsisSingle .books .categoryColumn ul li a:hover {
    padding: 4.4% 5.8%;
    border-bottom: none;
    color: #000;
    opacity: 1;
    background-color: #f4f5f5;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn ul li a:hover,
  #synopsisSingle .books .categoryColumn ul li a:hover {
    padding: 4.4% 5.8%;
    border-bottom: none;
    color: #000;
    opacity: 1;
    background-color: #f4f5f5;
  }
}

#books .books .categoryColumn ul li.active a,
#synopsisSingle .books .categoryColumn ul li.active a {
  color: #a81e2d;
  padding-bottom: 5px;
  border-bottom: solid 1px #a81e2d;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #books .books .categoryColumn ul li.active,
  #synopsisSingle .books .categoryColumn ul li.active {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #books .books .categoryColumn ul li.active,
  #synopsisSingle .books .categoryColumn ul li.active {
    display: none;
  }
}

#books .books .postNum,
#synopsisSingle .books .postNum {
  display: none;
}

#books .books .synopsisColumnTitle,
#synopsisSingle .books .synopsisColumnTitle {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.68px;
  text-align: left;
  color: #b21d2e;
  margin-bottom: 15px;
  visibility: hidden;
  position: absolute;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisColumnTitle,
  #synopsisSingle .books .synopsisColumnTitle {
    font-size: 2.3rem;
    line-height: 1.07;
    margin-bottom: 4%;
  }
}

#books .books .synopsisColumnTitle + .synopsisCardLayout,
#synopsisSingle .books .synopsisColumnTitle + .synopsisCardLayout {
  visibility: hidden;
  position: absolute;
}

#books .books .synopsisColumnTitle.active,
#synopsisSingle .books .synopsisColumnTitle.active {
  visibility: visible;
  position: relative;
}

#books .books .synopsisColumnTitle.active + .synopsisCardLayout,
#synopsisSingle .books .synopsisColumnTitle.active + .synopsisCardLayout {
  visibility: visible;
  position: relative;
}

#books .books .synopsisBtn,
#synopsisSingle .books .synopsisBtn {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  background-color: #b21d2e;
  padding: 15px 0;
  display: block;
  width: 250px;
  margin: 50px auto 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisBtn,
  #synopsisSingle .books .synopsisBtn {
    font-size: 2.2rem;
    width: 100%;
    margin: 0 auto;
    line-height: 1.68;
    letter-spacing: 1.14px;
    margin: 60px auto 20px;
    padding: 5px 25px;
  }
}

#books .books .synopsisArticle,
#synopsisSingle .books .synopsisArticle {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle,
  #synopsisSingle .books .synopsisArticle {
    display: block;
  }
}

#books .books .synopsisArticle .synopsisArticleRightColumn,
#synopsisSingle .books .synopsisArticle .synopsisArticleRightColumn {
  margin-right: 60px;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleRightColumn,
  #synopsisSingle .books .synopsisArticle .synopsisArticleRightColumn {
    width: 60%;
    margin: 0 auto;
  }
}

#books .books .synopsisArticle .synopsisArticleRightColumn .synopsisArticleVisual,
#synopsisSingle .books .synopsisArticle .synopsisArticleRightColumn .synopsisArticleVisual {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

#books .books .synopsisArticle .synopsisArticleRightColumn .txtBox,
#synopsisSingle .books .synopsisArticle .synopsisArticleRightColumn .txtBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleRightColumn .txtBox,
  #synopsisSingle .books .synopsisArticle .synopsisArticleRightColumn .txtBox {
    margin-bottom: 20%;
  }
}

#books .books .synopsisArticle .synopsisCategory,
#synopsisSingle .books .synopsisArticle .synopsisCategory {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: left;
  color: #a81e2d;
  padding: 5px 12px;
  border: solid 1px #A81E2D;
  background-color: #ffffff;
  opacity: 0;
}

#books .books .synopsisArticle .synopsisCategory::before,
#synopsisSingle .books .synopsisArticle .synopsisCategory::before {
  content: "";
  display: inline-block;
  background-image: url(/images/synopsis/icon_book.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 13px;
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisCategory::before,
  #synopsisSingle .books .synopsisArticle .synopsisCategory::before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisCategory,
  #synopsisSingle .books .synopsisArticle .synopsisCategory {
    font-size: 1.6rem;
    line-height: 0.38;
    letter-spacing: 0.8px;
    padding: 3% 6% 2%;
  }
}

#books .books .synopsisArticle .synopsisCategory.show,
#synopsisSingle .books .synopsisArticle .synopsisCategory.show {
  opacity: 1;
}

#books .books .synopsisArticle .synopsisId,
#synopsisSingle .books .synopsisArticle .synopsisId {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  opacity: 0;
  margin: 0 0 10px;
}

#books .books .synopsisArticle .synopsisId.show,
#synopsisSingle .books .synopsisArticle .synopsisId.show {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisId,
  #synopsisSingle .books .synopsisArticle .synopsisId {
    font-size: 1.5rem;
    line-height: 0.38;
    letter-spacing: 0.4px;
    margin: 0 0 15px;
  }
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn {
    width: 100%;
  }
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory {
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.5px;
  text-align: left;
  color: #a81e2d;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleCategory {
    line-height: 1.42;
    letter-spacing: 0.95px;
  }
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle {
  font-size: 3.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle em,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle em {
  font-style: italic;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTitle {
    line-height: 1.29;
    letter-spacing: 1.05px;
  }
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticlePublication {
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.7px;
    margin-bottom: 10%;
  }
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor {
  font-size: 2.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleAuthor {
    font-size: 2rem;
    line-height: 1.5;
    letter-spacing: 0.7px;
  }
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  margin-bottom: 20px;
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p {
  margin-bottom: 15px;
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p:last-of-type,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt p:last-of-type {
  margin-bottom: 0;
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt em,
#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt i,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt em,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt i {
  font-style: italic;
}

#books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt b,
#synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt b {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #books .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt,
  #synopsisSingle .books .synopsisArticle .synopsisArticleLeftColumn .synopsisArticleTxt {
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0.9px;
  }
}

#books .errorLayout,
#synopsisSingle .errorLayout {
  display: block;
}

#books .errorLayout .inner,
#synopsisSingle .errorLayout .inner {
  max-width: 880px;
  padding: 60px 20px 0;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner,
  #synopsisSingle .errorLayout .inner {
    padding-bottom: 60px;
  }
}

#books .errorLayout .inner h2,
#synopsisSingle .errorLayout .inner h2 {
  font-size: 4rem;
  font-size: 3.84615vw;
  line-height: 1.23;
  letter-spacing: 1px;
  text-align: center;
  color: #a81e2d;
  padding-bottom: 40px;
}

@media screen and (min-width: 1040px) {
  #books .errorLayout .inner h2,
  #synopsisSingle .errorLayout .inner h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner h2,
  #synopsisSingle .errorLayout .inner h2 {
    font-size: 5.20833vw;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner h2,
  #synopsisSingle .errorLayout .inner h2 {
    font-size: 7.2rem;
    font-size: 6.92308vw;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #books .errorLayout .inner h2,
  #synopsisSingle .errorLayout .inner h2 {
    font-size: 7.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #books .errorLayout .inner h2,
  #synopsisSingle .errorLayout .inner h2 {
    font-size: 9.375vw;
  }
}

#books .errorLayout .inner p,
#synopsisSingle .errorLayout .inner p {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
}

@media screen and (min-width: 1040px) {
  #books .errorLayout .inner p,
  #synopsisSingle .errorLayout .inner p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner p,
  #synopsisSingle .errorLayout .inner p {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner p,
  #synopsisSingle .errorLayout .inner p {
    font-size: 3.6rem;
    font-size: 3.46154vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #books .errorLayout .inner p,
  #synopsisSingle .errorLayout .inner p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #books .errorLayout .inner p,
  #synopsisSingle .errorLayout .inner p {
    font-size: 4.6875vw;
  }
}

#books .errorLayout .inner .backToBtn,
#synopsisSingle .errorLayout .inner .backToBtn {
  display: block;
  text-align: center;
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner .backToBtn,
  #synopsisSingle .errorLayout .inner .backToBtn {
    padding-top: 40px;
  }
}

#books .errorLayout .inner .backToBtn a,
#synopsisSingle .errorLayout .inner .backToBtn a {
  display: inline-block;
  background-color: #b21d2e;
  padding: 15px;
  width: 100%;
  max-width: 255px;
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.55;
  letter-spacing: 1.2px;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 1040px) {
  #books .errorLayout .inner .backToBtn a,
  #synopsisSingle .errorLayout .inner .backToBtn a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner .backToBtn a,
  #synopsisSingle .errorLayout .inner .backToBtn a {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  #books .errorLayout .inner .backToBtn a,
  #synopsisSingle .errorLayout .inner .backToBtn a {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    padding: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #books .errorLayout .inner .backToBtn a,
  #synopsisSingle .errorLayout .inner .backToBtn a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #books .errorLayout .inner .backToBtn a,
  #synopsisSingle .errorLayout .inner .backToBtn a {
    font-size: 4.16667vw;
  }
}

#books .newsPager,
#synopsisSingle .newsPager {
  max-width: 750px;
  width: 100%;
  padding: 0 20px;
  margin: 60px auto 100px;
}

@media screen and (max-width: 767px) {
  #books .newsPager,
  #synopsisSingle .newsPager {
    position: relative;
    z-index: 1;
    max-width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    padding: 0 75px 0 0;
    margin: 0 auto 80px;
  }
  #books .newsPager dl,
  #synopsisSingle .newsPager dl {
    max-width: 75px;
    width: 100%;
  }
  #books .newsPager dl .pageAccordionArea,
  #synopsisSingle .newsPager dl .pageAccordionArea {
    position: relative;
  }
  #books .newsPager dl #pageAccordionBox,
  #synopsisSingle .newsPager dl #pageAccordionBox {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 10s, visibility 10s, transform .5s;
    transform: translateY(-100%);
  }
  #books .newsPager dl #pageAccordionBox.open,
  #synopsisSingle .newsPager dl #pageAccordionBox.open {
    visibility: visible;
    opacity: 1;
    transition: .4s;
    transform: translateY(0);
  }
  #books .newsPager .maxPage,
  #synopsisSingle .newsPager .maxPage {
    font-size: 2.3rem;
    line-height: 1;
    letter-spacing: 0.46px;
    color: #c7c7c7;
    margin: 0 0 0 10px;
  }
}

#books .newsPager ol,
#synopsisSingle .newsPager ol {
  flex-wrap: wrap;
  justify-content: flex-start;
}

#books .newsPager ol.center,
#synopsisSingle .newsPager ol.center {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #books .newsPager ol,
  #synopsisSingle .newsPager ol {
    position: static;
    display: block;
    width: 100%;
    max-height: 215px;
    overflow-y: scroll;
    border-right: solid 1px #a81e2d;
    border-left: solid 1px #a81e2d;
    border-bottom: solid 1px #a81e2d;
  }
}

#books .newsPager ol li,
#synopsisSingle .newsPager ol li {
  width: 35px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #books .newsPager ol li,
  #synopsisSingle .newsPager ol li {
    width: auto;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  #books .newsPager ol li a,
  #synopsisSingle .newsPager ol li a {
    display: block;
    font-size: 2.3rem;
    line-height: 1;
    letter-spacing: .572px;
    padding: 10px 15px 10px;
  }
  #books .newsPager ol li a:hover,
  #synopsisSingle .newsPager ol li a:hover {
    color: #000;
    background-color: #f4f5f5;
  }
}

#books .newsPager ol li.current a,
#synopsisSingle .newsPager ol li.current a {
  color: #b21d2e;
}

@media screen and (max-width: 767px) {
  #books .newsPager ol li.current,
  #synopsisSingle .newsPager ol li.current {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #books .newsPager .next,
  #synopsisSingle .newsPager .next {
    position: absolute;
    width: 7px;
    top: 15px;
    left: calc(100% - 27px);
    padding: 0;
  }
  #books .newsPager .next::after,
  #synopsisSingle .newsPager .next::after {
    display: none;
  }
  #books .newsPager .next a,
  #synopsisSingle .newsPager .next a {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #books .newsPager .prev,
  #synopsisSingle .newsPager .prev {
    position: absolute;
    width: 7px;
    top: 15px;
    left: calc(100% - 57px);
    padding: 0;
  }
  #books .newsPager .prev::after,
  #synopsisSingle .newsPager .prev::after {
    display: none;
  }
  #books .newsPager .prev a,
  #synopsisSingle .newsPager .prev a {
    display: block;
  }
}

#books .newsPager .currentPage,
#synopsisSingle .newsPager .currentPage {
  cursor: pointer;
  font-size: 2.3rem;
  line-height: 1;
  letter-spacing: .572px;
  color: #a81e2d;
  display: block;
  background-color: #fff;
  padding: 10px 15px 10px;
  border: solid 1px #a81e2d;
  position: relative;
  z-index: 2;
}

#books .newsPager .currentPage::after,
#synopsisSingle .newsPager .currentPage::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 6px;
  margin: 0 auto 0 0;
  background-image: url(/images/synopsis/icon_accordion.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 14%;
  top: 44%;
  transition: .4s;
}

#books .newsPager .currentPage.open,
#synopsisSingle .newsPager .currentPage.open {
  border-bottom: solid 1px #c7c7c7;
}

#books .newsPager .currentPage.open::after,
#synopsisSingle .newsPager .currentPage.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

#privacy .privacy {
  max-width: 880px;
  padding: 60px 20px 100px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #privacy .privacy {
    padding: 40px 20px 70px;
  }
}

#privacy .privacy .privacyLead {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  margin: 0 0 60px;
}

@media screen and (min-width: 1040px) {
  #privacy .privacy .privacyLead {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyLead {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyLead {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    letter-spacing: 0.2px;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #privacy .privacy .privacyLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacy .privacy .privacyLead {
    font-size: 4.16667vw;
  }
}

#privacy .privacy .privacyList .privacyListTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #000;
  margin: 0 0 18px;
}

@media screen and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListTit {
    font-size: 3.125vw;
  }
}

#privacy .privacy .privacyList .privacyListTit::before {
  content: "";
  display: block;
  width: 70px;
  border-bottom: solid 1px #b21d2e;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListTit {
    font-size: 4.2rem;
    font-size: 4.03846vw;
    letter-spacing: 0.3px;
    margin: 0 0 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListTit {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListTit {
    font-size: 5.46875vw;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListTit::before {
    margin: 0 0 18px;
  }
}

#privacy .privacy .privacyList .privacyListCon {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  margin: 0 0 60px;
}

@media screen and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListCon {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon {
    font-size: 2.08333vw;
  }
}

#privacy .privacy .privacyList .privacyListCon:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    letter-spacing: 0.2px;
    margin: 0 0 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListCon {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon {
    font-size: 4.16667vw;
  }
}

#privacy .privacy .privacyList .privacyListCon p {
  margin: 0 0 14px;
}

#privacy .privacy .privacyList .privacyListCon p:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon p {
    margin: 0 0 10px;
  }
}

#privacy .privacy .privacyList .privacyListCon ul {
  margin: 0 0 10px;
}

#privacy .privacy .privacyList .privacyListCon ul li {
  position: relative;
  display: flex;
  padding-left: 5px;
  margin: 0 0 10px;
}

#privacy .privacy .privacyList .privacyListCon ul li:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon ul li {
    padding-left: 0;
    margin: 0 0 10px;
  }
}

#privacy .privacy .privacyList .privacyListCon ul li::before {
  content: "・";
  flex-shrink: 0;
  font-weight: bold;
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.1;
  height: 0;
}

@media screen and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    line-height: 1;
  }
}

@media screen and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    font-size: 5rem;
    font-size: 4.80769vw;
    margin: 0 -2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacy .privacy .privacyList .privacyListCon ul li::before {
    font-size: 6.51042vw;
  }
}

@media screen and (max-width: 767px) {
  #terms .keyVisualTit {
    line-height: 1.2;
  }
}

#terms .terms {
  max-width: 880px;
  padding: 60px 20px 100px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #terms .terms {
    padding: 40px 20px 70px;
  }
}

#terms .terms .termsLead {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  margin: 0 0 60px;
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsLead {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsLead {
    font-size: 2.08333vw;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsLead {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    letter-spacing: 0.2px;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #terms .terms .termsLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #terms .terms .termsLead {
    font-size: 4.16667vw;
  }
}

#terms .terms .termsList .termsListTit {
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: #000;
  margin: 0 0 18px;
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsList .termsListTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListTit {
    font-size: 3.125vw;
  }
}

#terms .terms .termsList .termsListTit::before {
  content: "";
  display: block;
  width: 70px;
  border-bottom: solid 1px #b21d2e;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListTit {
    font-size: 4.2rem;
    font-size: 4.03846vw;
    letter-spacing: 0.3px;
    margin: 0 0 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #terms .terms .termsList .termsListTit {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #terms .terms .termsList .termsListTit {
    font-size: 5.46875vw;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListTit::before {
    margin: 0 0 18px;
  }
}

#terms .terms .termsList .termsListCon {
  font-size: 1.6rem;
  font-size: 1.53846vw;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
  margin: 0 0 60px;
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon {
    font-size: 2.08333vw;
  }
}

#terms .terms .termsList .termsListCon:last-of-type {
  margin-bottom: 0;
}

#terms .terms .termsList .termsListCon dt {
  font-size: 2rem;
  font-size: 1.92308vw;
  line-height: 1.8;
  letter-spacing: 0.5px;
  color: #000;
  margin: 0 0 12px;
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon dt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon dt {
    font-size: 2.60417vw;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon dt {
    font-size: 3.6rem;
    font-size: 3.46154vw;
    letter-spacing: 0.25px;
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon dt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #terms .terms .termsList .termsListCon dt {
    font-size: 4.6875vw;
  }
}

#terms .terms .termsList .termsListCon dd {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon dd {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon {
    font-size: 3.2rem;
    font-size: 3.07692vw;
    letter-spacing: 0.2px;
    margin: 0 0 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #terms .terms .termsList .termsListCon {
    font-size: 4.16667vw;
  }
}

#terms .terms .termsList .termsListCon p {
  margin: 0 0 14px;
}

#terms .terms .termsList .termsListCon p:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon p {
    margin: 0 0 10px;
  }
}

#terms .terms .termsList .termsListCon ul {
  margin: 0 0 10px;
}

#terms .terms .termsList .termsListCon ul li {
  position: relative;
  display: flex;
  padding-left: 5px;
  margin: 0 0 10px;
}

#terms .terms .termsList .termsListCon ul li:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ul li {
    padding-left: 0;
    margin: 0 0 10px;
  }
}

#terms .terms .termsList .termsListCon ul li::before {
  content: "・";
  flex-shrink: 0;
  font-weight: bold;
  font-size: 2.4rem;
  font-size: 2.30769vw;
  line-height: 1.1;
  height: 0;
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon ul li::before {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ul li::before {
    font-size: 3.125vw;
  }
}

@media screen and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon ul li::before {
    line-height: 1;
  }
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ul li::before {
    font-size: 5rem;
    font-size: 4.80769vw;
    margin: 0 -2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1040px) {
  #terms .terms .termsList .termsListCon ul li::before {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ul li::before {
    font-size: 6.51042vw;
  }
}

#terms .terms .termsList .termsListCon ol {
  padding: 0 0 0 10px;
  margin: 10px 0;
  counter-reset: number 0;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ol {
    padding: 0;
  }
}

#terms .terms .termsList .termsListCon ol li {
  position: relative;
  display: flex;
  margin: 0 0 10px;
}

#terms .terms .termsList .termsListCon ol li::before {
  counter-increment: number 1;
  content: counter(number) ". ";
  margin-right: 8px;
}

#terms .terms .termsList .termsListCon ol li:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #terms .terms .termsList .termsListCon ol li {
    margin: 0 0 10px;
  }
}

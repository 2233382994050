.keyVisualTitRecipient {
  @include mq(md) {
    left: 5%;
  }
}
.recipientInstitution {
  padding: 0 0 60px;
  @include mq(md) {
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
  }
  .btnBox {
    @include mq(md) {
      padding: 0 20px;
    }
  }
  .recipientInstitutionBtn {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.55;
    letter-spacing: 1.2px;
    text-align: center;
    color: #fff;
    background-color: #b21d2e;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: 0 auto;
    cursor: pointer;
    @include mq(md) {
      font-size: 2.2rem;
      width: 100%;
      margin: 0 auto;
      line-height: 1.68;
      letter-spacing: 1.14px;
      margin: 0 auto 20px;
      padding: 5px 25px;
    }
  }
  .followUpButton {
    img {
      width: 7rem;
      position: fixed;
      z-index: 1;
      right: 4%;
      bottom: 30vh;
      @include mq(md) {
        width: 5rem;
        right: 2%;
      }
      &.hide {
        opacity: 0;
        transition: .6s;
        visibility: hidden;
      }
    }
  }
  .categoryColumn {
    background-color: #f4f5f5;
    @include mq(md) {
      background-color: #fff;
    }
    .inner {
      max-width: 880px;
      margin: 0 auto;
      padding: 60px 20px 45px;
      @include mq(md) {
        padding: 60px 20px 0;
        position: relative;
      }
    }
    .categoryArea {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.68px;
      color: #b21d2e;
      margin-bottom: 40px;
      .number {
        font-size: 2.4rem;
        letter-spacing: 1.44px;
        margin-left: 5px;
      }
    }
    .categoryNav {
      overflow: hidden;
      @include mq(md) {
        position: absolute;
        top: 120%;
        left: calc(0 - 20px);
        width: calc(100% - 40px);
      }
    }
    .activeSp {
      border: solid 1px #666666;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: 2.16px;
      color: #666666;
      padding: 17px 20px;
      position: relative;
      z-index: 2;
      background-color: #fff;
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        margin: 0 auto 0 0;
        background-image: url(/images/recipient-Institution/icon_accordion.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 6%;
        top: 42%;
        transition: .4s;
      }
      &.open {
        border-bottom: solid 1px #c7c7c7;
        &::after {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
    .selectBtn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .nationalFlag {
        max-width: 38px;
        margin-right: 15px;
        flex: 1;
        @include mq(md) {
          max-width: 15vw;
          margin-right: 0;
        }
      }
      .nationaltxt {
        max-width: 98px;
        flex: 1;
        font-size: 1.6rem;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.5;
        color: #231815;
        text-decoration: underline;
        word-break: break-all;
        @include mq(md) {
          max-width: 75vw;
        }
      }
      .number {
        display: inline-block;
        color: #a81e2d;
        text-decoration: none;
        margin-left: 3px;
      }
    }
    .categoryMenu {
      display: flex;
      flex-wrap: wrap;
      @include mq(md) {
        background-color: #fff;
        border-left: solid 1px #666666;
        border-right: solid 1px #666666;
        border-bottom: solid 1px #666666;
        opacity: 0;
        visibility: hidden;
        transition: opacity 10s, visibility 10s, transform .5s;
        transform: translateY(-100%);
        width: 100%;
        position: relative;
        z-index: 1;
        height: 31rem;
        overflow-y: scroll;
      }
      &.open {
        @include mq(md) {
          width: 100%;
          visibility: visible;
          opacity: 1;
          transition: .4s;
          transform: translateY(0);
        }
      }
      .categoryList {
        width: 20%;
        margin-bottom: 35px;
        @include mq(md) {
          width: 100%;
          background-color: #fff;
          margin: 0;
        }
        a {
          display: flex;
          flex-wrap: wrap;
          @include mq(md) {
            padding: 17px 20px;
          }
          &:hover {
            @include mq(md) {
              background-color: #f4f5f5;
            }
          }
        }
        .nationalFlag {
          max-width: 38px;
          margin-right: 15px;
          flex: 1;
          @include mq(md) {
            max-width: 15vw;
            margin-right: 0;
          }
        }
        .nationaltxt {
          max-width: 98px;
          flex: 1;
          font-size: 1.6rem;
          line-height: 1.5;
          color: #231815;
          text-decoration: underline;
          word-break: keep-all;
          @include mq(md) {
            max-width: 75vw;
          }
        }
        .number {
          display: inline-block;
          color: #a81e2d;
          text-decoration: none;
          margin-left: 3px;
        }
      }
      .childCategoryList {
        width: 12%;
        margin-right: 15px;
        margin-bottom: 35px;
        @include mq(md) {
          width: 100%;
          background-color: #fff;
          margin: 0;
        }
        a {
          display: flex;
          flex-wrap: wrap;
          @include mq(md) {
            padding: 17px 20px;
          }
          &:hover {
            @include mq(md) {
              background-color: #f4f5f5;
            }
          }
        }
        .nationalFlag {
          max-width: 38px;
          margin-right: 15px;
          flex: 1;
          @include mq(md) {
            max-width: 15vw;
            margin-right: 0;
          }
        }
        .nationaltxt {
          max-width: 98px;
          flex: 1;
          font-size: 1.6rem;
          line-height: 1.5;
          color: #231815;
          text-decoration: underline;
          word-break: keep-all;
          @include mq(md) {
            max-width: 75vw;
          }
        }
        .number {
          display: inline-block;
          color: #a81e2d;
          text-decoration: none;
          margin-left: 3px;
        }
      }
    }
  }
  .recipientInstitutionBookList {
    max-width: 880px;
    margin: 0 auto 60px;
    padding: 0 20px 20px;
    @include mq(md) {
      position: relative;
      padding: 30px 0 20px;
    }
    .recipientInstitutionByNational {
      padding-top: 60px;
      @include mq(md) {
        padding-top: 40px;
        margin-top: 40px;
      }
    }
    .byNationalTit {
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: 0.5px;
      color: #231815;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;
      @include mq(md) {
        padding: 0 20px;
      }
      .number {
        color: #a81e2d;
        margin-left: 10px;
      }
      .byNationalFlag {
        width: 56px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
    .byNationalTable {
      @include mq(md) {
        border-top: solid 1px #c7c7c7;
        border-bottom: solid 1px #c7c7c7;
        padding-top: 20px;
      }
      thead {
        background-color: #f4f5f5;
      }
      tbody {
        tr {
          border-bottom: dashed 1px #c7c7c7;
          @include mq(md) {
            border: none;
          }
        }
      }
      tr {
        &:last-child {
          .byNationalYear {
            &::after {
              @include mq(md) {
                display: none;
              }
            }
          }
        }
      }
      .byNationalTh {
        padding: 15px 20px;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: #666666;
        @include mq(md) {
          display: block;
          padding: 0 12px 20px 20px;
          background-color: #f4f5f5;
          width: 30%;
        }
      }
      .byNationalTd {
        padding: 20px;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: #000;
        position: relative;
        word-break: keep-all;
        @include mq(md) {
          display: flex;
          flex-wrap: wrap;
          border-bottom: none;
          padding: 0;
          position: relative;
        }
        .byNationalTdTxt {
          @include mq(md) {
            width: 70%;
            padding: 0 20px 20px 12px;
          }
        }
        &.byNationalInstitution {
          width: 365px;
          @include mq(md) {
            width: auto;
          }
        }
        &.byNationalLibrary {
          width: 375px;
          @include mq(md) {
            width: auto;
          }
        }
        &.byNationalYear {
          &::after {
            right: 20%;
            left: auto;
            @include mq(md) {
              content: "";
              display: block;
              width: 90%;
              border-bottom: dashed 1px #c7c7c7;
              position: absolute;
              left: 5%;
              bottom: 0;
            }
          }
        }
        &:first-child {
          .byNationalTh,
          .byNationalTdTxt {
            @include mq(md) {
              padding-top: 20px;
            }
          }
        }
      }
    }
    .byNationalChildTit {
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: 0.5px;
      color: #231815;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 40px;
      margin-bottom: 20px;
      @include mq(md) {
        padding: 40px 20px 0;
      }
      .number {
        color: #a81e2d;
        margin-left: 10px;
      }
      .byNationalFlag {
        width: 56px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.donationVoice{
  margin-top: 110px;
  margin-bottom: 25px;

  @include mq(md){
    margin-top: 18.22vw;
    margin-bottom: 0;
  }

  .donationVoiceBoxItemTxt{
    font-size: 2rem;

    @include mq(md){
      @include fz_vw(36);
    }
  }

  .video{
    position: relative;
    cursor: pointer;
    transition: opacity .3s;

    &:hover{
      opacity: .7;
    }

    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      width: 25.064%;
      padding-bottom: 25.064%;
      background-image: url(/images/bookdonation/video_icon.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

#donation{
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    display: none;

    .modalBack {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, .9);
      z-index: 1;
      cursor: pointer;
    }

    .modalMovie {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 2;
      max-width: 1020px;
      width: 100%;
      padding: 0 20px;

      @include mq(max) {
        max-width: 1400px;
      }

      @include mq(md) {
        width: 100%;
      }

      .video {
        video {
          width: 100%;
        }
      }

      .modalMovieBtn {
        display: block;
        cursor: pointer;
        position: relative;
        width: 60px;
        height: 60px;
        margin: 0 0 15px auto;
        z-index: 10;
        background-color: rgba($color: #000, $alpha: 0);

        @include mq(md) {
          width: 40px;
          height: 16px;
          margin-bottom: 20px;
        }

        span {
          position: absolute;
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          transition: all .3s ease-out;

          &:nth-of-type(1) {
            bottom: auto;
            transform: rotate(45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            top: auto;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
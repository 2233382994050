@charset "utf-8";
/* ================================================================
ヘッダー
================================================================ */
header#header {
  background-color: #b21d2e;
  padding: 25px 0;
  position: relative;
  z-index: 999;
  @include mq(md) {
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 61px;
  }
  .spNavigations {
    display: none;
    &.open {
      transform: translateY(0%);
    }
    @include mq(md) {
      display: block;
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
      //overflow: scroll;
      font-size: 3rem;
      background: rgba(#a81e2d, .95);
      transform: translateY(-130%);
      z-index: 1;
      transition: 2s;
      transition: transform .8s, opacity .8s, -webkit-transform .8s;
      overflow: scroll;
    }
    .txtFlix {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      padding-top: 25px;
      padding-right: 25px;
      padding-left: 25px;
      padding-bottom: 100px;
      p {
        @include fz_vw(21);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: 0.19px;
        text-align: left;
        color: #ffffff;
        margin: 0 auto 0 0;
      }
      .snsF {
        width: 34px;
          
        &.xIcon{
          width: 26px;
          margin-bottom: 3px;
        }
        @include mq(md) {
            margin-left: 15px;
        }
      }
    }
    ul {
      padding-top: 30px;
      li {
        padding: 0 20px;
        margin-bottom: 12px;
          &.spAnker {
            display: block;
          }
          &.pcAnker {
              display: none;
            }
        a,
        span {
          @include fz_vw(14);
          line-height: 1;
          letter-spacing: 2.17px;
          text-align: left;
          color: #ffffff;
          display: block;
          padding: 0 10px;
          @include mq(md) {
            padding: 15px 0;
            background-color: #fff;
            color: #A81E2D;
            @include fz_vw(30);
            font-weight: bold;
            text-align: center;
          }
          &.notHover {
            background-color: #a7a7a7;
          }
        }
      }
    }
  }
  .inner {
    max-width: 1040px;
    margin: 0 auto;
    position: relative;
    z-index: 1000;
    @include mq(md) {
      height: 100%;
    }
    .inside {
      width: 100%;
      padding-left: 6.25%;
      display: flex;
      align-items: center;
      z-index: 999;
      top: 0;
      left: 0;
      background-color: #b21d2e;
      @include mq(md) {
        padding: 18px;
        position: fixed;
      }
      .logo {
        width: 15.8974%;
        position: relative;
        z-index: 99999;
        @include mq(md) {
          width: 225px;
        }
        a {
          display: block;
        }
      }
    }
    /*ナビゲーション*/
    nav.globalTopNav {
      display: block;
      flex: 1;
      position: relative;
      padding: 5px 0 0 6.25%;
      z-index: 1000;
      @include mq(md) {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        padding: 0;
        //transform: translateY(50%);
        //background: rgba(#A81E2D, .8);
        background-color: #fff;
        width: 100%;
        padding: 7.16145% 0 0 0;
        transition: .8s;
        z-index: 0;
        //overflow: scroll;
        width: 100%;
        height: 50%;
        opacity: 0;
      }
      &.open {
        @include mq(md) {
          transform: translateY(0);
          opacity: 1;
        }
      }
      ul {
        display: flex;
        @include mq(md) {
          display: block;
          padding-bottom: 13.671875%;
        }
        li {
          &.spAnker {
            display: none;
          }
          @include mq(md) {
            &.spAnker {
              display: block;
            }
            &.pcAnker {
              display: none;
            }
            padding: 0 20px 10px;
          }
          &:first-child {
            @include mq(tab) {
              a {
                padding-left: 0;
              }
            }
          }
          &:last-child {
            @include mq(md) {
              padding-bottom: 0;
            }
          }
          &+li {
            border-left: 1px solid #fff;
            @include mq(md) {
              border: 0;
            }
          }
          a,
          span {
            @include fz_vw(16);
            line-height: 1;
            letter-spacing: 2.17px;
            text-align: left;
            color: #ffffff;
            display: block;
            padding: 0 20px;
            @include mq(tab) {
              padding: 0 1.8vw;
            }
            @include mq(md) {
              padding: 8px 0;
              background-color: #fff;
              color: #A81E2D;
              @include fz_vw(30);
              font-weight: bold;
              text-align: center;
            }
            &.notHover {
              color: #a7a7a7;
              cursor: default;
            }
          }
        }
      }
      .txtBox {
        display: none;
        @include mq(md) {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 0 20px;
          width: 100%;
        }
        p {
          @include fz_vw(21);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: 0.19px;
          text-align: left;
          color: #ffffff;
        }
        a.snsF {
          display: block;
          width: 10.9890%;
        }
      }
    }
    /*検索ボタン*/
    #searchBtn {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 1.9846%;
      margin-right: 5%;
      width: 2.26923%;
      cursor: pointer;
      z-index: 10001;
      @include mq(md) {
        margin-top: 18px;
        width: 20px;
        margin-right: 14.3229%;
      }
      button {
        cursor: pointer;
        background-color: transparent;
      }
    }
    /*SPグローバルメニュー*/
    .menuTrigger {
      display: none;
      @include mq(md) {
        display: inline-block;
        transition: all .4s;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        margin-top: 20px;
        margin-right: 5.859375%;
      }
      span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        border-radius: 4px;
        &:nth-of-type(1) {
          top: 0;
        }
        &:nth-of-type(2) {
          top: 9px;
        }
        &:nth-of-type(3) {
          bottom: 0;
        }
      }
      &.active {
        span {
          &:nth-of-type(1) {
            transform: translateY(9px) rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            transform: translateY(-10px) rotate(45deg);
          }
        }
      }
    }
  }
}
/*検索フォーム*/
#searchLayout {
  position: absolute;
  width: 100%;
  padding-right: 5.25%;
  top: -120px;
  left: 0;
  transform: translateY(-100%);
  z-index: 100;
  transition: top .8s;
  @include mq(md) {
    position: fixed;
    width: 100%;
    transform: translateY(0);
    padding-right: 0;
  }
  &.addOpen {
    @include mq(md) {
      top: 60px !important;
    }
  }
  .searcInner {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    @include mq(md) {
      background: rgba(#fff, .8);
      padding: 3.07692% 15px;
    }
    form {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .searchIcon {
      background-color: #fff;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(md) {
        height: 40px;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url(/images/common/search_icon_gray.svg) center/contain no-repeat;
      }
    }
    .searchTxt {
      font-family: $GaramondPremrPro;
      font-size: 1.6rem;
      line-height: 2;
      letter-spacing: 0.35px;
      outline: none;
      appearance: none;
      color: #000;
      @include placeholder(#c7c7c7);
      width: 200px;
      height: 35px;
      border: 0;
      @include mq(md) {
        width: 73.7%;
        height: 40px;
      }
    }
    .searchSubmit {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      background-color: #555;
      width: 59px;
      height: 35px;
      border: solid 1px #fff;
      cursor: pointer;
      @include mq(md) {
        width: 26.3%;
        height: 40px;
        border: none;
      }
      span {
        color: #fff;
        font-size: 1.6rem;
        line-height: 2;
        letter-spacing: 0.35px;
        text-align: center;
      }
      input {
        display: none;
      }
    }
  }
}
@charset "utf-8";
#application {
  .keyVisual {
    &Tit {
      @include fz_vw(36);
      @include mq(md) {
        @include fz_vw(48);
      }
    }
  }
  .enterBtn {
    font-family: $GaramondPremrPro;
    @include fz_vw(19);
    font-weight: bold;
    line-height: 1.55;
    letter-spacing: 1.2px;
    text-align: center;
    color: #fff;
    background-color: #b21d2e;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: 60px auto 0;
    cursor: pointer;
    text-transform: uppercase;
    border: 0;
    @include mq(md) {
      @include fz_vw(38);
    }
    &.disabled {
      background-color: #8d8d8d;
    }
  }
  .returnBtn {
    @extend .enterBtn;
    color: #b21d2e;
    background-color: #fff;
    border: solid 1px #b21d2e;
  }
  .required {
    display: inline-block;
    width: 55px;
    height: 15px;
    background: url(/images/application/required.svg) center/contain no-repeat;
    margin-left: 10px;

    &.indent{
      margin-left: 0;

      @include mq(tab) {
        margin-left: 10px;
      }

      @include mq(md) {
        margin-left: 10px;
      }
    }
  }
  .gate{
    padding: 50px 0;

    @include mq(md) {
      padding: 40px 0 70px;
    }

    .inner {
      max-width: 880px;
      margin: 0 auto;
      padding: 0 20px;
    }
    .gateTit{
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: #000;
      margin: 0 0 10px;

      @include mq(md) {
        @include fz_vw(36);
      }
    }
    .gateProcessBox{
      position: relative;
      padding: 40px 0;
      border-bottom: solid 1px #b21d2e;

      &.flex{
        display: flex;
        align-items: flex-end;

        @include mq(md) {
          display: block;
        }
      }

      &:not(:last-of-type) {
        margin: 0 0 12px;

        @include mq(md) {
          margin: 0 0 6px;
        }

        &::after{
          content: "";
          position: absolute;
          bottom: -54px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border: 32px solid transparent;
          border-top: 22px solid #b21d2e;
        }
      }

      .gateProcessTxt{
        margin-right: 40px;

        @include mq(md) {
          margin-right: 0;
        }
      }
      .gateProcessImg{
        display: flex;
        flex-shrink: 0;

        @include mq(md) {
          justify-content: center;
          padding: 9.161vw 4.30vw 0;
        }

        a{
          display: flex;
          flex-flow: column;
          @include fz_vw(16);
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: #000;
          text-align: center;
          flex-shrink: 0;
          margin-right: 40px;

          .img{
            display: flex;
            align-items: center;
            flex-grow: 1;
          }

          img{
            display: block;
            margin: 0 auto 10px;
          }

          @include mq(md) {
            @include fz_vw(32);
            letter-spacing: 0.25px;
            margin: 0 20px;

            img{
              width: 12.648vw;
            }

            &:last-of-type{
              img{
                width: 19.791vw;
              }
            }
          }
        }
      }

      .gateProcessBoxNum{
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #b21d2e;
        margin: 0 0 10px;

        @include mq(md){
          @include fz_vw(42);
          margin-bottom: 15px;
        }
      }
      .gateProcessBoxTxt{
        @include fz_vw(24);
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #000;

        a{
          color: inherit;
          text-decoration: underline;
        }

        @include mq(md) {
          @include fz_vw(42);
        }
      }
    }
  }
}
.application {
  padding: 60px 20px 0;
  &Closed {
    @include fz_vw(20);
    line-height: 1.7;
    letter-spacing: 0.5px;
    text-align: center;
    color: #333;
    margin-bottom: 80px;
    @include mq(md) {
      @include fz_vw(36);
      margin-bottom: 40px;
    }
  }
  &Tit {
    @include fz_vw(24);
    line-height: 1.42;
    letter-spacing: 0.6px;
    text-align: center;
    margin-bottom: 40px;
    @include mq(md) {
      @include fz_vw(44);
      margin-bottom: 20px;
    }
  }
  .passField {
    font-family: $GaramondPremrPro;
    display: block;
    width: 100%;
    max-width: 580px;
    margin: auto;
    border: solid 1px #c7c7c7;
    background-color: #fff;
    @include fz_vw(16);
    line-height: 1.5;
    letter-spacing: 0.4px;
    padding: 12px 20px;
    @include mq(md) {
      @include fz_vw(34);
    }
  }
  .errorMsg {
    display: none;
    text-align: center;
    padding: 30px 0 0;
    color: #b21d2e;
  }
  .loginFailed {
    display: block;
  }
}
.form {
  padding: 40px 20px 0;
  max-width: 880px;
  margin: auto;
  overflow: hidden;
  .error {
    background-color: #fff2f3;
  }
  .checkBox,
  .radioBtn {
    display: block;
    @include mq(md) {
      margin: 0 auto;
      padding: 20px 0;
    }
    &.error {
      padding: 10px;
      margin-top: 20px;
      @include mq(md) {
        padding: 20px;
      }
    }
    .errorTxt {
      text-indent: -26px;
    }
    p {
      position: relative;
      @include fz_vw(18);
      color: #231815;
      cursor: pointer;
      padding-left: 30px;
      @include mq(tab) {
        padding-left: 2.885vw;
      }
      @include mq(md) {
        @include fz_vw(40);
        line-height: 1.35;
      }
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 18px;
        height: 18px;
        border: solid 1px #231815;
        background-color: #fff;

        @include mq(tab) {
          top: 0.481vw;
          width: 1.731vw;
          height: 1.731vw;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 3px;
        width: 12px;
        height: 12px;
        transform: scale(.5);
        transition: .3s;

        @include mq(tab) {
          top: 0.769vw;
          left: 0.288vw;
          width: 1.154vw;
          height: 1.154vw;
        }
      }
    }
    input {
      opacity: 0;
      position: absolute;
      width: 20px;
      padding: 9px;
      margin-top: 2px;
      &:checked {
        &+p {
          &::after {
            transform: scale(1);
            background: #a81e2d;
          }
        }
      }
    }
  }
  .radioBtn {
    &:first-of-type {
      margin-bottom: 20px;

      @include mq(md) {
        margin-bottom: 0;
      }
    }
    &.inline {
      display: inline-block;
      &:first-of-type {
        margin-right: 40px;
        margin-bottom: 0;
      }
    }
  }
  .errorTxt {
    display: block;
    @include fz_vw(16);
    line-height: 1.75;
    letter-spacing: 0.4px;
    color: #ff001a;
    position: relative;
    margin-top: 10px;
    @include mq(md) {
      @include fz_vw(34);
      line-height: 1.41;
      letter-spacing: 0.85px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 12px;
      margin-right: 5px;
      background: url(/images/application/icon_warning.svg) center/contain no-repeat;
      @include mq(md) {
        width: 17px;
        height: 15px;
        margin-right: 8px;
      }
    }
    &.ib {
      display: inline-block;
      margin-left: 20px;
    }
  }
  &Flow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
    @include mq(md) {
      padding: 0 30px;
      margin-bottom: 20px;
    }
    &Txt {
      position: relative;
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 10px 0 46px;
      background-color: #f4f5f5;
      color: #666;
      max-width: 33.333%;;
      flex-basis: 33.333%;
      @include mq(md) {
        display: none;
        max-width: 100%;
        flex-basis: 100%;
        justify-content: center;
        padding: 0 20px;
        height: 50px;
        &::before,
        &::after {
          display: none;
        }
      }
      &:not(:last-of-type) {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: -50px;
          margin-top: -30px;
          border: 30px solid transparent;
          border-left: 20px solid #f4f5f5;
          z-index: 2;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -60px;
          margin-top: -36px;
          border: 36px solid transparent;
          border-left: 24px solid #fff;
          z-index: 1;
        }
      }
      @include mq(pc) {
        &:first-of-type {
          padding-left: 22px;
        }
        &:last-of-type {
          padding-right: 22px;
        }
      }
      &.current {
        background-color: #a81e2d;
        color: #fff;
        @include mq(md) {
          display: flex;
        }
        &:not(:last-of-type) {
          &::before {
            border-left-color: #a81e2d;
          }
        }
      }
      p {
        @include fz_vw(18);
        letter-spacing: 1.08px;
        text-align: center;
        width: 100%;
        @include mq(md) {
          @include fz_vw(42);
        }
      }
    }
    &Num {
      width: 100%;
      display: flex;
      justify-content: center;
      @include mq(pc) {
        display: none;
      }
      li {
        @include fz_vw(56);
        line-height: 2.68;
        letter-spacing: 1.4px;
        color: #c7c7c7;
        &:not(:first-child) {
          margin-left: 25px;
        }
        &.current {
          @include fz_vw(80);
          line-height: 1.88;
          letter-spacing: 2px;
          color: #a81e2d;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -6px;
            border: 6px solid transparent;
            border-top: 6px solid #a81e2d;
          }
        }
      }
    }
  }
  // step1
  &Lead {
    @include fz_vw(20);
    line-height: 1.7;
    letter-spacing: 0.5px;
    color: #333;
    margin-bottom: 80px;
    @include mq(md) {
      @include fz_vw(36);
      margin-bottom: 50px;
    }
    a {
      text-decoration: underline;
      color: #333;
    }
    &Link {
      margin: 60px 0 30px;
      @include mq(md) {
        margin-bottom: 20px 0 40px;
      }
      a {
        @include fz_vw(18);
        line-height: 1.89;
        letter-spacing: 0.45px;
        color: #a81e2d;
        text-decoration: underline;
        display: block;
        @include mq(md) {
          @include fz_vw(36);
        }
      }
    }
  }
  &Field {
    .attention {
      @include fz_vw(18);
      line-height: 1.89;
      letter-spacing: 0.45px;
      color: #ff001a;
      background-color: #f4f5f5;
      padding: 6px 10px;
      display: inline-block;
      margin-bottom: 30px;
      @include mq(md) {
        @include fz_vw(32);
        padding: 4px 6px;
        margin: 0 20px 30px;
      }
    }
    &Tit {
      @include fz_vw(27);
      font-size: 27px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.68px;
      color: #000;
      padding-bottom: 20px;
      border-bottom: solid 1px #c7c7c7;
      @include mq(md) {
        @include fz_vw(40);
        padding-bottom: 15px;
      }

      .modalBtn{
        display: inline-block;
        max-width: 80px;
        width: 100%;
        vertical-align: middle;
        margin: 0 0 0 20px;

        @include mq(md) {
          max-width: 60px;
          margin: 0 0 0 15px;
        }
      }
    }
    &SubTit{
      @include fz_vw(20);
      font-weight: 500;
      line-height: 1.8;
      letter-spacing: 0.5px;
      color: #b21d2e; 
      padding: 0 10px 5px;

      // &::after{
      //   content: "";
      //   display: inline-block;
      //   vertical-align: middle;
      //   width: 50px;
      //   height: 1px;
      //   background-color: #b21d2e;
      //   margin: 0 0 0 10px;
      // }
    
      @include mq(md) {
        @include fz_vw(40);
        padding: 0 0 10px;
      }
    }
    &List {
      padding: 30px 0;
      border: solid #c7c7c7;
      border-top: none;
      border-bottom: none;
      border-width: 1px 0;
      margin-bottom: 60px;

      &.first{
        margin-bottom: 10px;
      }
      &.second{
        margin-bottom: 65px;
      }
      &.bottom{
        padding: 30px 0;
        margin-bottom: 0;
        border-bottom: dotted 1px #c7c7c7;
      }
      &.question {
        padding: 0;
        border: none;
        margin-bottom: 80px;
        @include mq(md) {
          padding: 0 20px;
        }
      }
      dt, dd {
        padding: 0 10px;
        @include mq(md) {
          padding: 0;
        }
      }
      dt {
        @include fz_vw(18);
        line-height: 1.56;
        letter-spacing: 0.45px;
        color: #333;
        padding-bottom: 20px;

        .red{
          display: inline-block;
          color: #a81e2d;
          padding-top: 4px;

          a{
            color: #a81e2d;
          }
        }

        @include mq(md) {
          @include fz_vw(36);
          padding-bottom: 12px;
          &.error {
            padding-top: 15px;
            padding-left: calc(50vw - 50%);
            padding-right: calc(50vw - 50%);
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
          }
        }
        span:not([class]) {
          display: block;
          @include fz_vw(14);
          line-height: 1.5;
          letter-spacing: 0.35px;
          @include mq(md) {
            @include fz_vw(30);
          }
        }
        strong{
          font-weight: bold;
        }
        &.twoColumn{
          display: inline-block;
          width: 31%;
          vertical-align: top;
          padding-top: 8px;

          @include mq(tab) {
            padding-top: 13px;
          }
          @include mq(md) {
            display: block;
            width: 100%;
            padding-top: 0;
          }
        }
        a {
          text-decoration: underline;
        }
        .modalBtn{
          display: inline-block;
          max-width: 80px;
          width: 100%;
          vertical-align: middle;
          margin: 0 0 0 20px;

          @include mq(md) {
            max-width: 60px;
            margin: 5px 0 0 15px;
          }
        }
      }
      dd {
        &:not(:last-child) {
          padding-bottom: 30px;
          border-bottom: dotted 1px #c7c7c7;
          margin-bottom: 40px;
          @include mq(md) {
            margin-bottom: 30px;
          }
          &.error {
            &::after {
              content: "";
              width: 100%;
              border-bottom: dotted 1px #c7c7c7;
              position: absolute;
              bottom: -30px;
              left: 0;
            }
          }
        }
        &.twoColumn{
          display: inline-block;
          width: 68%;
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 30px;

          @include mq(md) {
            display: block;
            width: 100%;
            margin-bottom: 0;
          }
        }
        &.error {
          padding-bottom: 20px;
          margin-bottom: 70px;
          position: relative;
          border: none;
          @include mq(md) {
            padding-left: calc(50vw - 50%);
            padding-right: calc(50vw - 50%);
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
          }
        }
        p {
          @include fz_vw(18);
          line-height: 1.56;
          letter-spacing: 0.45px;
          color: #333;
          @include mq(md) {
            @include fz_vw(36);
          }
        }
        span {
          @include fz_vw(16);
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: #333;
          @include mq(md) {
            @include fz_vw(36);
          }
        }
        .note {
          display: block;
          @include fz_vw(14);
          line-height: 1.5;
          letter-spacing: 0.35px;
          color: #666;
          margin-top: 10px;
          &.left{
            padding-left: 30px;
          }
          &+.note{
            margin-top: 3px;
          }
          &.red{
            color: #a81e2d;
          }
          @include mq(md) {
            @include fz_vw(30);

            &.left{
              padding-left: 0;
            }
          }
        }
        .row {
          display: flex;
          @include mq(md) {
            display: block;
          }
          span {
            &:not([class]) {
              @include mq(md) {
                display: block;
                margin-bottom: 12px;
              }
            }
          }
          .selectBox{
            &+.note{
              margin:0 0 0 10px;

              @include mq(md) {
                margin:10px 0 0;
              }
            }
          }
        }
        .address {
          label {
            display: flex;
            align-items: center;
            @include mq(md) {
              display: block;
            }
            &:not(:first-child) {
              margin-top: 20px;
            }
            span {
              width: 13%;
              color: #333;
            }
            input {
              @include mq(md) {
                margin-top: 12px;
              }
            }
          }
        }
        .tel {
          span:not([class]) {
            padding-top: 10px;
            margin-right: 15px;
          }
          label {
            &:first-of-type {
              flex-basis: 35%;
              flex: inherit;
              margin-right: 30px;
              @include mq(md) {
                display: block;
                width: calc(100% - 30px);
                margin-top: 12px;
                margin-bottom: 25px;
              }
            }
            &:last-of-type {
              flex-basis: auto;
              flex: 1;
            }
          }
        }
        .titleName {
          justify-content: space-between;
          .selectBox {
            flex-basis: 20%;
            margin-right: 45px;
            @include mq(md) {
              width: calc(100% - 130px);
            }
          }
          span:not([class]) {
            padding-top: 10px;
            margin-right: 15px;
          }
          label {
            flex-basis: 28.4%;
            &:not(:last-child) {
              margin-right: 30px;

              @include mq(md) {
                margin-right: 0;
              }
            }
          }
        }
        .annual {
          margin-bottom: 20px;
          @include mq(md) {
            display: flex;
          }
          p {
            padding-top: 6px;
            margin-right: 15px;
          }
          .required {
            height: 40px !important;
          }
          .selectBox {
            width: auto;
            flex: 1;
          }
        }
        .collection {
          justify-content: space-between;
          align-items: center;
          @include mq(md) {
            display: flex;
            flex-wrap: wrap;
          }
          span:not([class]) {
            @include mq(md) {
              width: calc(100% - 200px);
            }
          }
          .selectBox {
            max-width: 260px;
            @include mq(md) {
              max-width: 200px;
              margin-bottom: 25px;
            }
          }
        }
        .which {
          align-items: center;
          margin-bottom: 30px;
          .checkBox {
            margin-right: 30px;
          }
        }
        .fileArea{
          padding: 20px 0 0;
          margin: 20px 0 0;

          @include mq(md) {
            padding: 10px 0 0;
            margin: 10px 0 0;
          }

          .fileAreaTit{
            @include fz_vw(18);
            line-height: 1.56;
            letter-spacing: 0.45px;
            color: #333;
            margin: 0 0 20px;

            @include mq(md) {
              @include fz_vw(36);
            }
          }

          .red{
            color: #a81e2d;
          }

          a{
            text-decoration: underline;
          }
        }
        .otherArea{
          padding: 20px 0 0;
          margin: 10px 0 0;

          @include mq(md) {
            padding: 20px 0 0;
            margin: 0;
          }

          .otherAreaTit{
            @include fz_vw(18);
            line-height: 1.56;
            letter-spacing: 0.45px;
            color: #333;
            margin: 0 0 20px;

            @include mq(md) {
              @include fz_vw(36);
            }
          }

          .red{
            color: #a81e2d;
          }

          a{
            text-decoration: underline;
          }
        }
      }
    }
    input, select, textarea {
      font-family: $GaramondPremrPro;
      width: 100%;
      border: solid 1px #c7c7c7;
      @include fz_vw(16);
      line-height: 1.5;
      letter-spacing: 0.4px;
      padding: 0 20px;
      height: 40px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: #000;
      @include placeholder(#999);
      &.mt20 {
        margin-top: 20px;
      }
      &:disabled{
        background-color: rgba(239, 239, 239, 0.3);
      }
      @include mq(md) {
        @include fz_vw(34);
      }
    }
    textarea {
      min-height: 180px;
      resize: vertical;
    }
    .selectBox {
      position: relative;
      width: 100%;
      max-width: 360px;
      @include mq(md) {
        width: calc(100% - 30px);
      }
      &::after {
        content: "";
        width: 14px;
        height: 6px;
        display: block;
        background: url(/images/application/icon_down.svg) center/contain no-repeat;
        position: absolute;
        top: 17px;
        right: 20px;
        margin: auto;
      }
      select {
        max-width: 360px;
        color: #999;
        background-color: #fff;
        border-radius: 0;
        &.selected {
          color: #000;
        }
      }

      &.min{
        max-width: 270px;

        select {
          max-width: 270px;
        }

        @include mq(md) {
          max-width: 90%;

          select {
            max-width: 100%;
          }
        }
      }
      &.big{
        max-width: 100%;

        select {
          max-width: 100%;
        }

        @include mq(md) {
          width: 100%;
        }
      }
    }
    .bgArea {
      margin: 0 calc(50% - 50vw) 30px;
      padding: 50px calc((50vw - 50%) + 60px);
      background-color: #f7f8f8;
      @include mq(md) {
        padding: 30px 20px;
        margin-bottom: 40px;
      }
    }
    .caution {
      @include fz_vw(18);
      line-height: 1.56;
      letter-spacing: 0.45px;
      color: #a81e2d;
      @include mq(md) {
        @include fz_vw(36);
      }
    }
    .upload {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      @include mq(md) {
        display: block;
      }
      p:not([class]) {
        @include fz_vw(18);
        margin-right: 30px;
        @include mq(md) {
          @include fz_vw(36);
          margin-bottom: 12px;
        }
        .required {
          @include mq(pc) {
            display: block !important;
            margin-left: 0 !important;
          }
        }
      }
      &Btn {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 520px;
        input {
          width: calc(100% - 126px);
        }
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
          background-color: #666;
          width: 126px;
          height: 40px;
          cursor: pointer;
          span {
            color: #fff;
            @include fz_vw(16);
            line-height: 1.94;
            letter-spacing: 0.96px;
            text-align: center;
            @include mq(md) {
              @include fz_vw(34);
            }
          }
        }
        input[type=file] {
          display: none;
        }
      }
      &Note {
        width: 100%;
        margin-top: 3px;
        @include fz_vw(14);
        color: #666;
        @include mq(md) {
          @include fz_vw(28);
        }
      }
    }
  }
  &Agree {
    margin: 40px 0 80px;
    @include mq(md) {
      margin: 20px 0 40px;
    }
    p {
      @include fz_vw(18);
      margin-bottom: 20px;
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 0;
      }
    }
    .checkBox {
      margin-bottom: 30px;

      &.agree{
        padding: 12px 0 20px;
      }

      p {
        margin: 0;
      }
    }
    &Caution {
      color: #b21d2e;
      @include fz_vw(16);
      @include mq(md) {
        @include fz_vw(34);
      }
    }
  }
  // step2
  &Select {
    $select: &;
    &Head {
      margin-bottom: 55px;
      &.error {
        padding: 15px calc(50vw - 50%);
        margin: 0 calc(50% - 50vw) 40px;
        @include mq(md) {
          padding: 20px calc(50vw - 50%);
          margin-bottom: 50px;
        }
        #{$select}Txt {
          color: #ff001a;
        }
      }
    }
    &Tit {
      @include fz_vw(20);
      line-height: 1.5;
      letter-spacing: 1.2px;
      color: #333;
      margin-bottom: 10px;
      @include mq(md) {
        @include fz_vw(36);
      }
      a {
        text-decoration: underline;
        color: #333;
      }
    }
    &Txt {
      @include fz_vw(16);
      line-height: 1.88;
      letter-spacing: 0.96px;
      color: #ff001a;
      @include mq(md) {
        @include fz_vw(36);
      }
    }
    &Genre {
      margin-bottom: 20px;
      padding-left: 10px;
      @include mq(md) {
        margin-bottom: 5px;
        padding-left: 0;
      }
      p {
        @include fz_vw(18);
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: 0.45px;
        color: #333;
      }
    }
    &List {
      border: solid #c7c7c7;
      border-width: 1px 0;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;
      &:not(:last-of-type) {
        margin-bottom: 120px;
        @include mq(md) {
          margin-bottom: 90px;
        }
      }
      &::after {
        content: "";
        width: 30%;
      }
      @include mq(md) {
        display: block;
        margin: 0 calc(50% - 50vw);
      }
      li {
        flex-basis: 30%;
        padding: 20px 0;
        @include mq(pc) {
          &:nth-child(3n-2) {
            padding-left: 10px;
          }
          &:nth-child(3n) {
            padding-right: 10px;
          }
          &:nth-child(6n-2) {
            position: relative;
            &::after {
              content: "";
              width: 100vw;
              max-width: 840px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #f7f8f8;
              z-index: -1;
            }
          }
        }
        @include mq(md) {
          padding: 0;
          &:nth-child(2n) {
            background-color: #f7f8f8;
          }
        }
        &>p {
          @include fz_vw(18);
          color: #231815;
          padding-left: 30px;
          @include mq(md) {
            @include fz_vw(40);
            line-height: 1.35;
            margin: 0 calc(50% - 50vw);
            padding: 20px;
            padding-left: 50px;
          }
        }
      }
    }
  }
  &Confirm {
    &Lead {
      @include fz_vw(20);
      line-height: 1.7;
      letter-spacing: 0.5px;
      color: #333;
      margin-bottom: 80px;
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 40px;
      }
    }
    &Tit {
      @include fz_vw(20);
      line-height: 1.8;
      letter-spacing: 0.5px;
      color: #b21d2e;
      position: relative;
      margin-left: 25px;
      text-indent: -25px;
      padding-bottom: 20px;
      @include mq(md) {
        @include fz_vw(40);
        margin-left: 18px;
        text-indent: -18px;
        padding: 30px 20px 20px 38px;
        background-color: #f7f8f8;
        border-bottom: solid 1px #c7c7c7;
        margin: 0 calc(50% - 50vw);
      }
      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 1px;
        background-color: #b21d2e;
        margin-right: 10px;
        margin-bottom: 6px;
        @include mq(md) {
          width: 11px;
          margin-right: 7px;
        }
      }
    }
    &Edit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 30px;
      position: absolute;
      bottom: 20px;
      right: 0;
      margin: auto;
      background-color: #ce2437;
      font-size: 1.6rem;
      letter-spacing: 0.8px;
      color: #fff;
      cursor: pointer;
      @include mq(md) {
        @include fz_vw(32);
        right: 20px;
        width: 90px;
      }
      &::before {
        content: '';
        width: 5px;
        height: 5px;
        border: 0px;
        border-top: solid 1px #fff;
        border-right: solid 1px #fff;
        transform: rotate(-135deg);
        display: inline-block;
        margin-right: 10px;
      }
    }
    &List {
      padding: 40px 30px;
      background-color: #f7f8f8;
      margin-bottom: 60px;

      @include mq(md) {
        margin: 0 calc(50% - 50vw) 70px;
        padding: 30px 20px;
      }

      &.second{
        margin-top: 20px;
        margin-bottom: 80px;

        @include mq(md) {
          margin-bottom: 70px;
        }
      }
      &.first{
        padding: 40px 0;
        margin-bottom: 10px;
        background-color: inherit;

        @include mq(md) {
          margin: 0 auto 10px;
        }
      }
      &.bottom{
        padding: 30px 0;
        background-color: inherit;

        @include mq(md) {
          margin: 0 auto 20px;
        }
      }

      dt, dd {
        padding: 0 10px;
        @include mq(md) {
          padding: 0;
        }
      }
      dt {
        @include fz_vw(18);
        line-height: 1.56;
        letter-spacing: 0.45px;
        color: #555;
        margin-bottom: 20px;
        @include mq(md) {
          @include fz_vw(36);
        }
        span:not([class]) {
          display: block;
          @include fz_vw(14);
          line-height: 1.5;
          letter-spacing: 0.35px;
          color: #333;
          @include mq(md) {
            @include fz_vw(30);
          }
        }
        .red{
          display: inline-block;
          color: #a81e2d;
          padding-top: 4px;

          a{
            color: #a81e2d;
          }
        }
        .note {
          display: block;
          @include fz_vw(14);
          line-height: 1.5;
          letter-spacing: 0.35px;
          color: #666;
          margin-top: 10px;
          &.red{
            color: #a81e2d;
          }
          @include mq(md) {
            @include fz_vw(30);
          }
        }
        &.border{
          border-top: dotted 1px #c7c7c7;
          padding-top: 40px;
          @include mq(md) {
            padding-top: 20px;
          }
        }
        strong{
          color: #000;
        }
        &.twoColumn{
          display: inline-block;
          width: 31%;
          vertical-align: top;
          padding-top: 2px;

          @include mq(md) {
            display: block;
            width: 100%;
            padding-top: 0;
          }
        }
        a {
          text-decoration: underline;
        }
      }
      dd {
        &:not(:last-child) {
          padding-bottom: 30px;
          border-bottom: dotted 1px #c7c7c7;
          margin-bottom: 40px;
          @include mq(md) {
            margin-bottom: 20px;
          }
        }
        p {
          @include fz_vw(18);
          line-height: 1.56;
          letter-spacing: 0.45px;
          color: #000;
          display: inline-block;
          margin-right: 15px;
          @include mq(md) {
            @include fz_vw(36);
          }
          span {
            color: #555;
            margin-right: 20px;
          }
        }
        .fileArea{
          .red{
            color: #a81e2d;
          }

          a{
            text-decoration: underline;
          }
        }
        .otherArea{
          .red{
            color: #a81e2d;
          }

          a{
            text-decoration: underline;
          }
        }
        .address {
          p {
            display: block;
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
          .flex {
            display: flex;
            align-items: center;
            @include mq(md) {
              display: block;
            }
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            p {
              @include mq(md) {
                margin: 4px 0 28px;
              }
            }
            span {
              width: 13%;
              @include fz_vw(16);
              line-height: 1.5;
              letter-spacing: 0.4px;
              color: #666;
              @include mq(md) {
                width: 100%;
                @include fz_vw(36);
              }
            }
          }
        }
        .row {
          display: flex;
          margin-bottom: 20px;
          @include mq(md) {
            display: block;
          }
          .required {
            height: auto !important;
          }
        }
        .collection {
          margin-bottom: 0;
          p {
            margin-right: 140px;
            @include mq(md) {
              margin: 0;
              &:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
        &.twoColumn{
          display: inline-block;
          width: 68%;
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 30px;

          @include mq(md) {
            display: block;
            width: 100%;
            margin-bottom: 0;
          }
        }

        .fileArea{
          padding: 20px 0 0;
          margin: 20px 0 0;

          @include mq(md) {
            padding: 10px 0 0;
            margin: 10px 0 0;
          }

          .fileAreaTit{
            @include fz_vw(18);
            line-height: 1.56;
            letter-spacing: 0.45px;
            color: #555;
            @include mq(md) {
              @include fz_vw(36);
            }
          }
        }
        .otherArea{
          padding: 20px 0 0;
          margin: 20px 0 0;

          @include mq(md) {
            padding: 20px 0 0;
            margin: 10px 0 0;
          }

          .otherAreaTit{
            @include fz_vw(18);
            line-height: 1.56;
            letter-spacing: 0.45px;
            color: #555;
            margin-bottom: 20px;
            @include mq(md) {
              @include fz_vw(36);
            }
          }
        }
      }
    }
    .bgArea {
      margin: 0 calc(50% - 50vw) 40px;
      padding: 50px calc((50vw - 50%) + 40px);
      background-color: #f7f8f8;
      @include mq(md) {
        padding: 30px 20px;
        margin-bottom: 40px;
      }
    }
    .caution {
      @include fz_vw(18);
      line-height: 1.56;
      letter-spacing: 0.45px;
      color: #555;
      @include mq(md) {
        @include fz_vw(36);
      }
    }
    .upload {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &::before {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(/images/application/icon_success.svg) center/contain no-repeat;
        margin-right: 10px;
        @include mq(md) {
          margin-bottom: 15px;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      &:first-of-type {
        margin-top: 40px;
        @include mq(md) {
          margin-top: 20px;
        }
      }
      p {
        @include fz_vw(18);
        margin-right: 30px;
        @include mq(md) {
          @include fz_vw(36);
          margin: 0 0 15px;
          display: inline-block;
          flex-basis: calc(100% - 28px);
        }
        .required {
          @include mq(pc) {
            display: block !important;
            margin-left: 0 !important;
          }
        }
      }
      img {
        margin-right: 15px;
      }
      span,a {
        @include fz_vw(14);
        line-height: 1.43;
        letter-spacing: 0.35px;
        color: #555;
        display: inline-block;
        text-decoration: underline;
        align-self: flex-end;
        @include mq(md) {
          @include fz_vw(28);
        }
      }
    }
    &Select {
      margin-top: 50px;
      @include mq(md) {
        margin-top: 30px;
      }
      &Genre {
        margin-bottom: 10px;
        padding-left: 10px;
        @include fz_vw(18);
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: 0.45px;
        color: #000;
        @include mq(md) {
          margin-bottom: 5px;
          padding-left: 0;
        }
      }
      &List {
        border-top: solid 1px #c7c7c7;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
        &:not(:last-of-type) {
          margin-bottom: 60px;
          @include mq(md) {
            margin-bottom: 30px;
          }
        }
        &::after {
          content: "";
          width: 30%;
        }
        @include mq(md) {
          display: block;
        }
        li {
          flex-basis: 28%;
          padding: 20px 0;
          @include mq(pc) {
            &:nth-child(3n-2) {
              padding-left: 10px;
              position: relative;
              &::after {
                content: "";
                width: 100vw;
                max-width: 840px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-bottom: dotted 1px #c7c7c7;
              }
            }
          }
          @include mq(md) {
            padding: 10px 0;
            &:nth-child(2n) {
              background-color: #f7f8f8;
            }
          }
          p {
            @include fz_vw(16);
            line-height: 1.5;
            letter-spacing: 0.4px;
            color: #333;
            margin: 0;
            text-indent: -1em;
            padding-left: 1em;
            &::before {
              content: '・';
            }
          }
        }
      }
    }
    &Txt {
      @include fz_vw(18);
      line-height: 1.33;
      letter-spacing: 0.45px;
      color: #000;
      margin-bottom: 20px;
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 0;
      }
    }
    &Column {
      display: flex;
      justify-content: space-between;
      max-width: 580px;
      margin: auto;
      .returnBtn {
        margin-right: 10px !important;
      }
    }
  }
  &Complete {
    &Tit {
      @include fz_vw(24);
      line-height: 1.17;
      letter-spacing: 0.6px;
      text-align: center;
      padding-top: 20px;
      margin-bottom: 100px;
      @include mq(md) {
        @include fz_vw(44);
        margin-bottom: 50px;
      }
    }
  }
}
.download {
  padding: 140px 20px 60px;
  text-align: center;
  &Btn {
    @include fz_vw(28);
    line-height: 1;
    letter-spacing: 0.7px;
    color: #000;
    text-decoration: underline;
    @include mq(md) {
      @include fz_vw(44);
    }
  }
}
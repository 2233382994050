//----------------------------------------------------

/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
//----------------------------------------------------

$yakuhanjp-noto-font-family: "YakuHanJP_Noto" !default;
$yakuhanjp-noto-font-file: "YakuHanJP" !default;
$yakuhanjp-noto-font-dir: "/css/fonts" !default;
$yakuhanjp-noto-eot-use: true !default;
$yakuhanjp-noto-cdn-use: false !default;
$yakuhanjp-noto-cdn-version: "3.2.0" !default;
$yakuhanjp-noto-weight: (
  100: Thin,
  300: Light,
  400: Regular,
  500: Medium,
  700: Bold,
  900: Black,
) !default;

@each $key, $value in $yakuhanjp-noto-weight {
  @font-face {
    $family: $yakuhanjp-noto-font-family;
    $file: $yakuhanjp-noto-font-file;
    $dir: $yakuhanjp-noto-font-dir;
    $eot: $yakuhanjp-noto-eot-use;
    $cdn: $yakuhanjp-noto-cdn-use;
    $version: $yakuhanjp-noto-cdn-version;
    $jsdelivr: "https://cdn.jsdelivr.net/npm/yakuhanjp";

    font-family: $family;
    font-style: normal;
    font-weight: $key;

    @if $eot == true and $cdn == true {
      src: url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $eot == true and $cdn == false {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.eot");
    }

    @if $cdn == true {
      src:
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff2") format("woff2"),
        url("#{$jsdelivr}@#{$version}/dist/fonts/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }

    @else {
      src: url("#{$dir}/#{$file}/#{$file}-#{$value}.woff2") format("woff2"), url("#{$dir}/#{$file}/#{$file}-#{$value}.woff") format("woff");
    }
  }
}

@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
  &::before,&::after {
    box-sizing: border-box;
  }
}

.hoge{
	width: 100%;
	background-color: antiquewhite;
	height: 800px;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0; // 横幅768pxまで
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.tabOnly {
  display: none !important;

  @include mq(tab) {
    display: block !important;
  }
}

.spOnly {
  display: none !important;

  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family:$GaramondPremrPro;
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;
  &.fixed {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.fixedBody {
  position: fixed !important;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  @include mq(md) {
    margin-top: 60px;
  }
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.highlight{
  display: inline !important;
  background-color: #FFFF5A !important;
  //color: #fff !important;
  padding: 0 2px 2px !important;
}

a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
.detailModalContent {
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  background: $basicWhiteColor;
  position: fixed;
  display: none;
  z-index: 1002;
  display: none;

  .close {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 100;
      opacity: 1; // 横幅が768pxまでのスタイル
      @include mq(md) {
          top: 20px;
          right: 20px;
      }

      a{
        display: block;
        text-indent: -9999px;
        width: 5vw;
        height: 5vw;
        max-width: 60px;
        max-height: 60px;
        background: url(/images/common/modal_close_btn.svg) no-repeat;
        background-size: contain;

        @include mq(md){
          width: 6.4vw;
          height: 6.4vw;
          background: url(/images/common/modal_close_btn_sp.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    .modalInner{
      border: 10px solid #B21D2E;
      overflow-y: auto;
      position: relative;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 120px;
      height: 100vh;
      background: $basicWhiteColor;

      @include mq(md){
        padding-bottom: 16vw;
      }

      h3{
        &.subTtl{
          @include fz_vw(28);
          text-align: center;
          margin: 120px auto 47px;
  
          @include mq(md){
            @include fz_vw(36);
            margin: 16vw auto 5.33vw;
          }
        }
      }

      img{
        &.dateImg{
          margin: 0 auto;
          width: 80vw;
          max-width: 1200px;

          @include mq(md){
            width: 75%;
          }
        }
      }
    }
  }
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
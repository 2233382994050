@charset "utf-8";
/* ボタン装飾
------------------------------------------------------------- */

html:not([data-whatinput="mouse"]):not([data-whatinput="touch"]) input:focus,
html:not([data-whatinput="mouse"]):not([data-whatinput="touch"]) button:focus {
  outline: none;
}

.moreBtnCom {
	display: block;
	text-align: center;
	a {
		max-width: 110px;
		margin: 0 auto;
		background-color: #b21d2e;
		color: #fff;
		@include fz_vw(19);
		line-height: 1.68;
		letter-spacing: 1.14px;
		text-align: center;
		color: #ffffff;
		padding: 5px 25px;
		display: block;
		@include mq(md){
			max-width: none;
			width: 100%;
			@include fz_vw(40);
			font-weight: bold;
		}
	}
}
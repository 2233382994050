.synopsis {
  padding: 60px 0 0;
  background-color: #fff;
  @include mq(tab) {
    //padding-bottom: 60px;//ajax用調整
    padding-top: 0;
    margin: 60px auto 0;
    overflow: hidden;//ajax用調整
  }
  @include mq(md) {
    position: relative;
    z-index: 2;
    //padding-bottom: 30px;
    padding-top: 0;
    margin: 60px auto 0;
    overflow: hidden;//ajax用調整
  }
  .synopsisBtn {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.55;
    letter-spacing: 1.2px;
    text-align: center;
    color: #fff;
    background-color: #b21d2e;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: 30px auto 0;
    cursor: pointer;
    @include mq(md) {
      font-size: 2.2rem;
      width: 100%;
      margin: 0 auto;
      line-height: 1.68;
      letter-spacing: 1.14px;
      margin: 60px auto 20px;
      padding: 5px 25px;
    }
  }
  .categoryColumn {
    max-width: 880px;
    margin: 0 auto 60px;
    padding: 0 20px;
    @include mq(tab) {
      position: relative;
    }
    @include mq(md) {
      position: relative;
      margin-bottom: 50px;
    }
    &Current {
      font-size: 2rem;
      line-height: 1.7;
      letter-spacing: 0.5px;
      color: #a81e2d;
      display: block;
      background-color: #fff;
      padding: 2.2% 5.8%;
      border: solid 1px #a81e2d;
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        margin: 0 auto 0 0;
        background-image: url(/images/synopsis/icon_accordion.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 6%;
        top: 42%;
        transition: .4s;
      }
      &.open {
        &::after {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
      //      &:hover {
      //        @include mq(md) {
      //          background-color: #f4f5f5;
      //        }
      //      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      z-index: 1;
      @include mq(tab) {
        display: block;
        width: calc(100% - 40px);
        position: absolute;
        margin-top: -1px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: opacity 10s, visibility 10s, transform .5s;
        transform: translateY(-100%);
        border-top: solid 1px #c7c7c7;
        border-right: solid 1px #a81e2d;
        border-left: solid 1px #a81e2d;
        border-bottom: solid 1px #a81e2d;
      }
      @include mq(md) {
        display: block;
        width: calc(100% - 40px);
        position: absolute;
        margin-top: -1px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: opacity 10s, visibility 10s, transform .5s;
        transform: translateY(-100%);
        border-top: solid 1px #c7c7c7;
        border-right: solid 1px #a81e2d;
        border-left: solid 1px #a81e2d;
        border-bottom: solid 1px #a81e2d;
      }
      &.open {
        visibility: visible;
        opacity: 1;
        transition: .4s;
        transform: translateY(0);
      }
      li {
        margin-right: 20px;
        @include mq(tab) {
          margin-right: 0;
          background-color: #fff;
        }
        @include mq(md) {
          margin-right: 0;
          background-color: #fff;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: 0;
          text-align: center;
          color: #666666;
          @include mq(tab) {
            display: block;
            background-color: #fff;
            padding: 4.4% 5.8%;
            text-align: left;
          }
          @include mq(md) {
            display: block;
            background-color: #fff;
            padding: 4.4% 5.8%;
            text-align: left;
          }
          &:hover {
            color: #a81e2d;
            padding-bottom: 5px;
            border-bottom: solid 1px #a81e2d;
            @include mq(tab) {
              padding: 4.4% 5.8%;
              border-bottom: none;
              color: #000;
              opacity: 1;
              background-color: #f4f5f5;
            }
            @include mq(md) {
              padding: 4.4% 5.8%;
              border-bottom: none;
              color: #000;
              opacity: 1;
              background-color: #f4f5f5;
            }
          }
        }
        &.active {
          a {
            color: #a81e2d;
            padding-bottom: 5px;
            border-bottom: solid 1px #a81e2d;
          }
          @include mq(tab) {
            display: none;
          }
          @include mq(md) {
            display: none;
          }
        }
      }
    }
  }
  .synopsisNote {
    font-size: 1.6rem;
    line-height: 1.75;
    letter-spacing: 0.4px;
    color: #333;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: 1.43;
      letter-spacing: 0.7px;
      margin-top: 30px;
      margin-bottom: -5px;
      padding-bottom: 40px;
    }
    &::before {
      content: '';
      display: inline-block;
      margin: auto 10px;
      width: 8px;
      height: 1px;
      background-color: #333;
      @include mq(md) {
        margin: auto 7px;
        transform: translateY(-3.5px);
      }
    }
    span {
      order: -1;
      display: inline-block;
      ont-family: GaramondPremrPro;
      font-size: 1.4rem;
      line-height: 0.86;
      letter-spacing: 0.35px;
      text-align: left;
      color: #a81e2d;
      padding: 4px 10px;
      background-color: #ffffff;
      border: solid 1px #d9d9d9;
      @include mq(md) {
        letter-spacing: 0.7px;
        padding: 4px 7px;
        margin-bottom: 7px;
      }
      &::before {
        content: "";
        display: inline-block;
        background-image: url(/images/synopsis/icon_book.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        vertical-align: top;
        @include mq(md) {
          width: 11px;
          height: 10.5px;
          background-image: url(/images/synopsis/icon_book_sp.svg);
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
  }
  .synopsisColumn {
    position: relative;
  }
  #synopsisLoadPoint {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: auto;
      font-size: 10px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      border-left: 2px solid #000;
      transform: translateY(150%);
      transition: opacity 1s;
      opacity: 0;
    }
    &.loading {
      &::after {
        opacity: 1;
        animation: load 1.1s infinite linear;
      }
    }
  }
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .postNum {
    display: none;
  }
  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
    .synopsisColumnTitle {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.68px;
      text-align: left;
      color: #b21d2e;
      margin-bottom: 20px;
      @include mq(md) {
        font-size: 2.3rem;
        line-height: 1.07;
        margin-bottom: 4%;
      }
      visibility: hidden;
      position: absolute;
      &+.synopsisCardLayout {
        visibility: hidden;
        position: absolute;
      }
      &.active {
        visibility: visible;
        position: relative;
        &+.synopsisCardLayout {
          visibility: visible;
          position: relative;
        }
      }
    }
  }
  .synopsisCardLayout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    @include mq(md) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    &::after,
    &::before {
      content: "";
      display: block;
      width: 24%;
      height: 0;
    }
    &::before {
      order: 1;
    }
    .synopsisCardBox{
      width: 24%;
      margin-bottom: 8px;
      @include mq(md) {
        flex-basis: 49%;
        padding-top: 61px;
        margin-top: -61px;
        margin-bottom: 2%;
      }
    }
    .synopsisCard {
      //width: 24%;
      height: 100%;
      background-color: #f7f8f8;
      padding: 15px 20px 50px;
      //margin-bottom: 8px;
      @include mq(md) {
        //flex-basis: 49%;
        padding: 15px 5.3% 50px;
        //margin-bottom: 2%;
      }
      .txtBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .synopsisCategory {
        display: inline-block;
        ont-family: GaramondPremrPro;
        font-size: 1.6rem;
        line-height: 0.75;
        letter-spacing: 0.4px;
        text-align: left;
        color: #a81e2d;
        padding: 4px 10px;
        background-color: #ffffff;
        &::before {
          content: "";
          display: inline-block;
          background-image: url(/images/synopsis/icon_book.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 12.2px;
          height: 11.7px;
          margin-right: 6px;
          vertical-align: top;
          @include mq(md) {
            width: 12.5px;
            height: 12px;
            background-image: url(/images/synopsis/icon_book_sp.svg);
            margin-right: 7px;
            vertical-align: middle;
          }
        }
        @include mq(md) {
          font-size: 1.4rem;
          letter-spacing: 0.8px;
          padding: 6px 4px 4px;
        }
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
      .synopsisId{
        font-size: 1.4rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.4px;
        text-align: right;
        opacity: 0;
        &.show {
          opacity: 1;
        }
        @include mq(md) {
          font-size: 1.4rem;
          line-height: 0.38;
          letter-spacing: 0.4px;
        }
      }
      a {
        &:hover {
          .synopsisCardVisual,
          .synopsisCardTitle {
            opacity: 0.7;
          }
        }
      }
      .synopsisCardVisual {
        margin-bottom: 15px;
        img {
          width: 100%;
        }
      }
      .synopsisCardTitle {
        font-size: 1.4rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.35px;
        text-align: left;
        text-decoration: underline;
        color: #000000;

        em{
          font-style: italic;
        }

        @include mq(md) {
          line-height: 1.36;
          letter-spacing: 0.7px;
        }
      }
    }
  }
  //記事ページのレイアウト
  .synopsisArticle {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    @include mq(md) {
      display: block;
    }
    .synopsisArticleRightColumn {
      flex-basis: 33.57%;
      margin-right: 60px;
      @include mq(md) {
        width: 60%;
        margin: 0 auto;
      }
      .synopsisArticleVisual {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
      }
      .txtBox{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq(md) {
          margin-bottom: 20%;
        }
      }
      .synopsisCategory {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.4px;
        text-align: left;
        color: #a81e2d;
        padding: 5px 12px;
        border: solid 1px #A81E2D;
        background-color: #ffffff;
        &::before {
          content: "";
          display: inline-block;
          background-image: url(/images/synopsis/icon_book.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 13px;
          height: 13px;
          margin-right: 6px;
          vertical-align: middle;
          @include mq(md) {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
        @include mq(md) {
          font-size: 1.6rem;
          line-height: 0.38;
          letter-spacing: 0.8px;
          padding: 3% 6% 2%;
        }
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
      .synopsisId{
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.4px;
        text-align: right;
        opacity: 0;
        &.show {
          opacity: 1;
        }
        @include mq(md) {
          font-size: 1.5rem;
          line-height: 0.38;
          letter-spacing: 0.4px;
        }
      }
    }
    .synopsisArticleLeftColumn {
      flex-basis: 55.83%;
      @include mq(md) {
        width: 100%;
      }
      .synopsisArticleCategory {
        font-size: 2rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: 0.5px;
        text-align: left;
        color: #a81e2d;
        margin-bottom: 20px;
        @include mq(md) {
          line-height: 1.42;
          letter-spacing: 0.95px;
        }
      }
      .synopsisArticleTitle {
        font-size: 3.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.8px;
        text-align: left;
        color: #000000;
        margin-bottom: 10px;

        em{
          font-style: italic;
        }

        @include mq(md) {
          line-height: 1.29;
          letter-spacing: 1.05px;
        }
      }
      .synopsisArticlePublication {
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: left;
        color: #000000;
        margin-bottom: 32px;
        @include mq(md) {
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0.7px;
          margin-bottom: 10%;
        }
      }
      .synopsisArticleAuthor {
        font-size: 2.2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: left;
        color: #000000;
        @include mq(md) {
          font-size: 2rem;
          line-height: 1.5;
          letter-spacing: 0.7px;
        }
      }
      .synopsisArticleTxt {
        font-size: 1.6rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.4px;
        text-align: left;
        color: #000000;
        margin-bottom: 20px;

        p{
          margin-bottom: 15px;

          &:last-of-type{
            margin-bottom: 0;
          }
        }

        em,
        i{
          font-style: italic;
        }
        b{
          font-weight: bold;
        }
        @include mq(md) {
          font-size: 1.8rem;
          line-height: 1.5;
          letter-spacing: 0.9px;
        }
      }
    }
  }
}
#synopsis{
  .newsPager{
    max-width: 750px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 100px;
    @include mq(md) {
      position: relative;
      z-index: 1;
      max-width: 100%;    
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      padding: 0 75px 0 0;
      margin: 0 auto 80px;

      dl{
        max-width: 75px;
        width: 100%;

        .pageAccordionArea{
          position: relative;
        }

        #pageAccordionBox{
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
          width: 100%;
          background-color: #fff;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transition: opacity 10s, visibility 10s, transform .5s;
          transform: translateY(-100%);
          &.open {
            visibility: visible;
            opacity: 1;
            transition: .4s;
            transform: translateY(0);
          }
        }
      }
      .maxPage{
        font-size: 2.3rem;
        line-height: 1;
        letter-spacing: 0.46px;
        color: #c7c7c7;
        margin: 0 0 0 10px;
      }
    }
    ol{
      flex-wrap: wrap;
      justify-content: flex-start;

      &.center{
        justify-content: center;
      }
      @include mq(md) {
        position: static;
        display: block;
        width: 100%;
        max-height: 215px;
        overflow-y: scroll;
        border-right: solid 1px #a81e2d;
        border-left: solid 1px #a81e2d;
        border-bottom: solid 1px #a81e2d;
      }

      li{
        width: 35px;
        text-align: center;
        @include mq(md) {
          width: auto;
          text-align: left;
        }
        a{
          @include mq(md) {
            display: block;
            font-size: 2.3rem;
            line-height: 1;
            letter-spacing: .572px;
            padding: 10px 15px 10px;

            &:hover {
              color: #000;
              background-color: #f4f5f5;
            }
          }
        }
        &.current{
          a{
            color: #b21d2e;
          }
          @include mq(md) {
            display: none;
          }
        }
      }
    }
    .next{
      @include mq(md) {
        position: absolute;
        width: 7px;
        top: 15px;
        left: calc(100% - 27px);
        padding: 0;

        &::after{
          display: none;
        }

        a{
          display: block;
        }
      }
    }
    .prev{
      @include mq(md) {
        position: absolute;
        width: 7px;
        top: 15px;
        left: calc(100% - 57px);
        padding: 0;

        &::after{
          display: none;
        }

        a{
          display: block;
        }
      }
    }


    .currentPage {
      cursor: pointer;
      font-size: 2.3rem;
      line-height: 1;
      letter-spacing: .572px;
      color: #a81e2d;
      display: block;
      background-color: #fff;
      padding: 10px 15px 10px;
      border: solid 1px #a81e2d;
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 6px;
        margin: 0 auto 0 0;
        background-image: url(/images/synopsis/icon_accordion.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 14%;
        top: 44%;
        transition: .4s;
      }
      &.open {
        border-bottom: solid 1px #c7c7c7;
        &::after {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
      //      &:hover {
      //        @include mq(md) {
      //          background-color: #f4f5f5;
      //        }
      //      }
    }
  }
}
@charset "utf-8";

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

// PCサイズ Retina画像出し分け用
// 使用例：@include media-retina {background-image: url("~~~@2x.jpg");}
@mixin media-retina() {
  @media (-webkit-min-device-pixel-ratio: 1.5),
		(min-resolution: 1.5) {
    	@content;
  }
}

/* ================================================================================
フォントサイズ VW設定
================================================================================ */

/*PC設定*/
@function get_vw_pc($size, $viewport:1040) {
  @return (100 / $viewport) * $size * 1vw;
}
/*タブレット設定*/
//@function get_vw_tab($size, $viewport:768) {
//  @return (100 / $viewport) * $size * 1vw;
//}
/*SP設定*/
@function get_vw_sp($size, $viewport:768) {
  @return (100 / $viewport) * $size * 1vw;
}

@mixin fz_vw($font_size:10) {
  font-size: $font_size * 0.1rem;
	font-size: get_vw_pc($font_size);
  @include mq(fixed) {
    font-size: $font_size * 0.1rem;
  }
//	@include mq(tab) {
//		font-size: get_vw_tab($font_size);
//	}
	@include mq(md) {
		font-size: get_vw_sp($font_size);
	}
}

// フォントサイズ基準
$font_size_root:10;

// フォント周り一括指定 true：タイトル
@mixin fz_lh($font_size:0, $line_height:0, $is_tit:false) {
	font-size: $font_size * 0.1rem;
	line-height: $line_height / $font_size;
	letter-spacing: (($font_size / $font_size_root) * 1px)/2;
	@if($is_tit==true){
		letter-spacing: ($font_size / $font_size_root) * 1px;
	}
}

// @include absolute(0,0,0,0);
// @include absolute($top:0, $bottom:0);
@mixin absolute($top:null, $right:null, $bottom:null, $left:null){
  position: absolute;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}

/*Placeholder*/
// @include placeholder(red);
@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }
  // IE
  &:-ms-input-placeholder {
    color: $color;
  }
  // Edge
  &::-ms-input-placeholder {
    color: $color;
  }
}

/*PC設定*/
//@function get_vw_pc($size, $viewport:1366) {
//  $rate: 100 / $viewport;
//  @return $rate * $size * 1vw;
//}
//@mixin fz_vw_pc($font_size:10) {
//  font-size: $font_size * 0.1rem;
//  font-size: get_vw_pc($font_size);
//  @include mq(fixed) {
//    font-size: $font_size * 0.1rem;
//  }
//}
//
///*タブレット設定*/
//@function get_vw_tab($size, $viewport:768) {
//  $rate: 100 / $viewport;
//  @return $rate * $size * 1vw;
//}
//@mixin fz_vw_tab($font_size:10) {
//  @include mq(tab) {
//		font-size: $font_size * 0.1rem;
//		font-size: get_vw_tab($font_size);
//  }
//}
//
///*SP設定*/
//@function get_vw_sp($size, $viewport:750) {
//  $rate: 100 / $viewport;
//  @return $rate * $size * 1vw;
//}
//@mixin fz_vw_sp($font_size:10) {
//	@include mq(md) {
//		font-size: $font_size * 0.1rem;
//  	font-size: get_vw_sp($font_size);
//	}
//}
@charset "utf-8";
.donation {
  padding-top: 60px;
  @include mq(md) {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    @include fz_vw(28);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.68px;
    text-align: center;
    color: #b21d2e;
    margin-bottom: 40px;
    @include mq(md) {
      @include fz_vw(46);
      margin-bottom: 20px;
    }
    span {
      border-bottom: 4px solid #b21d2e;
      @include mq(md) {
        border-bottom: 2px solid #b21d2e;
      }
    }
  }
  &Process {
    margin-bottom: 80px;
    @include mq(md) {
      margin-bottom: 70px;
    }
    &Box {
      padding-bottom: 25px;
      border-bottom: solid 1px #b21d2e;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      @include mq(pc) {
        padding-bottom: 40px;
        margin-top: 60px;
      }
      &::before {
        @include fz_vw(62);
        font-weight: bold;
        line-height: 0.3;
        color: #b21d2e;
        background-color: rgba(178, 29, 46, 0.13);
        border-radius: 50%;
        width: 4.615385vw;
        height: 4.615385vw;
        max-width: 48px;
        max-height: 48px;
        display: block;
        margin-top: 25px;
        @include mq(md) {
          @include fz_vw(78);
          width: 8.073vw;
          height: 8.073vw;
          max-width: 62px;
          max-height: 62px;
        }
      }
      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          &::before {
            content: "#{$i}";
          }
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 60px;
        &::after {
          content: "";
          position: absolute;
          bottom: -54px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border: 32px solid transparent;
          border-top: 22px solid #b21d2e;
        }
      }
    }
    &Txt {
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      flex-basis: 52.38%;
      @include mq(md) {
        @include fz_vw(40);
        flex-basis: calc(100% - 8vw - 20px);
        margin-bottom: 20px;
      }
      span {
        @include fz_vw(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        display: block;
        margin-top: 20px;
        @include mq(md) {
          @include fz_vw(36);
          margin-top: 30px;
        }
        &.minTxt {
          @include fz_vw(14);
          line-height: 1.29;
          letter-spacing: 0.35px;
          color: #666;
          @include mq(md) {
            @include fz_vw(28);
          }
        }
      }
    }
    &Img {
      flex-basis: 37.62%;
      @include mq(md) {
        flex-basis: 80%;
        margin: auto;
      }
      img {
        width: 100%;
      }
    }
    &Note {
      margin-top: 10px;
      @include fz_vw(14);
      line-height: 1.29;
      letter-spacing: 0.35px;
      color: #666;
      @include mq(md) {
        @include fz_vw(28);
      }
    }
  }
  &Rcpt {
    margin-bottom: 120px;
    @include mq(md) {
      margin-bottom: 70px;
      /******アンカーリンク対応*****/
      padding-top: 60px;
    }
    &EndTxt{
      @include fz_vw(24);
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.6px;
      margin-bottom: 30px;

      a {
        color: #b21d2e;
        display: inline-block;
      }
      
      @include mq(md) {
        @include fz_vw(36);
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
    &Txt {
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      margin-bottom: 70px;

      a {
        color: #b21d2e;
        display: inline-block;
      }
      
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 40px;
      }
    }
    &Map {
      max-width: 940px;
      margin: auto;
      padding: 0 20px;
      svg {
        width: 100%;
        height: 100%;
      }
      text {
        font-family: $GaramondPremrPro;
        @include fz_vw(28);
        @include mq(md) {
          @include fz_vw(64);
        }
      }
    }
    &MinTxt{
      font-size: 2rem;
      line-height: 1.8;
      letter-spacing: 0.5px;
      margin-top: 60px;
      @include mq(md) {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.8px;
        margin-top: 40px;
      }
    }
  }
  &Voice {
    &Box {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }
      &:not(:last-of-type) {
        margin-bottom: 60px;
        @include mq(md) {
          margin-bottom: 50px;
        }
      }
      &Item {
        flex-basis: calc(62% - 40px);
        &:first-of-type {
          flex-basis: 38%;
        }
        img {
          width: 100%;
        }
        &Name {
          @include fz_vw(24);
          line-height: 1.5;
          letter-spacing: 0.6px;
          margin-top: 20px;
          @include mq(md) {
            @include fz_vw(42);
          }
          span {
            @include fz_vw(14);
            line-height: 1.31;
            letter-spacing: 0.4px;
            color: #666;
            display: block;
            margin-top: 10px;
            @include mq(md) {
              @include fz_vw(28);
            }
          }
        }
        &Txt {
          @include fz_vw(16);
          @include mq(md) {
            @include fz_vw(36);
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.recipients{
  padding-top: 60px;
  @include mq(md) {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .inner {
    max-width: 880px;
    margin: 0 auto;
    padding: 0 20px;
  }
  h2 {
    @include fz_vw(28);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.68px;
    text-align: center;
    color: #b21d2e;
    margin-bottom: 40px;
    @include mq(md) {
      @include fz_vw(46);
      margin-bottom: 20px;
    }
    span {
      border-bottom: 4px solid #b21d2e;
      @include mq(md) {
        border-bottom: 2px solid #b21d2e;
      }
    }
  }
  &Rcpt {
    &Txt {
      @include fz_vw(24);
      line-height: 1.5;
      letter-spacing: 0.6px;
      margin-bottom: 70px;
      @include mq(md) {
        @include fz_vw(36);
        margin-bottom: 40px;
      }
    }
    &Map {
      max-width: 940px;
      margin: auto;
      padding: 0 20px;
      svg {
        width: 100%;
        height: 100%;
      }
      text {
        font-family: $GaramondPremrPro;
        @include fz_vw(28);
        @include mq(md) {
          @include fz_vw(64);
        }
      }
      &Txt{
        font-size: 2rem;
        line-height: 1.8;
        letter-spacing: 0.5px;
        margin-top: 30px;
        text-align: right;
        @include mq(md) {
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: 0.8px;
          margin-top: 20px;
        }
      }
    }
    &MinTxt{
      font-size: 2rem;
      line-height: 1.8;
      letter-spacing: 0.5px;
      margin-top: 30px;
      @include mq(md) {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.8px;
        margin-top: 20px;
      }
    }
  }
}

.donationRcptDesTtl{
  @include fz_vw(24);
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  color: #B21D2E;

  &:nth-of-type(2){
    margin-top: 70px;
  }

  @include mq(md){
    @include fz_vw(42);
    margin-bottom: 4.6875vw;

    &:nth-of-type(2){
      margin-top: 9.114vw;
    }
  }
}

.donationRcptDesBox{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  .txtBox{
    width: 50%;
    text-align: start;
    line-height: 1.5;

    @include mq(md){
      width: 100%;
    }

    strong{
      @include fz_vw(24);

      @include mq(md){
        @include fz_vw(36);
      }
    }

    p{
      @include fz_vw(24);

      @include mq(md){
        @include fz_vw(36);
      }
    }
  }

  .pdfBox{
    width: 50%;
    text-align: center;

    @include mq(md){
      margin-top: 7.822vw;
    }

    @include mq(md){
      width: 100%;
    }

    .pdfBoxInner{
      width: 57.6%;
      margin: 0 auto;

      @include mq(md){
        width: 70%;
      }
    }

    img{
      margin: 0 auto;
      width: 100%;
    }

    .downloadBtn{
      display: inline-block;
      width: 100%;
      padding: 10px 0 ;
      background-color: #B21D2E;
      @include fz_vw(13);
      color: #fff;
      text-decoration: none;
      margin-top: 32px;
      letter-spacing: 0.3px;
      line-height: 1.5;

      @include mq(md){
        @include fz_vw(32);
        padding: 2.346vw 0 ;
      }
    }
  }

}

.downloadList{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0 30px 20px;
  border-bottom: 1px dashed #C7C7C7;
  @include fz_vw(18);

  @include mq(md){
    @include fz_vw(32);
    padding: 7.8125vw 0;
  }


  &:first-of-type{
    background-color: #F4F5F5;
    padding: 16px 0 16px 20px;
    border-bottom: none;
    @include fz_vw(20);

    @include mq(md){
      @include fz_vw(36);
      padding: 3.911vw 0;
      text-align: center;

    }
  }

  dt{
    flex-basis: 60%;
    align-self: center;

    span {
      display: block;
      @include fz_vw(14);
      line-height: 1.29;
      letter-spacing: 0.35px;
      color: #b21d2e;
      margin-top: 5px;
      @include mq(md) {
        @include fz_vw(28);
      }
    }

    @include mq(md){
      flex-basis: 100%;

      p{
        letter-spacing: 0.25px;
      }
    }

  }

  dd{
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    @include mq(md){
      flex-basis: 100%;
      padding: 7.161vw 4.30vw 0;
      justify-content: space-between;
    }

    p{
      width: 50%;

      @include mq(md){
        width: 100%;
        letter-spacing: 0.25px;
        @include fz_vw(32);
        color: #A81E2D;
        &.greenTxt{
          color: #007732;
        }

        &.blueTxt{
          color:#1D409D;
        }
      }
    }

    a{
      width: 40%;

      @include mq(md){
        &:first-of-type{
           width: 45%;

          img{
            width: 12.648vw;
          }
        }
        &:last-of-type{
           width: 45%;

          img{
            width: 19.791vw;
          }
        }
      }

      img{
        margin: 0 auto;
      }
    }

    .empty{
      width: 40%;
      font-family: $base-font;
    }
  }

}
@charset "utf-8";
.search {
  padding-top: 60px;
  margin-bottom: 30px;
  @include mq(md) {
    padding-top: 40px;
    margin-bottom: 20px;
  }
  .inner {
    max-width: 760px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &Tit {
    @include fz_vw(24);
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #000;
    padding-bottom: 10px;
    border-bottom: solid 1px #eaeaea;
    margin-bottom: 10px;
    @include mq(md) {
      @include fz_vw(42);
    }
  }
  &Num {
    @include fz_vw(14);
    letter-spacing: 0.35px;
    color: #333;
    margin-bottom: 50px;
    @include mq(md) {
      @include fz_vw(28);
      margin-bottom: 30px;
    }
    span {
      color: #a81e2d;
    }
  }
  &Results {
    margin-bottom: 30px;
    &Item {
      margin-bottom: 50px;
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
    &Tit {
      @include fz_vw(20);
      line-height: 1.5;
      letter-spacing: 0.5px;
      color: #a81e2d;
      @include mq(md) {
        @include fz_vw(32);
      }
    }
    &Txt {
      @include fz_vw(18);
      line-height: 1.33;
      letter-spacing: 0.45px;
      color: #555;
      padding-top: 15px;
      word-break: break-all;
      @include mq(md) {
        @include fz_vw(28);
      }
    }
  }
  &Pager {
    max-width: 210px;
    margin: auto;
    ol {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        flex-basis: auto;
        @include fz_vw(24);
        color: #a81e2d;
        @include mq(md) {
          @include fz_vw(52);
        }
        a {
          color: #c7c7c7;
        }
        &.current {
          text-decoration: underline;
        }
        &.prev,
        &.next {
          a {
            display: block;
            color: #fff;
            width: 10px;
            height: 10px;
            position: relative;
            &::after {
              content: '';
              border: solid 1px;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              transform: rotate(45deg);
            }
          }
          &.hide {
            opacity: 0;
            visibility: hidden;
          }
        }
        &.prev {
          a {
            &::after {
              border-color: transparent transparent #a81e2d #a81e2d;
            }
          }
        }
        &.next {
          a {
            &::after {
              border-color: #a81e2d #a81e2d transparent transparent;
            }
          }
        }
      }
    }
  }
  &BackBtn {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.55;
    letter-spacing: 1.2px;
    text-align: center;
    color: #fff;
    background-color: #b21d2e;
    padding: 15px 0;
    display: block;
    width: 250px;
    margin: 60px auto 0;
    cursor: pointer;
    @include mq(md) {
      font-size: 2.2rem;
      width: 100%;
      margin: 0 auto;
      line-height: 1.68;
      letter-spacing: 1.14px;
      margin: 60px auto 20px;
      padding: 5px 25px;
    }
  }
}